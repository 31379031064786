import React, { useEffect, useState } from 'react';
import styles from './style.module.less';
import { exitWebview } from '@/landingPage/common/bridge';

enum StructureNavType {
    productTitle = '1',
    formTitle = '2',
    productProfit = '3',
}
const tabs = [
    { id: StructureNavType.productTitle, title: '简介' },
    { id: StructureNavType.formTitle, title: '预约' },
    { id: StructureNavType.productProfit, title: '详情' },
];

const fullPageClose =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2025-01-07/1736262823677.892b6d3ef4858c20.png';

const scrollPageClose =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2025-01-07/1736262974311.06e57d1651c2ea50.png';
/**
 * 导航条组件，无需在B端搭建，判断为结构化落地页时候，使用createPortal挂载在页面中
 * 展示时机：页面上滑210px
 * @param props
 * @returns
 */
export const NavigationBar: React.FunctionComponent = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [isShowNavigationBar, setIsShowNavigationBar] = useState(false);

    // 递归遍历
    const handleTabChange = (tabKey: StructureNavType) => {
        setActiveTab(tabKey);
        let targetNode;
        let targetTop;
        const navTypeToSelectorMap = new Map([
            [StructureNavType.productTitle, '.structureNav_productTitle'],
            [StructureNavType.formTitle, '.structureNav_formTitle'],
            [StructureNavType.productProfit, '.structureNav_productInfo'],
        ]);
        const selector = navTypeToSelectorMap.get(tabKey) || '';
        targetNode = document.querySelector(selector);

        if (targetNode) {
            // 这里44是导航栏高度，15是文字框的padding-top, 87是沉浸式距离顶部高度
            targetTop = targetNode.getBoundingClientRect().top + window.scrollY - 44 - 87 - 15;
            window.scrollTo({ top: targetTop, behavior: 'smooth' });
        }
    };

    // 实现吸顶效果
    // 监听页面上滑动210px时候出现
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            if (scrollTop >= 210) {
                setIsShowNavigationBar(true);
            } else {
                setIsShowNavigationBar(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={styles.structurePageTopBar}>
                <img
                    src={isShowNavigationBar ? scrollPageClose : fullPageClose}
                    onClick={() => {
                        exitWebview();
                    }}
                    className={styles.closeBtn}
                />
                {isShowNavigationBar && <text className={styles.structurePageTitle}>服务详情</text>}
            </div>

            {isShowNavigationBar && (
                <div className={styles.navigationBarContainer}>
                    {tabs.map((tab) => (
                        <button
                            key={tab.id}
                            className={
                                activeTab === tab.id
                                    ? styles.buttonTab
                                    : styles.buttonTab_unselected
                            }
                            onClick={() => handleTabChange(tab.id)}
                        >
                            <text
                                className={
                                    activeTab === tab.id
                                        ? styles.buttonTab_title
                                        : styles.buttonTab_unselected_title
                                }
                            >
                                {tab.title}
                            </text>
                            {activeTab === tab.id && (
                                <span className={styles.buttonTab_line}></span>
                            )}
                        </button>
                    ))}
                </div>
            )}
        </>
    );
};

export default NavigationBar;
