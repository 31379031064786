import { createModel } from 'rt-model';
import { useRenderActions } from 'omega-render';

import { Toast } from '@ad/sharpui';

import { dataTrack } from 'landingPage/common/utils/trackAd';
// import { checkVersion } from 'landingPage/common/utils/compareVersion';
import { getWindow } from 'landingPage/common/utils/getWindow';
import { callQuickApp } from 'landingPage/common/utils/callQuickApp';
import {
    AppInfo,
    getAppInfo,
    getNeedVersion,
    appDownloadSubmitUsingPOST,
    getPackageInfo,
    reportNoFunctionIntroduction,
} from 'landingPage/common/api';
import { getPageId } from 'landingPage/common/utils/getPageId';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
// import { BizId } from 'landingPage/common/enums';
import { bridgeGetDeviceInfo, getAdBridge, importBridge } from 'landingPage/common/bridge';
import { getDeviceType } from 'landingPage/common/utils/getDeviceInfo';
import { sendClickLog } from 'landingPage/common/adTrack/adBridgeLog';
import { EnumPageType } from 'omega-shared/types';
import { AppDownloadProps } from './AppDownload';
import { isIOS } from 'omega-shared/utils';
import { mapAppInfo } from './utils';
import { isInApp } from '@/landingPage/common/utils/env';
// import { callLianmengBridge } from '@/landingPage/common/utils/lianmengBridge';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { iosDownload } from './linkTypeHandler/iosDownload';
import { androidDownload } from './linkTypeHandler/androidDownload';
import { harmonyDownload } from './linkTypeHandler/harmonyDownload';

interface bridgeDeviceInfo extends KsBridge.IKsBridgeResult {
    data: {
        globalId: string;
    };
}

export enum LINK_TYPE {
    QUICK_LINK = 2,
    ANDROID = 3,
    IOS = 4,
    HARMONY = 5,
    IMG = 1,
}

export enum DownloadTypeEnum {
    ANDROID = 1,
    QUICK_APP = 2,
    DIRECT_DOWNLOAD = 3,
}

export const downloadType = {
    [LINK_TYPE.QUICK_LINK]: DownloadTypeEnum.QUICK_APP,
    [LINK_TYPE.ANDROID]: DownloadTypeEnum.ANDROID,
};

/**
 * type 1 下载按钮点击 2 图片点击 3 底部蓝条点击
 */
export enum TYPE {
    DOWNLOAD = 1,
    PICTURE = 2,
    BOTTOM = 3,
}
export interface AppDownloadState {
    hasAppDownload: boolean;
    init: boolean;
    isFirstClick: boolean;
    clickedId?: string;
    versionLimit: {
        appVersionLimit: string;
        nebulaVersionLimit: string;
    };
    appInfo: AppInfo;
    downLoadProgress: {
        status: number;
        progress: number;
    };
    iOSLink?: string;
}
const appVersionLimit = '6.11.1';
const nebulaVersionLimit = '1.11.2';

async function trackDownload(appInfo: AppDownloadProps['appInfo'], id: string) {
    let deviceId = '';
    const deviceInfo = (await bridgeGetDeviceInfo()) as bridgeDeviceInfo;
    deviceId = deviceInfo?.data?.globalId;
    appDownloadSubmitUsingPOST({
        submitData: {
            /** 应用广告主Id */
            appAccountId: (appInfo?.accountId ?? '').toString(),
            /** 应用Id */
            appId: appInfo?.appId ?? 0,
            /** 应用名称 */
            appName: appInfo?.appName ?? '',
            /** 应用平台 */
            appPlatform: appInfo?.platform ?? '',
            /** 设备Id */
            deviceId,
            /** 设备类型 */
            deviceType: getDeviceType(),
            /** dspCallback */
            dspCallback: getUrlParam('callback') ?? '',
            /** 事件类型 */
            eventType: 'EVENT_APPOINT_JUMP_CLICK',
            /** 发布业务线Id */
            pubBizId: 1003,
            /** 发布来源Id */
            pubSourceId: getUrlParam('pageId'),
            sourceDesc: '',
            /** 来源URL */
            sourceUrl: document.location.href,
            /* 组件id */
            componentId: id,
        },
    });
}

export const defaultState: AppDownloadState = {
    hasAppDownload: false,
    init: false,
    isFirstClick: true,
    versionLimit: {
        appVersionLimit,
        nebulaVersionLimit,
    },
    appInfo: {
        appName: '',
        developerName: '',
        md5List: [],
        permissionList: [],
        privacyUrl: '',
        version: '',
        check: false,
    } as unknown as AppInfo,
    downLoadProgress: {
        status: 0,
        progress: 0,
    },
    iOSLink: '',
};

export const AppDownloadModel = createModel({
    namespace: 'appDownload',
    defaultState,
    reducers: {},
    effects: {
        /**
         * @progress
         *      1 根据pageID获取app版本的限制
         *      2 根据appId从服务端拉取app信息实时更新(因为用户会更新包信息，所以渲染时实时获取而不是从属性区取)
         *      3 桥方法实时监听下载进度
         */
        init(payload: Pick<AppDownloadProps, 'appInfo' | 'iOSLink'>) {
            return async (dispatch, getState) => {
                const state: AppDownloadState = getState().appDownload;
                const rolexState = getState().rolex;

                console.log('rolexState', rolexState);
                console.log('state.init', state.init);

                if (state.init || rolexState.preview) {
                    return;
                }
                dispatch(AppDownloadModel.actions.setModelState({ init: true }));

                const fetchAppVersionLimit = async () => {
                    if (!getPageId()) return;
                    try {
                        const res = await getNeedVersion(getPageId());
                        const { androidApp, nebula } = res.data.data;
                        dispatch(
                            AppDownloadModel.actions.setModelState({
                                versionLimit: {
                                    appVersionLimit: androidApp,
                                    nebulaVersionLimit: nebula,
                                },
                            }),
                        );
                    } catch (e) {
                        console.log(e);
                    }
                };

                const payloadAppInfo = { ...payload.appInfo } as AppDownloadProps['appInfo'];
                const queryAppInfo = async () => {
                    const isIos = !!payload.appInfo?.iosAppId;
                    try {
                        if (!payload.appInfo?.appId) {
                            console.error('invalid appid');
                            dispatch(
                                AppDownloadModel.actions.setModelState({
                                    appInfo: {
                                        ...payload.appInfo,
                                        version: payload.appInfo?.appVersion,
                                    } as unknown as AppInfo,
                                }),
                            );

                            // throw Error('invalid appid');
                            return;
                        }
                        // if (payload.appInfo?.iosAppId) {
                        //     throw Error('is ios app');
                        // }
                        /**由于应用中心升级，支持跨账号投放，导致getInfo接口后端拿页面所属的账户id去查应用信息时查不到，故针对新应用新增packageInfo接口 */
                        let appInfo: AppInfo | undefined = undefined;
                        let pageType = 0;
                        try {
                            pageType = JSON.parse(window.pageJsonString ?? '{}').type;
                        } catch (e) {
                            console.log(e);
                        }

                        if (
                            pageType !== EnumPageType.智能中间页 &&
                            pageType !== EnumPageType.PLC中间页 &&
                            pageType !== EnumPageType.大图中间页 &&
                            pageType !== EnumPageType.大白中间页
                        ) {
                            if (payload.appInfo?.packageId) {
                                appInfo = await getPackageInfo(String(payload.appInfo?.packageId));
                                if (!appInfo.functionIntroduction) {
                                    reportNoFunctionIntroduction({
                                        pageId: getUrlParam('pageId'),
                                        appId: String(payload.appInfo?.appId),
                                        packageId: String(payload.appInfo?.packageId),
                                        failReason: '新应用，未返回功能介绍字段',
                                        callback: getUrlParam('pageId'),
                                    });
                                }
                            } else {
                                appInfo = await getAppInfo(
                                    String(payload.appInfo?.appId),
                                    getUrlParam('callback'),
                                    `${isIos ? 'ios' : 'android'}`,
                                    getPageId(),
                                );
                                reportNoFunctionIntroduction({
                                    pageId: getUrlParam('pageId'),
                                    appId: String(payload.appInfo?.appId),
                                    failReason: '老应用，不支持功能介绍字段',
                                    callback: getUrlParam('pageId'),
                                });
                            }
                        }

                        if (payloadAppInfo && appInfo?.appDownloadUrl) {
                            payloadAppInfo.url = appInfo?.appDownloadUrl;
                        }

                        if (isIos) {
                            dispatch(
                                AppDownloadModel.actions.setModelState({
                                    iOSLink: appInfo?.appDownloadUrl,
                                }),
                            );
                        } else {
                            dispatch(
                                AppDownloadModel.actions.setModelState({
                                    appInfo,
                                }),
                            );
                        }
                    } catch (e) {
                        console.log(e);
                    }
                };

                await Promise.all([
                    //   获取应用下载信息
                    // this.fetchAppInfo(),
                    queryAppInfo(),

                    fetchAppVersionLimit(),
                ]);
                await importBridge();
                if (!isIOS) {
                    const apkStatusSuccess = (res) => {
                        console.log('[registerApkStatusListener]: success', res);
                        let result = res;
                        if (typeof res === 'string') {
                            result = JSON.parse(res);
                        }
                        if (result.result !== 1) {
                            return;
                        }
                        const { status, progress } = res.data;

                        dispatch(
                            AppDownloadModel.actions.setModelState({
                                downLoadProgress: {
                                    status,
                                    progress,
                                },
                            }),
                        );
                    };

                    const apkStatusError = (err) => {
                        console.log('[registerApkStatusListener]: fail', err);
                    };
                    if (!isInApp) {
                        // window.callback_registerApkStatusListener = (res) => {
                        //     let result: any = res;
                        //     if (typeof res === 'string') {
                        //         try {
                        //             result = JSON.parse(res);
                        //         } catch (e) {
                        //             console.log('error', e);
                        //         }
                        //     }
                        //     if (result.result !== 1) {
                        //         return;
                        //     }
                        //     const { status, progress } = result.data;

                        //     dispatch(
                        //         AppDownloadModel.actions.setModelState({
                        //             downLoadProgress: {
                        //                 status,
                        //                 progress,
                        //             },
                        //         }),
                        //     );
                        // };
                        // callLianmengBridge({
                        //     action: 'registerApkStatusListener',
                        //     data: JSON.stringify({ ...(mapAppInfo(payloadAppInfo) as any) }),
                        //     callback: 'callback_registerApkStatusListener',
                        // });

                        bridge?.registerApkStatusListener({
                            ...(mapAppInfo(payloadAppInfo) as any),
                            success: (res) => {
                                apkStatusSuccess(res);
                            },
                            error: (err) => {
                                apkStatusError(err);
                            },
                        });
                    } else {
                        getAdBridge()?.registerApkStatusListener({
                            ...(mapAppInfo(payloadAppInfo) as any),
                            success: (res: any) => {
                                apkStatusSuccess(res);
                            },
                            fail: (err) => {
                                apkStatusError(err);
                            },
                        });
                    }
                }
            };
        },
        /**
         * @progress
         *      1 ios下载逻辑：如果hasInstalledApp，如果没有安装，则用location.href跳转
         */
        openOrDownload(
            payload: { type: TYPE; id?: string } & Pick<
                AppDownloadProps,
                'iOSLink' | 'appInfo' | 'linkType' | 'quickAppLink' | 'deepLink'
            >,
        ) {
            return async (dispatch, getState) => {
                const { type, linkType, quickAppLink, appInfo, iOSLink, deepLink } = payload;
                let { id } = payload;

                type === 1 && extraTrack(appInfo, id);

                const appDownloadState: AppDownloadState = getState().appDownload;
                const marketDirect =
                    getState()?.rolex.opt?.originalPageJson?.pageMeta?.appMarketDirect;

                const { iOSLink: serverIOSLink, appInfo: serverAppInfo } = appDownloadState;
                if (linkType === LINK_TYPE.IOS) {
                    iosDownload(deepLink, serverIOSLink, iOSLink);
                }
                // 说明是图片点击,选择一个应用下载组件作为进度展示
                if (!id && type === 2 && appDownloadState.hasAppDownload) {
                    id = selectId();
                }
                // 统计
                dataTrack({
                    eventType: 'EVENT_LANDINGPAGE_STARTED_DOWNLOAD_CLICK',
                    start_download_click_type: type,
                    diyExtraInfo: JSON.stringify({
                        renderType: 'APP_DOWNLOAD_DRAG',
                        downloadType: marketDirect
                            ? DownloadTypeEnum.DIRECT_DOWNLOAD
                            : downloadType[linkType],
                    }),
                });

                const handleDownload = () => {
                    androidDownload({
                        appInfo: appInfo
                            ? { ...appInfo, url: serverAppInfo?.appDownloadUrl ?? appInfo?.url }
                            : undefined,
                        limitInfo: appDownloadState.versionLimit,
                        appSecureInfo: serverAppInfo,
                        linkType,
                        deepLink,
                        marketDirect,
                    });
                };
                if (linkType === LINK_TYPE.HARMONY) {
                    // const url = appInfo?.url;
                    harmonyDownload(appInfo);
                    return;
                }
                if (linkType === LINK_TYPE.ANDROID) {
                    dispatch(
                        AppDownloadModel.actions.setModelState({
                            clickedId: id,
                        }),
                    );
                }
                if (appDownloadState.isFirstClick && linkType === LINK_TYPE.QUICK_LINK) {
                    //
                    // const timer: number | null = null;
                    dispatch(
                        AppDownloadModel.actions.setModelState({
                            isFirstClick: false,
                            clickedId: id,
                        }),
                    );
                    // 调起快应用
                    try {
                        await callQuickApp(
                            quickAppLink as string,
                            handleDownload,
                            Boolean(appInfo),
                        );
                    } catch (err) {
                        console.error('callQuickApp error', err);
                        if (appInfo) {
                            handleDownload();
                        } else {
                            Toast.error('快应用打开失败，请重试');
                        }
                    }
                    // const iframe = document.createElement('iframe');
                    // iframe.src = quickAppLink!;
                    // iframe.style.display = 'none';
                    // document.body.appendChild(iframe);
                    // setTimeout(() => {
                    //     document.body.removeChild(iframe);
                    // }, 500);

                    // // 4s 后进入下载
                    // timer = window.setTimeout(handleDownload, 4000);
                    // document.addEventListener('visibilitychange', () => {
                    //     if (document.visibilityState === 'hidden') {
                    //         timer != null && clearTimeout(timer);
                    //         dispatch(
                    //             AppDownloadModel.actions.setModelState({
                    //                 isFirstClick: true,
                    //             }),
                    //         );
                    //         timer = null;
                    //     }
                    // });
                } else {
                    handleDownload();
                }
                // 下载中间页打点
                if (
                    [EnumPageType.智能中间页, EnumPageType.PLC中间页].includes(
                        JSON.parse(window.pageJsonString ?? '{}').type,
                    )
                ) {
                    sendClickLog();
                }
            };
        },
    },
});
export const reducerMap = {
    appDownload: AppDownloadModel?.reducer,
};

export const useAppDownloadActions = () => useRenderActions(AppDownloadModel.actions);

const selectId = () => {
    const nodes = Array.from(
        getWindow().document.querySelectorAll<HTMLDivElement>(
            `[data-component-type='APP_DOWNLOAD_DRAG']`,
        ),
    );
    const { innerHeight } = window;
    let ele = null as HTMLDivElement | null;
    let dis = Infinity;
    // 距离屏幕最近的按钮会被选中，当按钮都在屏幕内的时候，会选择屏幕最下面的那个
    // eslint-disable-next-line func-names
    nodes.forEach(function (node) {
        const { top, bottom } = node.getBoundingClientRect();
        // 在屏幕上方，选择距离屏幕最近的
        if (bottom < 0) {
            if (Math.abs(bottom) < dis) {
                dis = Math.abs(bottom);
                ele = node;
            }
            // 在屏幕内，选择剧里屏幕底部最近的
        } else if (top < innerHeight) {
            if (-top < dis) {
                dis = -top;
                ele = node;
            }
            // 在屏幕下面，选择剧里屏幕距离最近的
        } else if (top > innerHeight) {
            if (top - innerHeight < dis) {
                dis = top - innerHeight;
                ele = node;
            }
        }
    });
    const id = ele?.dataset?.id;
    return id;
};

export const getText = (downLoadProgress: AppDownloadState['downLoadProgress']) => {
    const { status, progress } = downLoadProgress || {};
    if (status === 2) {
        return progress;
    }
    if (status === 3) {
        return '继续下载';
    }
    if (status === 4) {
        return '点击下载';
    }
    if (status === 5) {
        return '立即安装';
    }
    if (status === 6) {
        return '立即打开';
    }
    return null;
};
/**
 * 游戏预约打点，和线索提交后的打点逻辑一致。这部分回头可以合并下
 */
export const extraTrack = (appInfo, id, diyExtraInfo?: any) => {
    try {
        const search = new URLSearchParams(window.parent.location.search);
        if (search) {
            /**
             * https://docs.corp.kuaishou.com/d/home/fcABAT6tTi3YNQFPoZZ0QM4V8 预约表单数据与dsp指标拉齐,应用下载不再上报
             */
            // const appointmentFromGame = search.get('appointmentFromGame');
            const appointmentJumpFromGame = search.get('appointmentJumpFromGame');
            // if (appointmentFromGame) {
            //     dataTrack({
            //         eventType: appointmentFromGame,
            //     });
            // }
            if (appointmentJumpFromGame) {
                dataTrack({
                    eventType: appointmentJumpFromGame,
                    diyExtraInfo,
                });
                console.log('start send submit开始调用submit接口');
                getUrlParam('callback') && trackDownload(appInfo, id ?? '');
            }
        }
    } catch (err) {
        console.log(err);
    }
};
