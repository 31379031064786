import { getCommitCount, getIndustryText, getInterstBrowseCount } from '@/landingPage/common/api';
import { getPageId } from '@/landingPage/common/utils/getPageId';

// 获取区间内随机数，兜底
const getRandomInt = (start, end) => {
    // 确保 start 和 end 是整数
    start = Math.ceil(start);
    end = Math.floor(end);
    // 计算范围（包括 start 和 end）
    return Math.floor(Math.random() * (end - start + 1)) + start;
};

// 获取感兴趣人数：数据+区间——>最大值
function getIntervalValue(count) {
    const intervals = [
        { max: 1000, value: 1000 },
        { max: 5000, value: 5000 },
        { max: 10000, value: 10000 },
        { max: Infinity, value: 20000 },
    ];
    const interval = intervals.find((interval) => count <= interval.max);
    return interval?.value;
}
// 获取正在看人数：数据+区间1——>区间2随机值
function getRandomValue(count) {
    const intervals = [
        { max: 200, min: 0, maxRandom: 400 },
        { max: 500, min: 201, maxRandom: 700 },
        { max: 1000, min: 501, maxRandom: 1200 },
        { max: Infinity, min: 1001, maxRandom: null },
    ];

    const interval = intervals.find((interval) => count <= interval.max);

    if (interval) {
        if (interval?.maxRandom !== null) {
            return (
                Math.floor(Math.random() * (interval.maxRandom - interval.max + 1)) + interval.max
            );
        } else {
            return count;
        }
    }
}

// 默认头像列表
export const defaultHeadImgList = [
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00887_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00555_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00123_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00666_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00066_.png',
    'https://p4-ad.adbkwai.com/kos/proj-CID/Q0lE/kcdn/cdn-kcdn111941/clue_24Q4/96PX/img_00471_.png',
];
export const defaultShufflingData = {
    headImgList: defaultHeadImgList,
    reserveCount: getRandomInt(700, 3500),
    intersetCount: getRandomInt(7000, 50000),
    browseCount: getRandomInt(1000, 5000),
};

// 转化数据
export type ShufflingDataType = {
    headImgList?: string[];
    reserveCount?: number;
    intersetCount?: number;
    browseCount?: number;
};
export const fetchShufflingData = async (props: any) => {
    const { isShowReserve, isShowInterest, isShowBrowse } = props;

    const lpPageId = getPageId();
    let requestList: Promise<any>[] = [];
    const requestCases: string[] = [];
    if (!lpPageId) {
        return defaultShufflingData;
    }
    // 请求list列表
    if (isShowReserve) {
        requestList.push(getIndustryText({ lpPageId, type: 3 }));
        requestList.push(getCommitCount({ lpPageId, type: 3 }));
        requestCases.push('getHeadImg', 'getReserveCount');
    }

    if (isShowInterest || isShowBrowse) {
        requestList.push(getInterstBrowseCount({ lpPageId, type: 3 }));
        requestCases.push('getInterstBrowseCount');
    }

    let shufflingData: ShufflingDataType = {};

    try {
        const result = await Promise.allSettled(requestList);

        result.forEach((item, index) => {
            if (item.status === 'fulfilled') {
                switch (requestCases[index]) {
                    case 'getHeadImg':
                        shufflingData.headImgList = item.value?.head?.length
                            ? item.value?.head
                            : defaultHeadImgList;
                        break;
                    case 'getReserveCount':
                        shufflingData.reserveCount =
                            item.value?.showNumber ?? getRandomInt(700, 3500);
                        break;
                    case 'getInterstBrowseCount':
                        const randomInterestCount =
                            item.value?.interestNum ?? getRandomInt(7000, 50000);
                        shufflingData.intersetCount = getIntervalValue(randomInterestCount);
                        const randomBrowseCount =
                            item.value?.lookingNum ?? getRandomInt(1000, 5000);
                        shufflingData.browseCount = getRandomValue(randomBrowseCount);
                        break;
                }
            }
        });

        return shufflingData;
    } catch (error) {
        console.error('请求出错', error);
        return defaultShufflingData; // 返回兜底对象或处理错误
    }
};
