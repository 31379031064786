import queryString from 'query-string';
// import { invoke } from '@yoda/bridge';
import { isProduction } from '../../utils/env';
import { AD_LOG, getUrlParams } from './env';
import axios, { AxiosRequestConfig } from 'axios';
import { getCallbackInfoCache } from './fetchCallbackByBridge';
import { EnumPageType } from 'omega-shared/types';
import { getAppVersion } from 'landingPage/common/impression';
import { getPageId } from '../../utils/getPageId';
import { getPageJson } from '../../utils/fetchCompPropsInPageJson';
// import { getBridge } from '../../bridge';

let baseParams: any = null;

// const IS_PRE_CODE = {
//     UNKNOWN: 0,
//     IS_PRE: 1,
//     NOT_PRE: 2,
// };

/* 是否已请求后端打点迁移开关 */
// let fetchBackConfigInitd = false;
/* 后端打点迁移开关 */
// let backSwitch = false;

export function getCallbackInfo() {
    const { search } = window.location;
    if (search) {
        let callback = decodeURIComponent(getUrlParams('callback'));
        /** 处于服务号场景 & 链接中未携带callback参数时，取前置bridge中获取到的callback信息 */
        if (getUrlParams('lpFromChannel') === '1' && !callback?.length) {
            const result = getCallbackInfoCache();
            callback = result?.adCallbackParam || '';
        }
        return callback;
    }
    return '';
}

export async function getBaseParams() {
    let params = {};
    // console.log('getDeviceInfo:::::::::::::start');
    // idfa, imei 这两个参数已经取不到了，是否要下线？
    // const ret = await invoke('webview.getDeviceInfo');
    // console.log('getDeviceInfo::success::::', ret);
    // const deviceParmas = { idfa: ret.data.idfa, imei: ret.data.imei };
    const pageId = getPageId();
    // const isPreLandingPage = window.isPreLandingPage;
    params = {
        ...params,
        // ...deviceParmas,
        lpPageId: pageId,
        // diyExtraInfo: JSON.stringify({
        //     isPreLandingpage: isPreLandingPage || IS_PRE_CODE.UNKNOWN,
        // }),
    };

    baseParams = params;

    return baseParams;
}

export function handleParams(...arr) {
    if (arr?.length < 1) {
        return {};
    }
    return [...arr].reduce((params, cur) => {
        return { ...params, ...cur, clientTimestamp: new Date().getTime() };
    }, {});
}

function fetchAdLog(baseUrl, params, method) {
    // 当建站后端打点接口出问题，重试数据流打点接口
    function retryOld() {
        const feedBaseUrl = !isProduction ? AD_LOG.debug : AD_LOG.release;
        const callbackInfo = getCallbackInfo();
        const url = `${feedBaseUrl}&callback=${callbackInfo}`;
        fetchAdLog(url, params, method);
    }
    let url = baseUrl;
    let options: AxiosRequestConfig = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    };

    if (method === 'GET') {
        // eslint-disable-next-line
        url = `${baseUrl}&${queryString.stringify(params)}`;
    } else {
        options = {
            ...options,
            method: 'POST',
            data: params,
        };
    }

    axios(url, options)
        .then(async (res) => {
            // 迁移后端打点增加错误备案，重试信息流打点接口
            const data = res.data;
            if (baseUrl?.includes(AD_LOG.back) && data.result !== 1) {
                retryOld();
            }
        })
        .catch((err) => {
            console.error(err);
            // 迁移后端打点增加错误备案，重试信息流打点接口
            if (baseUrl?.includes(AD_LOG.back)) {
                retryOld();
            }
        });
}

/* 获取迁移后端打点接口开关 */
// async function getBackSwitch() {
//     if (!fetchBackConfigInitd) {
//         try {
//             const res = await configSwitchClientUsingGet({ req: { id: getPageId(), caseName: 'PAGE_LOG' } });
//             if (res && res.data) {
//                 backSwitch = res.data;
//             }
//         } catch (err) {
//             console.error(err);
//         } finally {
//             fetchBackConfigInitd = true;
//         }
//     }
//     return backSwitch;
// }

// async function getBackUrl() {
//     const conf = await getBackSwitch();
//     if (conf) {
//         return AD_LOG.back;
//     }
//     return false;
// }

export async function getBaseUrl(callback?: string) {
    const baseUrl = AD_LOG.back;
    // const backUrl = await getBackUrl();
    // if (backUrl) {
    //     baseUrl = backUrl;
    // }
    const callbackInfo = callback || getCallbackInfo();
    const url = `${baseUrl}&callback=${callbackInfo}`;
    if (!callbackInfo) {
        return null;
    }
    return url;
}

export function getComponentType() {
    let componentType = 'unknown';
    try {
        let pageJSON = JSON.parse(window.pageJsonString);
        if (
            pageJSON?.type === EnumPageType.智能中间页 ||
            pageJSON?.type === EnumPageType.PLC中间页
        ) {
            componentType = '-1';
        } else {
            componentType = pageJSON.pageConversionType;
        }
    } catch (e) {
        console.log(e);
    }
    return componentType;
}

export async function sendData(trackparams, method?: string, callbackInfo?: string) {
    if (!window.trackerAd.canTrack) {
        return;
    }

    const baseUrl = await getBaseUrl(callbackInfo);
    if (!baseUrl) {
        return null;
    }
    const urlParams = baseParams || (await getBaseParams());
    const { globalParams } = window.trackerAd;
    const params = handleParams(urlParams, trackparams, globalParams);
    const diyExtraInfo = JSON.parse(params.diyExtraInfo ?? '{}');
    const pageJSONCache = getPageJson();
    params.diyExtraInfo = JSON.stringify({
        ...diyExtraInfo,
        isMK: getUrlParams('downgradeSource') ? true : getUrlParams('isH5') ? false : '',
        componentType: diyExtraInfo?.componentType || getComponentType(),
        appVersion: getAppVersion(),
        reportSource: 'magicSite',
        reportSubSource: 'front',
        reportType: 1001,
        ksCampaignType: getUrlParams('ksCampaignType'),
        pageType: pageJSONCache?.type,
        structurePageId: pageJSONCache.type === EnumPageType.结构化落地页 && getUrlParams('pageId'),
    });

    fetchAdLog(baseUrl, params, method);
}
