import * as React from 'react';

import cs from 'classnames';
import {
    CustomerServiceProps,
    IM_TYPE,
    openServiceUrl,
} from 'landingPage/components/CustomerServiceDrag/CustomerService';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { useComContext, useRenderState } from 'omega-render';
import Modal from 'landingPage/common/components/Modal/Modal';
import { getPrivateLetterInfo } from 'landingPage/services/apis';
import { formClass } from '../XiansuoFormDrag/XiansuoForm';
import { IFormProps, FormOption } from '../ButtonDrag/Button';
import { showAnchorFormIcon } from 'landingPage/common/utils/showAnchorFormIcon';
import { callTel, TelProps, ICallTelParam } from '../TelDrag/Tel';
import { FormDialog } from '../ButtonDrag/formDialog';
import { PayFormDialog } from '../ButtonDrag/payFormDialog';
import { Ol } from '../Blank/Blank';
import { ReactComponent as TelIcon } from './img/newTel.svg';
import { ReactComponent as FormIcon } from './img/newForm.svg';
import { ReactComponent as CustomerServiceIcon } from './img/newConsult.svg';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { isInApp } from '@/landingPage/common/utils/env';
import { fetchConsultPageUrl } from '../CustomerServiceDrag/utils';
import { scrollToAnchorForm } from '@/landingPage/common/utils/scrollToAnchorForm';
import { getAdBridge, importBridge } from '@/landingPage/common/bridge';
import { EnumPageType } from 'omega-shared/types';
import { eventEmitterOverall } from '@/landingPage/common/eventEmitter/event';

export interface ITab {
    type: ConsultType;
    name?: string;
}
export enum StyleType {
    NORMAL = 1,
    SQUARE = 2,
    ROUND = 3,
    STRUCTURE = 4,
}
// export enum StyleType {
//     NORMAL = 'NORMAL',
//     SQUARE = 'SQUARE',
//     ROUND = 'ROUND'
// }
export interface MultiConsultProps {
    tabs: ITab[];
    /** 客服相关配置 */
    customerServiceOpt?: CustomerServiceProps;
    /** 电话号码 */
    telOpt?: Pick<TelProps, 'tel' | 'telType' | 'iccId'>;
    formOpt?: {
        /** 最开始用的表单id，但是后来一个页面可以多个表单了 */
        formId?: string;
        index?: number;
    };
    styleType?: StyleType;
    className?: string;
    /** 不需要空白占位 */
    noBottom?: boolean;
    backgroundColor?: string;
    // iconStyle?: {
    //     color?: string; //字体颜色
    //     fontSize?: string; //字体大小
    //     backgroundColor?: string; //字体颜色
    // };
    iconColor?: string;
    iconFontSize?: number;
    iconFontColor?: string;
    formProps?: Omit<IFormProps, 'formType'>;
    formOption?: FormOption;
    dynamicStatus?: boolean;
    isFromStorePage?: boolean;
}

export enum ConsultType {
    onlineConsult = 1,
    call,
    submitForm,
}
export const config = {
    [ConsultType.onlineConsult]: {
        name: '在线咨询',
        Icon: CustomerServiceIcon,
    },
    [ConsultType.call]: {
        name: '电话拨打',
        Icon: TelIcon,
    },
    [ConsultType.submitForm]: {
        name: '表单提交',
        Icon: FormIcon,
    },
};
const MultiConsult: React.FunctionComponent<MultiConsultProps> = (props) => {
    const {
        tabs,
        backgroundColor,
        noBottom,
        styleType,
        iconColor,
        iconFontColor,
        iconFontSize,
        formProps,
        formOption,
        customerServiceOpt,
        telOpt,
        formOpt,
        className,
        dynamicStatus,
        isFromStorePage = false,
    } = props;
    const { edit } = useComContext();
    const [loading, setLoading] = React.useState(false);
    const [showFormLabel, setShowFormLabel] = React.useState(false);
    const [consultPageUrl, setConsultPageUrl] = React.useState(customerServiceOpt?.url);
    const [privateLetterDeepLink, setPrivateLetterDeepLink] = React.useState<string>();
    const [openH5, setOpenH5] = React.useState(false);
    const [linkSwitch, setLinkSwitch] = React.useState<boolean>(false);
    const [renderTabs, setRenderTabs] = React.useState(tabs);
    const [consultLink, setConsultLink] = React.useState('');

    const { pageJson, pageType } = useRenderState((state) => {
        return {
            pageJson: state.rolex.opt?.originalPageJson,
            pageType: state.rolex.opt?.originalPageJson?.type,
        };
    });
    const getPrivateLetterDeepLink = async () => {
        const res = await getPrivateLetterInfo({
            userId: customerServiceOpt?.uid as number,
        });
        if (isInApp) {
            return res?.data?.deepLink;
        } else {
            return res?.data?.h5Url;
        }
    };
    React.useEffect(() => {
        if (customerServiceOpt?.csType === IM_TYPE.PRIVATE_LETTER && customerServiceOpt?.uid) {
            getPrivateLetterDeepLink().then((res) => {
                setPrivateLetterDeepLink(res);
            });
        }
    }, [customerServiceOpt?.uid]);

    React.useEffect(() => {
        const fetchConsultInfo = async () => {
            if (pageType !== EnumPageType.结构化落地页) return;

            await importBridge();
            try {
                let newTabs = [...renderTabs];
                const adBridge = getAdBridge();
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const res = (await adBridge?.getData()) as any;

                const ad = res?.data?.ad;
                const h5Data = JSON.parse(ad?.adDataV2?.h5Data || '{}');

                /** 本地测试用数据 */
                // const h5Data = JSON.parse(
                //     '{"extraDisplayInfo":{"exposeTag":null,"showStyle":0,"exposeTagInfoList":null},"consultLink":"kwai://chat/session?sessionId=2813712340&extraInfo=%7B%22adCallback%22:%22nWXmxS5B9yACUWslL7-mpUS8drc2pDLIx1zaTaJe_qHuwgMzBQ8VrF9mbErrpO_qlLlsyi2m-GFzrKx39hhy1KtZpETQqo01VkqvbHIxUBlnf3DxEAtiG-M7rTmCc71WI4b_f_dlSs4bQ87rPqNExnlnOIGi8jm9_w9fZGqbhiYngu8SaPLXzPS6Ha6YPG1QI5GhRI3S5oQn60Mpce7Mi_Xdjk-_3KzGNAbNxG8BRW0%22,%22pageFrom%22:%225%22,%22plcInfo%22:%22__PLC_EXTRA_INFO__%22,%22lpId%22:%22656629324831296600%22%7D&msgExtraInfo=%7B%22adCallback%22:%22nWXmxS5B9yACUWslL7-mpUS8drc2pDLIx1zaTaJe_qHuwgMzBQ8VrF9mbErrpO_qlLlsyi2m-GFzrKx39hhy1KtZpETQqo01VkqvbHIxUBlnf3DxEAtiG-M7rTmCc71WI4b_f_dlSs4bQ87rPqNExnlnOIGi8jm9_w9fZGqbhiYngu8SaPLXzPS6Ha6YPG1QI5GhRI3S5oQn60Mpce7Mi_Xdjk-_3KzGNAbNxG8BRW0%22,%22pageFrom%22:%225%22,%22plcInfo%22:%22__PLC_EXTRA_INFO__%22,%22lpId%22:%22656629324831296600%22%7D&pageStyle=1&logParams=%7B%22ad_callback%22:%22nWXmxS5B9yACUWslL7-mpUS8drc2pDLIx1zaTaJe_qHuwgMzBQ8VrF9mbErrpO_qlLlsyi2m-GFzrKx39hhy1KtZpETQqo01VkqvbHIxUBlnf3DxEAtiG-M7rTmCc71WI4b_f_dlSs4bQ87rPqNExnlnOIGi8jm9_w9fZGqbhiYngu8SaPLXzPS6Ha6YPG1QI5GhRI3S5oQn60Mpce7Mi_Xdjk-_3KzGNAbNxG8BRW0%22,%22expert_biz_type%22:%22KSIM_TO_ADIM%22,%22current_page_infos%22:%7B%22page_from%22:%225%22%7D%7D&expertWorkbenchHideFollowCard=1&presentStyleOpt=1","isShowConsult":true}',
                // );
                const { isShowConsult, consultLink } = h5Data;
                if (isShowConsult) {
                    newTabs.unshift({
                        type: ConsultType.onlineConsult,
                        name: '在线聊',
                    });
                    setRenderTabs(newTabs);
                    setConsultLink(consultLink);
                }
            } catch (e) {
                console.log('------e', e);
            }
        };
        fetchConsultInfo();
    }, []);
    const onClick = React.useCallback(
        (type: ConsultType) => {
            switch (type) {
                case ConsultType.onlineConsult: {
                    if (pageType === EnumPageType.结构化落地页) {
                        openServiceUrl({
                            comType: 'MULTI_CONSULT_DRAG',
                            csType: IM_TYPE.PRIVATE_LETTER,
                            privateDeepLink: consultLink,
                            linkSwitch: true,
                            extraUrlParams: {
                                jumpSource: 0,
                            },
                        });
                    } else {
                        const appId = customerServiceOpt?.appId;
                        const funcId = customerServiceOpt?.funcId;
                        const csType =
                            customerServiceOpt?.csType ||
                            (appId === 10000 ? IM_TYPE.KUAI : IM_TYPE.OTHER);
                        openServiceUrl({
                            comType: isFromStorePage ? 'STORE_PAGE' : 'MULTI_CONSULT_DRAG',
                            url: consultPageUrl,
                            funcId,
                            csType,
                            privateDeepLink: privateLetterDeepLink || customerServiceOpt?.deepLink,
                            openH5,
                            linkSwitch,
                            extraUrlParams: {
                                jumpSource: 0,
                            },
                        });
                    }
                    break;
                }
                case ConsultType.call: {
                    const tel = telOpt?.tel;
                    const telType = telOpt?.telType;
                    const iccId = telOpt?.iccId;
                    const callParam: ICallTelParam = {
                        telType,
                        iccId,
                        loading,
                        setLoading,
                        comType: isFromStorePage ? 'STORE_PAGE' : 'MULTI_CONSULT_DRAG',
                        tel,
                        pageId: pageJson?.outPageId,
                    };
                    callTel(callParam);
                    break;
                }
                case ConsultType.submitForm: {
                    if (pageType === EnumPageType.结构化落地页) {
                        //这个console保留
                        console.log('[======触发表单提交=======]');
                        eventEmitterOverall?.emit('submitForm');
                    } else {
                        if (
                            formOption === FormOption.NewCommon ||
                            formOption === FormOption.NewPay
                        ) {
                            setShowFormLabel(true);
                        } else {
                            const index = formProps?.index ?? formOpt?.index;
                            const formId = formOpt?.formId;

                            // let componentType = 'XIANSUO_FORM_DRAG';
                            // if (formOption === FormOption.ExistPay) {
                            //     componentType = 'XIANSUO_FORM_PAY';
                            // }
                            if (index != null) {
                                scrollToAnchorForm(index);

                                return;
                            }
                            if (!formId) {
                                return;
                            }
                            const node =
                                document.querySelector(`.${formClass}[data-form-id="${formId}"]`) ??
                                undefined;

                            node?.scrollIntoView();
                            showAnchorFormIcon({ targetNode: node });
                        }
                    }

                    break;
                }
                default:
                    break;
            }
        },
        [consultPageUrl, openH5, privateLetterDeepLink, linkSwitch],
    );
    // 由于咨询页支持修改，修改后咨询页的页面url也会改变，所以在c端实时读取咨询页的url
    // 考虑到用户体验问题，获取url的操作前置而不是点击的时候请求
    React.useEffect(() => {
        if (!customerServiceOpt?.funcId) return;
        fetchConsultPageUrl(customerServiceOpt?.funcId).then((res) => {
            const { switchStatus, consultPageUrl, isH5Url } = res;
            setLinkSwitch(switchStatus);
            setConsultPageUrl(consultPageUrl);
            setOpenH5(isH5Url);
        });
    }, [customerServiceOpt?.funcId]);
    // console.log('tabs---->12', tabs);

    // if (styleType === StyleType.STRUCTURE) {
    //     return <StructureType {...props} />;
    // }

    const structureStyle = (type: ConsultType) => {
        if (pageType !== EnumPageType.结构化落地页) return {};
        return {
            width: type === ConsultType.onlineConsult ? 44 : 'auto',
            marginLeft: type === ConsultType.onlineConsult ? 12 : 0,
            height: 64,
        };
    };
    return (
        <>
            <Ol
                className={cs(className, styles.root, 'no-adaptor', {
                    [styles.style2]: styleType === StyleType.SQUARE,
                    [styles.style3]: styleType === StyleType.ROUND,
                    [styles.animation]: dynamicStatus,
                    [styles.animationInclineSweepLight]: dynamicStatus,
                })}
                style={
                    {
                        backgroundColor: styleType === StyleType.NORMAL ? backgroundColor : '#fff',
                        color: iconFontColor,
                        fontSize: iconFontSize,
                        borderTop: styleType === StyleType.STRUCTURE ? '1px solid #EAEAEA' : 'none',
                        '--varIncline':
                            renderTabs.length === 3
                                ? '400px'
                                : renderTabs.length === 2
                                  ? '600px'
                                  : '800px',
                    } as React.CSSProperties
                }
            >
                {renderTabs.map((t, index) => {
                    const conf = config[t.type];
                    return (
                        <Tab
                            index={index}
                            key={t.type}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...conf}
                            name={t.name}
                            onClick={() => onClick(t.type)}
                            type={t.type}
                            backgroundColor={backgroundColor}
                            styleType={styleType}
                            tabs={renderTabs}
                            iconColor={iconColor}
                            iconFontColor={iconFontColor}
                            iconFontSize={iconFontSize}
                            style={structureStyle(t.type)}
                        />
                    );
                })}
            </Ol>
            {!noBottom && (
                <>
                    <BlockPlaceholder pos="BOTTOM" height={64} preview={edit} />
                    <SafeAreaPlace
                        style={{
                            backgroundColor:
                                styleType === StyleType.NORMAL ? backgroundColor : '#fff',
                        }}
                    />
                </>
            )}
            {showFormLabel && !!formProps && (
                <Modal
                    style={{
                        zIndex: 999,
                    }}
                >
                    {formOption === FormOption.NewPay ? (
                        <PayFormDialog
                            formProps={formProps}
                            onClose={() => {
                                setShowFormLabel(false);
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    ) : (
                        <FormDialog
                            formProps={formProps}
                            onClose={() => {
                                setShowFormLabel(false);
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    )}
                </Modal>
            )}
        </>
    );
};

export interface TabProps {
    index?: number;
    name?: string;
    className?: string;
    style?: React.CSSProperties;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    type: ConsultType;
    onClick: () => void;
    backgroundColor?: string;
    // iconStyle?: React.CSSProperties;
    styleType?: StyleType;
    tabs?: { type: ConsultType; name?: string }[];
    iconColor?: string;
    iconFontSize?: number;
    iconFontColor?: string;
}
export const Tab: React.FunctionComponent<TabProps> = (props) => {
    const {
        className,
        style,
        name,
        Icon,
        type,
        onClick,
        // backgroundColor,
        styleType,
        index,
        tabs,
        // iconColor,
        iconFontColor,
        iconFontSize,
    } = props;
    const { color: themeIconColor } = useTheme({
        path: 'iconColor',
    });
    const { color: themeBackgroundColor } = useTheme({
        path: 'backgroundColor',
    });

    const tabsLen = (tabs?.length as number) - 1;

    const handleBkgColor = () => {
        if (index !== tabsLen) {
            return {};
        }
        if (themeBackgroundColor.includes('linear-gradient')) {
            return {
                background: themeBackgroundColor,
            };
        }
        return {
            backgroundColor: themeBackgroundColor,
        };
    };
    if (styleType !== StyleType.NORMAL) {
        return (
            <li
                className={cs(styles.item, className, {
                    [styles.structureButton]:
                        styleType === StyleType.STRUCTURE && index === tabsLen,
                    [styles.structureIcon]: styleType === StyleType.STRUCTURE && index !== tabsLen,
                })}
                data-type={type}
                style={{
                    ...style,
                    ...handleBkgColor(),
                }}
                onClick={onClick}
            >
                {(styleType !== StyleType.STRUCTURE ||
                    (styleType === StyleType.STRUCTURE && index !== tabsLen)) && (
                    <Icon
                        className={styles.icon}
                        style={{
                            fill:
                                styleType === StyleType.STRUCTURE || index === tabsLen
                                    ? themeIconColor
                                    : '',
                            width: styleType === StyleType.STRUCTURE ? 20 : 17,
                            height: styleType === StyleType.STRUCTURE ? 20 : 17,
                        }}
                    />
                )}

                <p
                    style={{
                        color: index === tabsLen ? iconFontColor : '#666666',
                        fontSize: index === tabsLen ? iconFontSize : '12px',
                        fontWeight: index === tabsLen ? 600 : 'normal',
                    }}
                >
                    {name}
                </p>
            </li>
        );
    }

    return (
        <li
            className={cs(styles.item, className)}
            data-type={type}
            style={{
                ...style,
                // backgroundColor: themeBackgroundColor,
            }}
            onClick={onClick}
        >
            <Icon className={styles.icon} style={{ fill: themeIconColor }} />
            <p
                style={{
                    color: iconFontColor,
                    fontSize: iconFontSize,
                }}
            >
                {name}
            </p>
        </li>
    );
};

export default MultiConsult;
