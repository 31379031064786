import { useLpUserInfo } from '@/utils/useLpUserInfo';
import { useEffect, useState } from 'react';
import { fireflyIsProd, fireflySDK } from 'src/utils/firefly';

export enum FireFlyHostEnum {
    PROD = '/kos/nlav11175/moliFE/',
    STAGING = '/kc/files/a/bird-test-cdn/moliFE/',
}

export enum FireFlyEnv {
    TEST = 'TEST',
    PROD = 'PROD',
}

export function useStepQYWXSwitch() {
    const [enable, setEnable] = useState<boolean>(true);
    useEffect(() => {
        fireflySDK
            .getData({
                path: fireflyIsProd
                    ? `${FireFlyHostEnum.PROD}stepFormWithQYWXSubmitSwitch.json`
                    : `${FireFlyHostEnum.STAGING}stepFormWithQYWXSubmitSwitch.json`, // 注：测试环境与线上环境path不一样
            })
            .then((res) => {
                console.log('[firefly res]', res);
                setEnable(res.data);
            })
            .catch(() => {
                console.error('Fetch Heatmap config error');
            });
    }, []);
    return enable;
}

export function useColorThemeSwitch() {
    const accountId = useLpUserInfo('accountId');
    const [tailNumber, setTailNumber] = useState<string>('');
    const [isInWhitelist, setIsInWhitelist] = useState<boolean>(false);
    useEffect(() => {
        if (accountId && tailNumber) {
            setIsInWhitelist(isAccountInWhitelist(accountId, tailNumber));
        }
    }, [accountId, tailNumber]);
    useEffect(() => {
        fireflySDK
            .getData({
                path: fireflyIsProd
                    ? '/kos/nlav11175/magicsite/editorColorTheme.json'
                    : '/kc/files/a/bird-test-cdn/magicsite/editorColorTheme.json', // 注：测试环境与线上环境path不一样
            })
            .then((res) => {
                console.log('[firefly res]', res);
                setTailNumber(res.data);
            })
            .catch(() => {
                console.error('Fetch Heatmap config error');
            });
    }, []);
    return isInWhitelist;
}

/** 处理tail_number */
export function isAccountInWhitelist(accountId: number, tailNumber: string): boolean {
    // 解析 tail_number 字符串以获取各个部分
    const parts = tailNumber.split(';');
    if (parts.length < 4) {
        throw new Error('Invalid tail_number format');
    }

    // 解析模数底
    const base = parseInt(parts[0]);

    // 解析白名单并分割成数组
    const whitelist = parts[2].split(',').map(Number);

    // 检查 accountId 是否在白名单中
    if (whitelist.includes(accountId)) {
        // 解析黑名单并分割成数组
        const blacklist = parts[3].split(',').map(Number);
        // 即使 accountId 在白名单中，也需要检查它是否不在黑名单中
        return !blacklist.includes(accountId);
    }

    // 解析并处理生效部分
    const effectiveRanges = parts[1].split(',');
    let isEffective = false;
    for (let range of effectiveRanges) {
        if (range.includes('-')) {
            const [start, end] = range.split('-').map(Number);
            if (accountId % base >= start && accountId % base <= end) {
                isEffective = true;
                break;
            }
        } else {
            if (accountId % base === parseInt(range)) {
                isEffective = true;
                break;
            }
        }
    }

    // 如果不在生效范围内，直接返回 false
    return isEffective;
}

export function useShowCorporateInfo() {
    const [enable, setEnable] = useState<boolean>(true);
    useEffect(() => {
        fireflySDK
            .getData({
                path: fireflyIsProd
                    ? `${FireFlyHostEnum.PROD}enableAggregateShowCorporInfo.json`
                    : `${FireFlyHostEnum.STAGING}enableAggregateShowCorporInfo.json`, // 注：测试环境与线上环境path不一样
            })
            .then((res) => {
                console.log('[firefly res]', res);
                setEnable(res.data);
            })
            .catch(() => {
                console.error('fetch ShowCorporInfo firefly error');
            });
    }, []);
    return enable;
}

export const getChangeAggregateCorporInfoPoi = async () => {
    try {
        const res = await fireflySDK.getData({
            path: fireflyIsProd
                ? `${FireFlyHostEnum.PROD}aggregateCorporInfoPoiChange.json`
                : `${FireFlyHostEnum.STAGING}aggregateCorporInfoPoiChange.json`, // 注：测试环境与线上环境path不一样
        });
        return res?.data || false;
    } catch (e) {
        console.error('fetch changeCorporInfoPoi firefly error', e);
        return false;
    }
};

export const enableSendEffectiveCustomerReport = async () => {
    try {
        const res = await fireflySDK.getData({
            env: fireflyIsProd ? FireFlyEnv.PROD : FireFlyEnv.TEST,
            key: 'moliFE.effectiveCustomerReport', // 项目名称.发布组合英文名，可在 Firefly 平台上看到
        });
        return res?.data || false;
    } catch (e) {
        console.error('fetch effectiveCustomerReport firefly error', e);
        return false;
    }
};

export const getJuXingCPL2WhiteList = async () => {
    try {
        const res = await fireflySDK.getData({
            env: fireflyIsProd ? FireFlyEnv.PROD : FireFlyEnv.TEST,
            key: 'moliFE.juxingCPL2WhiteList', // 项目名称.发布组合英文名，可在 Firefly 平台上看到
        });
        return res?.data || ['plc', 'resultPage'];
    } catch (e) {
        console.error('fetch juxingCPL2WhiteList firefly error', e);
        return ['plc', 'resultPage'];
    }
};
export function useLangPageLinkCheck() {
    const [enable, setEnable] = useState<boolean>(true);
    useEffect(() => {
        fireflySDK
            .getData({
                env: fireflyIsProd ? FireFlyEnv.PROD : FireFlyEnv.TEST,
                key: 'moliFE.langPageLinkCheck', // 项目名称.发布组合英文名，可在 Firefly 平台上看到
                // path: fireflyIsProd
                //     ? `${FireFlyHostEnum.PROD}langPageLinkCheck.json`
                //     : `${FireFlyHostEnum.STAGING}langPageLinkCheck.json`, // 注：测试环境与线上环境path不一样
            })
            .then((res) => {
                console.log('[firefly langPageLinkCheck res]', res);
                setEnable(res?.data ?? true);
            })
            .catch(() => {
                console.error('fetch ShowCorporInfo firefly error');
            });
    }, []);
    return enable;
}

export const showForceFormAgreement = async () => {
    try {
        const res = await fireflySDK.getData({
            env: fireflyIsProd ? FireFlyEnv.PROD : FireFlyEnv.TEST,
            key: 'moliFE.useFormManualCheck',
        });
        return res?.data || false;
    } catch (e) {
        return false;
    }
};
