import { EnumPageType } from 'omega-shared/types';
import { getPageJson } from './fetchCompPropsInPageJson';
import { getUrlParam } from './getUrlParam';

export const getPageId = () => {
    const pageJSONCache = getPageJson();
    const { originPageId } = pageJSONCache?.pageMeta?.structurePageInfo || {};
    const pageId =
        pageJSONCache?.type === EnumPageType.结构化落地页 && originPageId
            ? originPageId
            : getUrlParam('pageId');
    return pageId ?? null;
};
