import styles from './style.module.less';
import { Div } from '../Blank/Blank';
import { fetchShufflingData, ShufflingDataType } from './utils';
import { useEffect, useMemo, useState } from 'react';
import cs from 'classnames';

/** 产品信息模块，包含滚动头像组件、文字组件 */
export interface DragShufflingIconProps {
    isShowReserve: boolean;
    isShowInterest: boolean;
    isShowBrowse: boolean;
}
export const DragShufflingIcon: React.FunctionComponent<DragShufflingIconProps> = (props) => {
    // 修改为增加一个选项选择是否展示
    const { isShowReserve, isShowInterest, isShowBrowse } = props;
    const [shufflingData, setShufflingData] = useState<ShufflingDataType>();

    // 头像列表
    const [headImgList, setHeadImgList] = useState<string[]>([]);

    const [visibleUsers, setVisibleUsers] = useState<string[]>();
    const [isTransitioning, setIsTransitioning] = useState(false);

    useEffect(() => {
        if (headImgList?.length) {
            setVisibleUsers(headImgList);
            const interval = setInterval(() => {
                setIsTransitioning(true);
                setTimeout(() => {
                    setIsTransitioning(false);
                    setVisibleUsers((prevUsers) => {
                        if (prevUsers) {
                            const nextUsers = [...prevUsers];
                            // 去除最右边头像
                            const lastImg = nextUsers.pop();
                            // 向最左边前面插入
                            nextUsers.unshift(lastImg as string);
                            return nextUsers;
                        }
                    });
                }, 250);
            }, 1250);

            return () => clearInterval(interval);
        }
    }, [headImgList]);

    const shouldFetchData = useMemo(() => {
        // 获取是否展示
        return isShowReserve || isShowBrowse || isShowInterest;
    }, [isShowReserve, isShowBrowse, isShowInterest]);

    useEffect(() => {
        const fetchData = async () => {
            // 请求转化头像
            // 请求转化数据
            if (shouldFetchData) {
                const shufflingData = await fetchShufflingData(props);
                // 获取转化数据
                setShufflingData(shufflingData);
                setHeadImgList(shufflingData?.headImgList as string[]);
            }
        };
        fetchData();
    }, [shouldFetchData]);

    // 不同类型组件曝光情况时获取数据
    return (
        <Div className={styles.shufflingContainer}>
            {/* css实现 */}
            <div className={styles.itemContainer}>
                <div
                    style={{
                        width: 56,
                        height: 16,
                        position: 'relative',
                        marginLeft: -24,
                    }}
                >
                    <div
                        style={{
                            width: 32,
                            height: 16,
                            marginLeft: 24,
                            overflow: 'hidden',
                        }}
                    >
                        {visibleUsers?.length &&
                            visibleUsers?.map((imgUrl, index) => (
                                <img
                                    key={index}
                                    src={imgUrl}
                                    style={{
                                        width: 14,
                                        height: 14,
                                        borderRadius: 10,
                                        border: '1px solid white',
                                        zIndex: (6 - index) * 10,
                                        position: 'absolute',
                                        top: 1,
                                        left: index === 0 ? 1 : `${8 * index + 1}px`,
                                        // 起始状态
                                        opacity: index < 3 ? 0 : 1,
                                    }}
                                    className={cs({
                                        // 开始动画
                                        [styles.fadeoutFirst]: index === 5 && isTransitioning,
                                        [styles.fadeInMid]: index !== 5 && isTransitioning,
                                        [styles.fadeInFour]: index === 2 && isTransitioning,
                                    })}
                                />
                            ))}
                    </div>
                </div>
                <text className={styles.shufflingText}>
                    近7天{shufflingData?.reserveCount}人已预约
                </text>
            </div>

            {isShowInterest && (
                <div className={styles.itemContainer}>{shufflingData?.intersetCount}+人感兴趣</div>
            )}
            {isShowBrowse && (
                <div className={styles.itemContainer}>{shufflingData?.browseCount}人正在看</div>
            )}
        </Div>
    );
};

export default DragShufflingIcon;
