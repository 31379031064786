/* eslint-disable @typescript-eslint/no-floating-promises */
import { invoke } from '@yoda/bridge';
import { Toast } from '@ad/sharpui';
import cs from 'classnames';
import { useComContext, useRenderState } from 'omega-render';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { ACTION_TYPE, trackClick } from 'src/landingPage/common/adTrack/util/zt';

import { sendAdElementClickLog } from 'landingPage/common/adTrack/adBridgeLog';
import { EnumPageType } from 'omega-shared/types';
import { useProgress } from '../AppDownload/AppDownload';
import { AppDownloadState, useAppDownloadActions } from '../AppDownloadDrag/redux';
import { Div } from '../Blank/Blank';
import {
    imageLoadType,
    trackBridgeError,
    trackBridgeSuccess,
    trackOldImage,
} from '../../common/adTrack/monitor';
import { useEditHeight } from '../../common/hook/useEditHeight';
import { isInApp, isSSR } from '../../common/utils/env';
import { preventWakeUpApp } from '../../common/utils/preventWakeUpApp';
import { ELinkType } from '../Image/Image';
import { Portal } from './Portal';
import styles from './style.module.less';
import { EnhanceImage } from './EnhanceImage';
import { createPromiseAndExecutor } from '../../common/utils/promise';
import { postCheckOutLink } from '@/landingPage/services/apis';
import { getPageId } from '@/landingPage/common/utils/getPageId';

export interface ImageProps {
    imgUrl: string;
    targetUrl?: string;
    /**   1是普通链接，2是快应用，3是应用下载 */
    linkType?: ELinkType;
    appInfo?: any;
    quickAppLink?: string;
    className?: string;
    loadend?: () => void;
    iOSLink?: string;
    style?: React.CSSProperties;
    /** 选中图片的样式 */
    stroke?: {
        // width: number;
        // color: string;
        borderRadius: number; // 圆角大小
    };
}

const { selfPromise: checkTargetUrlValidPromise, promiseResolver } = createPromiseAndExecutor();

const Image: React.FunctionComponent<ImageProps> = (props) => {
    const {
        className,
        imgUrl,
        linkType = ELinkType.NormalLink,
        targetUrl,
        appInfo,
        quickAppLink,
        loadend,
        iOSLink,
        children,
        style,
        stroke,
    } = props;
    const { editHeight } = useEditHeight();
    const { preview, isSupportWebp } = useComContext();
    console.log('useComContext isSupportWebp::', isSupportWebp);
    const { appDownload } = useRenderState((state) => {
        return { appDownload: state.appDownload as AppDownloadState };
    });
    const hasAppDownload = appDownload?.hasAppDownload;
    useEffect(() => {
        if (linkType !== ELinkType.NormalLink) {
            trackOldImage();
        }
    }, [linkType]);

    const targetUrlInfo = React.useRef({
        linkValid: true,
        needCheck: false, // 默认false，不需要接口校验，兼容老逻辑
    });

    useEffect(() => {
        const checkTargetUrlValid = async () => {
            if (!(!!getPageId() && !!targetUrl)) {
                promiseResolver('done');
                return;
            }
            // 防止接口一直不返回 5s后直接resolve
            setTimeout(() => {
                promiseResolver('valid');
            }, 5000);
            try {
                const data = await postCheckOutLink({
                    pageId: getPageId(),
                    url: targetUrl as string,
                });
                targetUrlInfo.current = {
                    // newLogicSwitch 为true 走新的接口校验逻辑取status，newLogicSwitch 为false 走老逻辑默认不校验，默认认为接口校验通过了
                    linkValid: data.newLogicSwitch ? data.status : true,
                    needCheck: data.newLogicSwitch,
                };
            } catch (e) {
                // 如果接口请求报错，默认放行
                console.log('checkTargetUrlValid error', e);
            } finally {
                promiseResolver('valid'); // resolve promise
            }
        };

        checkTargetUrlValid();
    }, []);
    const actions = useAppDownloadActions();
    const onClick = useCallback(
        (clickPosition: 2 | 3) => {
            (async () => {
                trackClick(ACTION_TYPE.IMAGE);
                // 调起链接
                if (linkType === ELinkType.NormalLink) {
                    if (targetUrl) {
                        const jump = async () => {
                            if (isInApp) {
                                try {
                                    await invoke('tool.loadUrlOnNewPage', {
                                        url: targetUrl,
                                        type: 'back',
                                    });
                                    trackBridgeSuccess('loadUrlOnNewPage');
                                } catch (e) {
                                    trackBridgeError('loadUrlOnNewPage', e);
                                }
                            } else {
                                window.location.href = targetUrl;
                            }
                        };
                        await preventWakeUpApp();
                        try {
                            await checkTargetUrlValidPromise;
                            const { needCheck, linkValid } = targetUrlInfo.current;

                            if (needCheck && linkValid === false) {
                                Toast.error('链接失效');
                            } else {
                                jump();
                            }
                        } catch (e) {
                            // catch 也让跳转，确保能走下去
                            jump();
                            console.log('img target err', e);
                        }
                    }
                } else {
                    actions.openOrDownload({
                        type: clickPosition,
                        appInfo,
                        quickAppLink,
                        iOSLink,
                        linkType: linkType as 2 | 3 | 4,
                    });
                }
                // 下载中间页打点
                if (
                    [EnumPageType.智能中间页, EnumPageType.PLC中间页].includes(
                        JSON.parse(window.pageJsonString ?? '{}').type,
                    )
                ) {
                    sendAdElementClickLog();
                }
            })();
        },
        [linkType, targetUrl, actions, appInfo, quickAppLink, iOSLink],
    );

    const { buttonText } = useProgress('点击下载');

    const onLoad: (type: string) => () => void = (type: string) => () => {
        imageLoadType(type);
        // 统计load完成时间
        if (loadend) {
            loadend();
        }
    };

    return (
        <Div
            className={cs(styles.root)}
            style={{
                height: editHeight ?? (props as any).height,
                borderRadius: stroke?.borderRadius ?? 0,
            }}
        >
            <EnhanceImage
                onLoad={onLoad('image')}
                className={cs(styles.img, className)}
                src={imgUrl}
                style={style}
                alt="图片"
                onClick={() => onClick(2)}
            />
            {!isSSR && !hasAppDownload && (linkType === 2 || linkType === 3) && (
                <Portal>
                    <div
                        className={styles.download}
                        onClick={preview ? undefined : () => onClick(3)}
                    >
                        <div>
                            {buttonText}
                            <div className={styles.shadow} style={{ width: buttonText }} />
                        </div>
                    </div>
                </Portal>
            )}
            {children}
        </Div>
    );
};

export default Image;
