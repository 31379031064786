/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { CountdownDragProps } from './CountdownDrag';
import styles from './style.module.less';
import { useCountdownHook, getTimeDiffText } from './countdownHook';
import { useState } from 'react';
import { generateRandomEndTime } from '../XiansuoFormDrag/newFormStayModal/utils';

const RandomStyleCountdown: React.FunctionComponent<CountdownDragProps> = (props) => {
    const { countdownFontColor, countdownFontSize } = props;

    const [endTime, setEndTime] = useState(0);
    const hookProps = useCountdownHook(endTime);

    React.useEffect(() => {
        setEndTime(generateRandomEndTime());
    }, []);

    const { hourdiff, minutediff, seconddiff } = hookProps;

    const renderTimeDiff = (timediff: number, unit: string) => {
        return (
            <div
                className={styles.timebox}
                style={{
                    color: countdownFontColor,
                    fontSize: countdownFontSize,
                }}
            >
                <div className={styles.timeText}>
                    {getTimeDiffText(timediff).map((item) => {
                        return item;
                    })}
                </div>
                {Boolean(unit.length) && <div className={styles.unit}>{unit}</div>}
            </div>
        );
    };

    return (
        <div className={styles.randomStyle}>
            {[hourdiff, minutediff, seconddiff].map((item, index) => {
                return renderTimeDiff(item, `${index !== 2 ? ':' : ''}`);
            })}
        </div>
    );
};

export default RandomStyleCountdown;
