import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ComponentZIndex } from 'omega-shared/utils';
import cs from 'classnames';
import { findComponent, useRenderState } from 'omega-render';
import { getPopupInfo } from '../../common/api';
import { getPageId } from '../../common/utils/getPageId';
import { dataTrack } from '../../common/utils/trackAd';
import { PopupDetail } from '../../common/api/interface';
import { isInCooling, updateCoolingTime } from './utils';
import styles from './index.module.less';
import { getPicCDNSource } from 'landingPage/common/utils/getUrlParam';

/** 表单转化动作，包含的组件类型 */

const FORM_COMPONENT = ['XIANSUO_FORM', 'XIANSUO_FORM_DRAG', 'XIANSUO_FORM_PAY'];

interface GuideInfo {
    guideMsg: string;
    buttonText: string;
    iconUrl: string;
}

interface InteractivePopupStyleProps {
    guideInfo: GuideInfo;
    onClose: () => void;
    onButtonClick: () => void;
    onImpression: () => void;
}

export function InteractivePopupStyle(props: InteractivePopupStyleProps) {
    const { guideInfo, onImpression, onClose, onButtonClick } = props;
    const [animation, setAnimation] = useState(false);
    const [destroy, setDestroy] = useState(false);
    const timeoutHide = useCallback(() => {
        window.setTimeout(() => setAnimation(false), 3000);
        window.setTimeout(() => setDestroy(true), 3800);
    }, []);
    const close = useCallback(() => {
        setDestroy(true);
        onClose();
    }, [props]);
    useEffect(() => {
        window.setTimeout(() => {
            setAnimation(true);
            timeoutHide();
        }, 200);
    }, [timeoutHide]);
    useEffect(() => {
        /** 组件曝光 */

        onImpression();
    }, [onImpression]);
    return destroy ? null : (
        <div className={styles.styleWrapper}>
            <div className={cs([styles.interactPopup, animation ? styles.animation : undefined])}>
                <div className={styles.main}>
                    <div
                        className={styles.icon}
                        style={{ backgroundImage: `url(${guideInfo.iconUrl})` }}
                    />
                    <div className={styles.guideText}>{guideInfo.guideMsg}</div>
                    <div className={styles.button} onClick={onButtonClick}>
                        {guideInfo.buttonText}
                    </div>
                    <div className={styles.closeIconWrapper} onClick={close}>
                        <div className={styles.closeIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const formatTrackDynamicData = (data: PopupDetail) => {
    const res: string[] = [];
    const popupType = data.type;
    const popupId = data.id;
    [
        ...data.conversionComponents,
        ...data.guideTextList,
        ...data.iconList,
        ...data.showPositions,
    ].forEach((element) => {
        res.push(`${popupType}_${popupId}_${element.elementType}_${element.elementId}`);
    });
    return res;
};

const InteractivePopup: React.FC = () => {
    /** 动态优选埋点参数 */
    const [dynamicParams, setDynamicParams] = useState<string[]>([]);
    /** 曝光是否已上报标志位 */
    const impressionTracked = useRef(false);
    const [guideInfo, setGuideInfo] = useState<GuideInfo>({
        guideMsg: '',
        buttonText: '',
        iconUrl: '',
    });
    const renderComponents = useRenderState((state) => {
        return state.rolex.components;
    });
    const firstForm = useMemo(() => {
        return findComponent(renderComponents, (component) => {
            return FORM_COMPONENT.includes(component.type);
        });
    }, [renderComponents]);
    const [mounted, setMounted] = useState(false);
    /** 关闭弹窗 */
    const onClose = useCallback(() => {
        console.log('onClose');
        dataTrack({
            eventType: 'EVENT_POPUP_WINDOW_CLOSE',
            LpPopupWindowType: 2,
            page_id: getPageId(),
            diyExtraInfo: JSON.stringify({
                copywritingId: dynamicParams,
            }),
        });
        /** 更新用户关闭事件，用来计算冷却期 */
        updateCoolingTime(getPageId());
    }, [dynamicParams]);
    /** 弹窗曝光上报埋点 */
    const onImpression = useCallback(() => {
        if (!impressionTracked.current) {
            dataTrack({
                eventType: 'EVENT_POPUP_WINDOW_IMPRESSION',
                LpPopupWindowType: 2,
                page_id: getPageId(),
                diyExtraInfo: JSON.stringify({
                    copywritingId: dynamicParams,
                }),
            });
            impressionTracked.current = true;
        }
    }, [dynamicParams]);
    const mount = useCallback(() => {
        const mountTarget = document.querySelector('interactive-popup-wrapper');
        if (!mountTarget) {
            window.document.body.insertAdjacentHTML(
                'afterbegin',
                `<div id="interactive-popup-wrapper" style="z-index: ${ComponentZIndex.InteractivePopup}"></div>`,
            );
            setMounted(true);
        }
    }, []);
    /**  点击弹窗按钮 */
    const focus = useCallback(() => {
        dataTrack({
            eventType: 'EVENT_POPUP_WINDOW_CLICK',
            LpPopupWindowType: 2,
            page_id: getPageId(),
            diyExtraInfo: JSON.stringify({
                copywritingId: dynamicParams,
            }),
        });
        if (firstForm) {
            try {
                const node = document.querySelector(`div[data-id^="${firstForm.id}"]`);
                node?.scrollIntoView();
                dataTrack({
                    eventType: 'EVENT_ANCHORED_SUCCESS',
                    LpPopupWindowType: 2,
                    page_id: getPageId(),
                    diyExtraInfo: JSON.stringify({
                        copywritingId: dynamicParams,
                        anchoredType: 1,
                    }),
                });
            } catch (e) {
                console.error(e);
            }
        }
    }, [dynamicParams, firstForm]);

    useEffect(() => {
        async function getPopupDetail() {
            /** 用户手动关闭，一个自然日冷却期，不展示 */
            if (isInCooling(getPageId())) return;
            if (!firstForm) return;
            if (!getPageId()) return;
            const data = await getPopupInfo({ pageId: getPageId() });
            console.log('data', data);
            if (data) {
                setGuideInfo({
                    guideMsg: data.guideTextList?.[0]?.guideMsg,
                    buttonText: data.guideTextList?.[0]?.buttonText,
                    iconUrl: getPicCDNSource(
                        data.iconList?.[0]?.elementValue ||
                            'https://h2.static.yximgs.com/kos/nlav10749/lpsite/defaultInteractiveIcon.png',
                    ),
                });
                setDynamicParams(formatTrackDynamicData(data));
                // 挂载互动弹窗
                mount();
            }
        }
        getPopupDetail();
    }, [firstForm, mount]);
    return mounted && guideInfo.guideMsg
        ? createPortal(
              <InteractivePopupStyle
                  guideInfo={guideInfo}
                  onClose={onClose}
                  onButtonClick={focus}
                  onImpression={onImpression}
              />,
              document.querySelector('#interactive-popup-wrapper') as Element,
          )
        : null;
};

export default InteractivePopup;
