import cs from 'classnames';
import * as React from 'react';

import { preventWakeUpApp } from 'landingPage/common/utils/preventWakeUpApp';
import { useComContext, useRenderState } from 'omega-render';
import { isSSR } from 'omega-shared/utils';
import { Div } from '../Blank/Blank';
import { ETabType } from '../DragImage/DragImage';
import { EUrlType } from '../Image/Image';
import styles from './style.module.less';
import { SimpleRender } from './simpleRender';
import { EnumPageType } from 'omega-shared/types';
import { useTheme } from '@/landingPage/common/utils/useTheme';

export enum StructureTitleType {
    productTitle = 1, // 产品标题
    formTitle = 2, // 转化氛围条
    productInfo = 3, // 图文详情
    productProfit = 4, // 产品收益
}
export interface DragTextProps
    extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    text?: string;
    link?: string;
    linkType?: EUrlType;
    contentType?: ETabType;
    optionalConfigTexts: string[];
    activeIndex: number;
    singleText: string;
    type?: 'rich'; //    富文本
    richText?: any;
    richPureText?: string;
    displayType?: 'flex' | 'normal';
    structureTitleType?: StructureTitleType;
}
const defaultValue = [
    {
        type: 'paragraph',
        children: [{ text: '这里是富文本', fontSize: 22, letterSpace: 0, lineSpace: 1 }],
    },
];

const RichText = React.lazy(async () => import('./RichText'));
class RichTextErrorBoundary extends React.Component<
    { fallback: NonNullable<React.ReactNode> | null },
    {
        hasError: boolean;
    }
> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}

const FallbackUI = (props: { richText: Pick<DragTextProps, 'richText'> }) => {
    const { richText } = props;
    return (
        <div>
            <SimpleRender value={richText} />
        </div>
    );
};
const DragText: React.FunctionComponent<DragTextProps> = (props) => {
    const { id } = useComContext();
    const {
        text,
        style,
        link,
        children,
        type,
        richText = defaultValue,
        displayType = 'normal',
        structureTitleType,
    } = props;
    const { edit } = useComContext();
    const onClick = link
        ? async () => {
              await preventWakeUpApp();
              window.location.href = link;
          }
        : undefined;
    const pageJsonType = useRenderState((state) => state.rolex.opt?.originalPageJson?.type);
    React.useEffect(() => {
        //  中间页实时替换文本内容时blank高度更新
        if (!id) return;
        if (pageJsonType !== EnumPageType.智能中间页 && pageJsonType !== EnumPageType.PLC中间页)
            return;
        const ele = window.document.querySelector<HTMLElement>(`div[data-id='${id}']`);
        if (!ele) return;
        const eleHeight = ele?.getBoundingClientRect().height;
        const topHeightStr = (ele?.parentNode as HTMLDivElement)?.style?.top;
        const topHeight = topHeightStr ? Number(topHeightStr.replace('px', '')) : 0;
        const parentBlank: HTMLDivElement = ele?.parentNode?.parentNode as HTMLDivElement;
        parentBlank &&
            parentBlank.dataset?.componentType === 'BLANK' &&
            parentBlank.childNodes?.length === 1 &&
            (parentBlank.style.height = eleHeight + topHeight + 'px');
    }, [text, id]);
    const { color: textColor } = useTheme({
        path: 'style.color',
    });

    // 依据类型设置对应文本的className，用于Nav导航
    const structureNavClassName = (() => {
        switch (props?.structureTitleType) {
            case StructureTitleType.productTitle:
                return 'structureNav_productTitle';
            case StructureTitleType.formTitle:
                return 'structureNav_formTitle';
            case StructureTitleType.productInfo:
                return 'structureNav_productInfo';
            default:
                return null;
        }
    })();

    if (type !== 'rich' && displayType === 'flex' && !edit) {
        return (
            <div className={cs(styles.flexRoot)}>
                {text?.split(' ').map((t, index) => (
                    <span
                        style={{
                            ...style,
                            color: textColor,
                            ...(structureTitleType === StructureTitleType.productTitle && {
                                width: 200,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }),
                        }}
                        key={index}
                    >
                        {t}{' '}
                    </span>
                ))}
            </div>
        );
    }

    return (
        <Div
            className={cs(styles.root, structureNavClassName)}
            style={{
                ...style,
                color: textColor,
                ...(structureTitleType === StructureTitleType.productTitle && {
                    width: 360,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }),
            }}
            onClick={onClick}
        >
            {type !== 'rich' &&
                displayType !== 'flex' &&
                !edit &&
                text?.split('\n').map((t, index) => (
                    <React.Fragment key={index}>
                        {t}
                        <br />
                    </React.Fragment>
                ))}
            {/* {type === 'rich' && renderRichText(richText)} */}
            {type === 'rich' && isSSR && <SimpleRender value={richText} />}
            {type === 'rich' && !isSSR && (
                <RichTextErrorBoundary fallback={<FallbackUI richText={richText} />}>
                    <React.Suspense fallback={<FallbackUI richText={richText} />}>
                        <RichText richText={richText} />
                    </React.Suspense>
                </RichTextErrorBoundary>
                // <Slate
                //     editor={editor}
                //     value={richText}
                // >
                //     <Editable
                //         readOnly
                //         renderElement={renderElement}
                //         renderLeaf={renderLeaf}
                //         placeholder="请输入文本"
                //     />
                // </Slate>
            )}
            {children}
        </Div>
    );
};

export default DragText;
