/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { CSSProperties, useRef, useMemo } from 'react';
import { InjectComponentProps, useComContext, useRenderState } from 'omega-render';
// import '../Tel/iconfont/iconfont.css';
import './icon2/iconfont.css';
import { Toast } from '@ad/sharpui';
import cs from 'classnames';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { bridgeGetDeviceInfo } from 'landingPage/common/bridge';
import { dataTrack } from '../../common/utils/trackAd';
import { submitIccCluePhaseOneUsingPOST } from '../../common/api';
import { Div } from '../Blank/Blank';
import { DynamicType } from '../ButtonDrag/Button';
import { getPageId } from '../../common/utils/getPageId';
import { getUrlParam } from '../../common/utils/getUrlParam';
import { getProduct } from '../../common/utils/getProduct';
import { sendTraceLog } from '../../common/adTrack/monitor';
import { isInApp } from '../../common/utils/env';
import { getDeviceType, DEVICE_TYPE } from '../../common/utils/getDeviceInfo';
import { getStoreState } from '../Store/utils/locStore';
import styles from './style.module.less';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { BtnType } from '../WechatGame/WechatGame';
import { ReactComponent as CommonIcon } from './phone.svg';
import { getFuWuHaoInfo } from 'landingPage/common/utils/getFuWuHaoInfo';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';
import { useTheme } from '@/landingPage/common/utils/useTheme';

/**
 * 落地页流量场景
 */
export enum UseType {
    KUAISHOU = 1,
    LIAN_MENG = 2,
}

enum TelSubCompType {
    mobile = 0, //电话
    phone = 1, //固定电话
    AImobile = 2, //智能电话
}

export interface ICallTelParam {
    telType: string | undefined;
    iccId: string | undefined;
    loading: boolean;
    setLoading: (arg0: boolean) => void;
    comType: string;
    tel?: string | number;
    pageId?: string;
    componentType?: string;
}
export const callTel = async (data: ICallTelParam) => {
    const { telType, iccId, loading, setLoading, comType, tel, pageId, componentType } = data;
    if (telType !== 'AImobile' && !tel) {
        return;
    }
    if (loading) {
        return;
    }
    if (!loading) {
        setLoading(true);
    }
    let telNum = tel;

    if (telType === 'AImobile' && iccId) {
        // 兼容审核PC浏览器打开，UUID赋予默认值
        let uuid = '0';
        let deviceInfo;
        try {
            deviceInfo = await bridgeGetDeviceInfo();
            uuid = deviceInfo?.data?.uuid;
        } catch (err) {
            console.error('getDeviceInfo 调用失败:', err);
        }
        if (!uuid) {
            const ua = navigator && navigator.userAgent;
            uuid = `${encodeURIComponent(getPageId())}${ua}`;
            const isIos = getDeviceType() === DEVICE_TYPE.IOS;
            const appType = getProduct();
            sendTraceLog({
                tags: {
                    url: window.location.href,
                    ua: ua,
                    source: isIos ? 'ios' : 'android',
                    appType: appType,
                    pageId: pageId || getPageId(),
                    deviceInfo: JSON.stringify(deviceInfo),
                    isInApp: isInApp,
                    appVersion: deviceInfo?.data?.appVersion,
                    specTag: 'tel_uuid_error',
                },
            });
        }
        const sourceUrl = document.location.href;
        const dspCallback = getCallbackInfo();
        // const pageId = pageIdIn || getPageId();
        const iccClueFirstSubmitParam = {
            /** 线索数据描述信息 */
            iccClueSourceDescView: {
                /** 设备描述符 */
                deviceId: uuid,
                /** DSP回调 */
                dspCallback,
                /** 发布资源id，如落地页id */
                pubSourceId: pageId || getPageId(),
                /** 线索收集方业务线 */
                publishBizId: 1003,
                /** 来源url */
                sourceUrl,
            },
            /** 智能电话id */
            iccId,
            extraInfo: {
                isPreLandingPage: window.isPreLandingPage || 0,
                // 落地页类型，落地页取1 咨询页取2
                lpPageType: 1,
                isMK: getUrlParam('downgradeSource') ? true : getUrlParam('isH5') ? false : '',
                componentType,
            },
            storeId: getStoreState<'storeSelected'>('storeSelected')?.id,
            storeName: getStoreState<'storeSelected'>('storeSelected')?.name,
            orientedCity: getUrlParam('cityName') || '',
            reportRenderInfo: {
                // 新增加的字段
                renderType: comType, // renderType的值是字符串类型
            },
            iccUseTypeEnum: isInApp ? UseType.KUAISHOU : UseType.LIAN_MENG,
            fuWuHaoInfo: getFuWuHaoInfo(),
        };

        try {
            const res = await submitIccCluePhaseOneUsingPOST({ iccClueFirstSubmitParam });
            setLoading(false);

            if (!res.successList || !res.successList.length) {
                throw new Error(res.errorDetailMap.errorResult);
            } else {
                telNum = res.successList[0];
            }
        } catch (e) {
            setLoading(false);

            Toast.error(e.message || '');
            return;
        }
    }
    Toast.info('拨打电话后，您的电话号码会被商家获取');
    const a = window.document.createElement('a');
    a.href = `tel: ${telNum}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoading(false);
    dataTrack({
        eventType: 'EVENT_MAKING_CALLS',
        diyExtraInfo: JSON.stringify({
            renderType: 'TEL_DRAG',
            subCompType: TelSubCompType[telType || 'mobile'],
        }),
    });
    // if (telType !== 'AImobile') {
    //     const { dataTrack } = trackerAd;
    //     dataTrack({ eventType: 'EVENT_MAKING_CALLS', lp_page_type: 1 });
    // } else {
    //     // weblog 打点智能电话点击
    //     trackClick('EVENT_MAKING_AI_CALL');
    // }
};

export interface TelProps extends Omit<InjectComponentProps, 'type'> {
    backgroundFillMode: boolean | undefined;
    /** 组件位置类型 */
    position: 'bottom' | 'base' | 'rightBottom' | 'bottomBlank';
    /** 电话号码 */
    tel: string;
    /** 按钮文案 */
    text: string;
    /** 电话的类型 */
    telType?: 'mobile' | 'phone' | 'AImobile';
    /** 按钮内文本样式 */
    textStyle?: {
        fontSize?: number;
        color?: string;
        fontFamily?: string;
    };
    /** 按钮的基本样式 */
    buttonStyle?: {
        background?: string;
        backgroundImage?: string;
        backgroundSize?: string;
        borderRadius?: string;
        iconColor?: string;
        wrapBackground?: string;
        backgroundColor?: string;
        backgroundRepeat?: string;
    };
    /** 主题配置 */
    themeConfig?: any;
    /** 按钮的大小 */
    size?: {
        width?: string;
        height?: string;
    };
    /** 智能电话勾选id */
    iccId?: string | undefined;

    index?: number;

    dynamicStatus?: DynamicType;

    /** 坐标，本期暂时不做，下期可能用 */
    // coordinate?: { // 坐标
    //     left?: string;
    //     top?: string;
    // };
    btnType?: BtnType; // 新样式新增属性
    radiusStyle?: string; // 圆角类型
    btnSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    sizeType?: 'normal' | 'large';
    iconSize?: number;
}

const Tel: React.FunctionComponent<TelProps> = (props) => {
    const { position, tel, text, textStyle, buttonStyle, iccId, telType, dynamicStatus, index } =
        props;
    const pageJson = useRenderState((state) => state.rolex.opt?.originalPageJson);
    const { color: buttonBackgroundColor } = useTheme({
        path: 'buttonStyle.backgroundColor',
    });
    const { color: buttonBorderColor } = useTheme({
        path: 'buttonStyle.borderColor',
    });
    const { color: textColor } = useTheme({
        path: 'textStyle.color',
    });
    const ref = useRef<HTMLDivElement>(null);
    const { magic, edit } = useComContext();
    const normalStyle = useMemo(() => {
        return {
            ...buttonStyle,
            // background是历史遗留问题
            backgroundColor: `${buttonBackgroundColor}`,
            borderColor: buttonBorderColor,
            fontSize: `${textStyle?.fontSize}px`,
            color: `${textColor}`,
            // borderRadius: `${buttonStyle?.borderRadius}`,
            backgroundImage: buttonStyle?.backgroundImage,
            // backgroundSize: buttonStyle?.backgroundSize,
        };
    }, [buttonStyle, textStyle]);
    const wrapStyle: CSSProperties = {
        background: `${buttonStyle?.wrapBackground}`,
        zIndex: position === 'base' ? undefined : 202,
    };
    const iconStyle = {
        fontSize: `${props?.iconSize || textStyle?.fontSize}px`,
        width: `${props?.iconSize || textStyle?.fontSize}px`,
        height: `${props?.iconSize || textStyle?.fontSize}px`,
        color: buttonStyle?.iconColor,
        marginRight: 4,
    };
    const [loading, setLoading] = React.useState(false);

    const onClick = () => {
        callTel({
            telType,
            iccId,
            loading,
            setLoading,
            comType: 'TEL_DRAG',
            tel,
            pageId: pageJson?.outPageId,
            componentType: pageJson?.pageConversionType || 'unknown',
        });
    };
    const [width, setWidth] = React.useState(
        ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree?.width,
    );
    React.useEffect(() => {
        setWidth(ref.current ? getComputedStyle(ref.current).width : magic?.globalDragFree?.width);
    }, [magic?.globalDragFree?.width]);

    if (position !== 'rightBottom') {
        return (
            <Div
                className={cs(styles.base, styles.normalContent, {
                    [styles.dynamic]: dynamicStatus === 1,
                })}
                style={normalStyle}
                onClick={onClick}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <i style={iconStyle} className="iconfont2 icon-icon_dianhua" />
                    &nbsp; */}
                    <CommonIcon style={iconStyle} fill={textColor} />
                    <span style={{ fontFamily: textStyle?.fontFamily }}>{text}</span>
                </div>
                {position === 'bottomBlank' && (
                    <>
                        <BlockPlaceholder pos="BOTTOM" preview={edit} />
                        <SafeAreaPlace />
                    </>
                )}
            </Div>
        );
    }

    return (
        <Div style={wrapStyle} className={styles[position]}>
            <div>
                {index === 1 ? (
                    <div
                        className={cs(styles.telGif, {
                            [styles.dynamic]: dynamicStatus === 1,
                        })}
                        style={{
                            // ...buttonStyle,
                            borderRadius: buttonStyle?.borderRadius,
                            width: width - 16,
                            height: width - 16,
                        }}
                        onClick={() => {
                            onClick();
                        }}
                    />
                ) : (
                    <div
                        onClick={() => {
                            onClick();
                        }}
                        className={cs(styles['right-bottom-content'], {
                            [styles.dynamic]: dynamicStatus === 1,
                        })}
                        style={{
                            ...buttonStyle,
                            backgroundColor: `${buttonBackgroundColor}`,
                            backgroundImage: buttonStyle?.backgroundImage,
                            backgroundSize: buttonStyle?.backgroundSize,
                            backgroundRepeat: buttonStyle?.backgroundRepeat,
                            height: width - 16,
                            width: width - 16,
                        }}
                    >
                        {/* <TelIcon
                            fill={buttonStyle?.iconColor ?? ''}
                            style={{
                                width: 0.41 * (parseInt(width) - 16),
                                height: 0.41* (parseInt(width) - 16),
                            }}
                        /> */}
                        <CommonIcon
                            style={{
                                color: buttonStyle?.iconColor ?? '',
                                width: 0.41 * (parseInt(width) - 16),
                                height: 0.41 * (parseInt(width) - 16),
                            }}
                            fill={buttonStyle?.iconColor ?? ''}
                        />
                    </div>
                )}

                <p
                    className={styles.rightBottomText}
                    style={{
                        marginTop: 4,
                        fontSize: `${textStyle?.fontSize}px`,
                        color: `${index === 1 ? '#FF4906' : textColor}`,
                        textAlign: 'center',
                        lineHeight: '16px',
                        marginBottom: 8,
                    }}
                >
                    <span style={{ fontFamily: textStyle?.fontFamily }}>
                        {text.length > 4 ? `${text.slice(0, 4)}...` : text}
                    </span>
                </p>
            </div>
        </Div>
    );
};

export default Tel;
