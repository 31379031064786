/**
 * 所有微信弹窗、获取id、打点等逻辑，均在这个文件中
 * @liuyang25
 */
import * as React from 'react';
import { invoke } from '@yoda/bridge';
import {
    wechatPkgSelect,
    wechatItemSelect,
    getIpUsingGet,
    fetchWechatMiniAppInfo,
} from 'landingPage/common/api/index';
import { xianSuoCopy } from 'landingPage/common/api';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
import trackerAd from 'landingPage/common/adTrack';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { ua, isInApp } from 'landingPage/common/utils/env';
import { Toast } from '@ad/sharpui';
import Modal from 'landingPage/common/components/Modal/Modal';
import { render } from 'react-dom';
import { ComponentZIndex } from 'omega-shared/utils';
import { bridgeGetDeviceInfo, exitWebview } from 'landingPage/common/bridge';
import { AppletsModal, DIALOG_STYLE, PageMetaJson, WeChatStay, WeChatType } from 'omega-render';
import {
    WeiXinProps,
    WechatActionSceneEnum,
    WechatActionStatusEnum,
    Applets,
    WeiXinOpt,
    PopupType,
} from '../WeiXin';
import { DeeplinkAppType } from '../../ButtonDrag/Button';
import { CopyDialog } from './CopyDialog';
import { LongPressDialog } from './LongPressDialog';
import { QrCodeDialog } from './QrCodeDialog';
import { JumpWxApplet } from './JumpWxApplet';
import { DirectSubscribe } from './directSubscribe';
import { AppletsConfirmModal } from './appletsModal';
import { JumpWxKefu } from './JumpWxKefu';
import { deepLinkInvokeError, trackDeepLinkInvoke } from 'landingPage/common/adTrack/monitor';
import { appendWithJuXingMacroLinks } from 'landingPage/common/utils/juXingMacro';
import { bridge } from '@ad/ks-ad-alliance-bridge';
import { compareVersion } from '@/landingPage/common/utils/compareVersion';
import { SelectItem } from '../../XiansuoFormMulti/components/IMComponent';
import { getPageId } from '@/landingPage/common/utils/getPageId';

let ip = '';

/** addWechat 打点 */
export const wechatDataTrack = async (props: {
    itemId?: string;
    invokeSuccess: boolean;
    sence: WechatActionSceneEnum;
    wechatId?: string;
    appId?: string;
    popupType?: number;
}) => {
    const { itemId, invokeSuccess, sence, wechatId, appId, popupType } = props;
    if (!ip.length) {
        try {
            ip = await getIpUsingGet();
        } catch (e) {
            console.error('get ip fail');
        }
    }

    dataTrack({
        eventType: 'EVENT_ADD_WECHAT',
        wechatItemId: itemId ?? '',
        wechatActionScene: sence,
        lp_page_type: 1,
        WechatActionStatus: invokeSuccess
            ? WechatActionStatusEnum.WECHAT_INVOKED_SUCCESS
            : WechatActionStatusEnum.WECHAT_INVOKED_FAILED,
        ip,
        ua,
        wechatOfficialAccountId: wechatId,
        wechatAppId: appId,
        diyExtraInfo: JSON.stringify({
            renderType: 'WEI_XIN_DRAG',
            popupType,
        }),
    });
};

export enum FailNum {
    Version = 1,
    UnInstalled = 2,
    Other = 3,
    NoDetail = 4,
}

export const wechatActionSence = (dialogStyleType: DIALOG_STYLE, pageMeta?: PageMetaJson) => {
    let wechatActionSence = WechatActionSceneEnum.COPY_WECHAT_ID;
    switch (dialogStyleType) {
        case (DIALOG_STYLE.DEFAULT, DIALOG_STYLE.LONG_PRESS):
            wechatActionSence = WechatActionSceneEnum.COPY_WECHAT_ID;
            break;
        case DIALOG_STYLE.QRCODE:
            wechatActionSence = WechatActionSceneEnum.SCAN_WECHAT_QR_CODE;
            break;
        case DIALOG_STYLE.JUMPWX:
            if (pageMeta?.appletsModal) {
                wechatActionSence = WechatActionSceneEnum.JUMP_WECHAT_APPLET_FROM_POPUP;
            } else {
                wechatActionSence = WechatActionSceneEnum.JUMP_WECHAT_APPLET;
            }
            break;
        case DIALOG_STYLE.DIRECT_ATTENTION:
            wechatActionSence = WechatActionSceneEnum.DIRECT_SUBSCRIBE;
            break;
        case DIALOG_STYLE.KEFU:
            wechatActionSence = WechatActionSceneEnum.JUMP_KEFU;
            break;
        case DIALOG_STYLE.ACQUISITION_ASSISTANT:
            wechatActionSence = WechatActionSceneEnum.ACQUISITION_ASSISTANT;
            break;
        case DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP:
            wechatActionSence = WechatActionSceneEnum.ACQUISITION_ASSISTANT;
            break;
        default:
            break;
    }
    return wechatActionSence;
};

/** 跳转到小程序 */
export const jumpToMiniApp = async (params: {
    applets?: { appletsId?: string };
    preview?: boolean;
    wechatActionStatus?: WechatActionSceneEnum;
    wechatStay?: boolean;
}) => {
    const { applets, preview, wechatStay } = params;
    try {
        const res = await fetchWechatMiniAppInfo({
            appletsId: String(applets?.appletsId),
            pageId: String(getPageId()),
        });
        if (res.originalId) {
            if (preview) {
                openWechatMiniAppUseBridge({
                    originalId: res.encryptOriginalId,
                    path: res.path,
                    wechatStay,
                });
                return;
            }

            const callback = getUrlParam('callback');
            const ksCampaignId = getUrlParam('ksCampaignId');
            const ksUnitId = getUrlParam('ksUnitId');
            const ksCreativeId = getUrlParam('ksCreativeId');
            const ksSiteAccountId = getUrlParam('ksSiteAccountId');
            const ksPageId = getUrlParam('pageId');

            let pathWithParam = `${String(res.path)}${
                res.path.includes('?') ? '&' : '?'
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            }callback=${callback}&ksCampaignId=${ksCampaignId}&ksUnitId=${ksUnitId}&ksCreativeId=${ksCreativeId}&ksPageId=${ksPageId}&ksSiteAccountId=${ksSiteAccountId}&ksChannel=kuaishou`;
            pathWithParam = appendWithJuXingMacroLinks(pathWithParam);

            // const multiConversion = getUrlParam('multiConversion') === 'true';

            // if (!multiConversion) {
            //     dataTrack({
            //         eventType: 'EVENT_FORM_SUBMIT',
            //         wechatItemId: '0',
            //         lp_page_type: 1,
            //         WechatActionStatus:
            //             wechatActionStatus || WechatActionSceneEnum.JUMP_WECHAT_APPLET,
            //         diyExtraInfo: JSON.stringify({
            //             renderType: 'WEI_XIN_DRAG',
            //         }),
            //     });
            // }

            // if (!isInApp) {
            //     openWechatMiniAppInLianmeng({
            //         originalId: res.originalId,
            //         path: pathWithParam,
            //         wechatStay,
            //     });
            //     return;
            // }

            openWechatMiniAppUseBridge({
                originalId: res.encryptOriginalId,
                path: pathWithParam,
                wechatStay,
            });
        }
    } catch (e) {
        Toast.error('小程序信息获取异常，请稍后重试');
        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
                failType: FailNum.NoDetail,
            }),
        });
    }
};

/** 联盟场景，跳转到小程序 */
// const openWechatMiniAppInLianmeng = (params: {
//     originalId: string;
//     path: string;
//     wechatStay: boolean | undefined;
// }) => {
//     const { originalId, path, wechatStay } = params;

//     // tips: 联盟webview默认会注入KwaiAd，无需引入，直接使用即可

//     // 注册window下面的callback事件
//     window.callback_openWechatMiniProgram = (res) => {
//         console.log('res', res, JSON.parse(res), res.result);
//         trackDeepLinkInvoke(DeeplinkAppType.WECHAT_MINI_APP, 'WEI_XIN_DRAG');
//         if (res.result !== 1) {
//             dataTrack({
//                 eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
//                 deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
//                 diyExtraInfo: JSON.stringify({
//                     renderType: 'WEI_XIN_DRAG',
//                 }),
//             });
//             deepLinkInvokeError(DeeplinkAppType.WECHAT_MINI_APP, 'WEI_XIN_DRAG');

//             console.error('failed: ', JSON.stringify(res));
//             Toast.error('暂时无法跳转到小程序，请稍后重试');
//         } else {
//             console.log('success: ', JSON.stringify(res));
//             wechatDataTrack({
//                 itemId: '0',
//                 invokeSuccess: false,
//                 sence: WechatActionSceneEnum.JUMP_WECHAT_APPLET,
//                 popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
//             });
//             dataTrack({
//                 eventType: 'EVENT_DEEPLINK_INVOKED',
//                 deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
//                 diyExtraInfo: JSON.stringify({
//                     renderType: 'WEI_XIN_DRAG',
//                 }),
//             });
//         }
//     };

//     callLianmengBridge({
//         action: 'openWechatMiniProgram',
//         data: JSON.stringify({
//             originalID: originalId,
//             path,
//         }),
//         callback: 'callback_openWechatMiniProgram',
//     });
// };

/** 跳转到小程序 */
const openWechatMiniAppUseBridge = async (params: {
    originalId: string;
    path: string;
    wechatStay?: boolean;
}) => {
    const { originalId, path, wechatStay } = params;
    try {
        trackDeepLinkInvoke(DeeplinkAppType.WECHAT_MINI_APP, 'WEI_XIN_DRAG');
        let res;
        if (isInApp) {
            res = await invoke('platform.openWechatMiniProgram', {
                originalID: originalId,
                path,
            });
        } else {
            res = await bridge?.openWechatMiniProgram({
                originalID: originalId,
                path,
            });
        }
        console.log('success: ', JSON.stringify(res));
        wechatDataTrack({
            itemId: '0',
            invokeSuccess: false,
            sence: WechatActionSceneEnum.JUMP_WECHAT_APPLET,
            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
        });
        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
            }),
        });
    } catch (e) {
        deepLinkInvokeError(DeeplinkAppType.WECHAT_MINI_APP, 'WEI_XIN_DRAG');
        console.error('failed: ', JSON.stringify(e));
        let failType = FailNum.UnInstalled;
        let appInstalled: string | undefined = undefined;
        if (isInApp) {
            const { installed } = await invoke('tool.checkAppInstalled', {
                android: 'com.tencent.mm',
                ios: 'weixin://',
            });
            appInstalled = installed;
        } else {
            const res = await bridgeGetDeviceInfo();
            if (res?.data?.SDKVersion && compareVersion(res?.data?.SDKVersion, '3.3.65') >= 0) {
                const { data } = await bridge.checkAppInstalled({
                    android: 'com.tencent.mm',
                    ios: 'weixin://',
                });
                appInstalled = data?.isInstalled;
            } else {
                appInstalled = 'true';
            }
        }

        if (appInstalled) {
            Toast.error('暂时无法跳转到小程序，请稍后重试');
            failType = FailNum.Other;
        } else {
            Toast.error('请先安装微信，再重试');
        }

        dataTrack({
            eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
            deeplink_app_type: DeeplinkAppType.WECHAT_MINI_APP,
            diyExtraInfo: JSON.stringify({
                renderType: 'WEI_XIN_DRAG',
                failType,
            }),
        });
    }
};

/** 获取可复制的微信号，看是否后续还可以优化 */
export const fetchWechatId = async (props: {
    dialogStyleType: DIALOG_STYLE;
    opt?: {
        id?: string;
        hasQrCode?: boolean;
        isDel?: boolean;
        packId?: string;
        wechatAppId?: string;
        wechatId?: string;
        wechatNickname?: string;
        wechatType?: string;
        useCount?: number;
        createTime?: number;
        updateTime?: number;
        itemViews?: any;
    };
    edit?: boolean;
    wechatType?: number;
    showModal?: () => void;
    onError?: () => void;
    dialogInfo: {
        wechatId: any;
        packId: string;
        itemId: any;
        qrcode: string;
        appId?: string;
        againstFlowPrefixSwitch?: boolean;
        againstFlowPrefix?: string;
        deepLink?: string;
    };
}) => {
    const { dialogStyleType, opt, wechatType, showModal, dialogInfo, onError, edit } = props;

    let finalDialogInfo = dialogInfo;

    // 直接跳转小程序，可以不选择微信号，也可不用执行下面内容
    if (dialogStyleType !== DIALOG_STYLE.JUMPWX) {
        if (!opt) {
            return;
        }

        if (wechatType === WeChatType.PACKAGE) {
            try {
                const res = await wechatPkgSelect(
                    String(opt?.id),
                    dialogStyleType === DIALOG_STYLE.QRCODE,
                );
                if (res.wechatId) {
                    const result = await wechatItemSelect(
                        res.id,
                        dialogStyleType === DIALOG_STYLE.QRCODE,
                    );

                    if (result) {
                        finalDialogInfo = {
                            wechatId: res.wechatId,
                            packId: String(opt?.id),
                            itemId: res.id,
                            qrcode: res?.wechatQrCodeUrl || '',
                            appId: res.wechatAppId,
                            againstFlowPrefixSwitch: result?.againstFlowPrefixSwitch,
                            againstFlowPrefix: result?.againstFlowPrefix,
                        };

                        showModal?.();
                    }
                }
            } catch (e) {
                onError?.();
            }
        } else if (
            wechatType === WeChatType.KEFU ||
            wechatType === WeChatType.ASSISTANT ||
            wechatType === WeChatType.ASSISTANT_GROUP
        ) {
            showModal?.();
        } else if (dialogInfo.itemId) {
            try {
                const result = await wechatItemSelect(
                    dialogInfo.itemId,
                    edit ? opt?.hasQrCode : dialogStyleType === DIALOG_STYLE.QRCODE,
                );
                if (edit) {
                    finalDialogInfo = {
                        ...dialogInfo,
                        qrcode: result?.wechatQrCodeUrl || '',
                    };
                }

                if (result) {
                    if (dialogStyleType === DIALOG_STYLE.QRCODE) {
                        finalDialogInfo = {
                            ...dialogInfo,
                            qrcode: result?.wechatQrCodeUrl || '',
                        };
                    }
                    finalDialogInfo = {
                        ...finalDialogInfo,
                        againstFlowPrefixSwitch: result?.againstFlowPrefixSwitch,
                        againstFlowPrefix: result?.againstFlowPrefix,
                    };
                    showModal?.();
                }
            } catch (e) {
                onError?.();
            }
        }
    } else {
        showModal?.();
    }

    console.log('finalDialogInfo', finalDialogInfo);

    return finalDialogInfo;
};

/** 历史遗留从opt中获取可用的dialogInfo，看后续是否可优化 */
export const useRandomItem = (props: {
    opt?: WeiXinProps['opt'];
    wechatType?: WeChatType;
    qrcode?: string;
}) => {
    const { opt, wechatType } = props;

    return React.useMemo(() => {
        if (wechatType === WeChatType.KEFU || wechatType === WeChatType.ASSISTANT) {
            return {
                wechatId: '',
                packId: '0',
                itemId: '',
                qrcode: '',
                appId: '',
                deepLink: opt?.deepLink,
            };
        }
        if (wechatType !== WeChatType.PACKAGE) {
            const itemViews = opt?.itemViews;
            if (!itemViews) {
                if (opt?.wechatId) {
                    return {
                        wechatId: opt.wechatId,
                        packId: '0',
                        itemId: opt.id,
                        qrcode: '',
                        appId: opt.wechatAppId,
                        againstFlowPrefixSwitch: opt?.againstFlowPrefixSwitch,
                        againstFlowPrefix: opt?.againstFlowPrefix,
                        directSubscribeSwitch: opt?.directSubscribeSwitch, // 是否直达关注
                        directSubscribeLink: opt?.directSubscribeLink, // 公众号链接
                    };
                }
                return {};
            }
            const randomIndex = Math.floor(Math.random() * itemViews.length);
            const conf = itemViews[randomIndex];
            return {
                wechatId: conf.wechatId,
                packId: '0',
                itemId: conf.id,
                qrcode: '',
                appId: conf.wechatAppId,
            };
        }
        return {
            wechatId: '',
            packId: '0',
            itemId: '',
            qrcode: '',
            appId: '',
        };
    }, [opt, wechatType]);
};

// export const copyText = (text: string) => {};
export const copy = async ({
    text,
    itemId,
    packId,
    wechatStay,
}: {
    text: string;
    itemId?: string;
    packId?: string;
    wechatStay?: boolean;
}) => {
    try {
        if (!isInApp) {
            await bridge?.setClipBoard({ text });
        } else {
            await invoke('webview.setClipBoard', { text });
        }
    } catch (err) {
        console.log(err);
    }

    const lastTime = window.localStorage.getItem(storageKey);
    if (lastTime && new Date().getTime() - Number(lastTime) < 5000) {
        return;
    }
    window.localStorage.setItem(storageKey, `${new Date().getTime()}`);
    packId &&
        itemId &&
        xianSuoCopy({
            deviceType: '0',
            dspCallback: getUrlParam('callback'),
            packId,
            packageId: packId,
            itemId,
            pubBizId: 1003,
            pubSourceId: getUrlParam('pageId'),
            sourceUrl: window.location.href,
        });
    const { dataTrack } = trackerAd;
    dataTrack({
        eventType: 'EVENT_COPY_WECHAT',
        wechatItemId: itemId,
        lp_page_type: 1,
        wechatActionScene: WechatActionSceneEnum.COPY_WECHAT_ID,
        diyExtraInfo: JSON.stringify({
            renderType: 'WEI_XIN_DRAG',
            popupType: wechatStay ? PopupType.WANLIU : PopupType.COMPONENT,
        }),
    });
};
const storageKey = 'moli_weixin_copy';
interface DialogStyleModalProps {
    opt: WeiXinOpt;
    dialogStyleType: DIALOG_STYLE;
    info?: string;
    onClose?: () => void;
    dialogInfo?: {
        wechatId: any;
        packId: string;
        itemId: any;
        qrcode: string;
        appId?: string;
        againstFlowPrefixSwitch?: boolean;
        againstFlowPrefix?: string;
        deepLink?: string;
    };
    applets?: Applets;
    showAppletsModal?: boolean;
    appletsModal?: AppletsModal;
    wechatType?: WeChatType;
    renderType?: string;
    wechatStay?: boolean;
    formId?: string;
    formInfo?: SelectItem[];
    needSendEffectiveAction?: boolean;
}

interface WechatStayBeforClose
    extends Pick<
        DialogStyleModalProps,
        'dialogStyleType' | 'dialogInfo' | 'info' | 'applets' | 'opt' | 'wechatType'
    > {
    edit?: boolean;
}

/** 微信挽留（c端） */
export const wechatStayBeforClose = (props: WechatStayBeforClose) => {
    render(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <WechatStayModel {...props} />,
        window.document.body,
    );
};

/** 微信挽留（编辑端）
 * 因为微信挽留没有实体组件，c端和b端渲染逻辑差异较大
 * b端用render会导致中间的预览区引发其他问题，所以把编辑端单独抽出来了
 */
export const WechatStayInEdit: React.FunctionComponent<{ wechatStay: WeChatStay }> = (props) => {
    const { wechatStay } = props;
    const { wechatType, applets, opt, dialogStyleType, info } = wechatStay;

    const dialogInfo = {
        wechatId: opt.wechatId,
        packId: '0',
        itemId: opt.id,
        qrcode: '',
        appId: opt.wechatAppId,
        againstFlowPrefixSwitch: opt?.againstFlowPrefixSwitch,
        againstFlowPrefix: opt?.againstFlowPrefix,
        directSubscribeSwitch: opt?.directSubscribeSwitch, // 是否直达关注
        directSubscribeLink: opt?.directSubscribeLink, // 公众号链接
    };

    const [res, setRes] = React.useState<any>();
    React.useEffect(() => {
        fetchWechatId({
            opt,
            wechatType,
            edit: true,
            dialogInfo,
            dialogStyleType: dialogStyleType as DIALOG_STYLE,
        }).then((res) => {
            setRes(res);
        });
    }, [opt, wechatType]);

    return (
        <>
            {res && (
                <WechatStayModel
                    dialogStyleType={dialogStyleType as DIALOG_STYLE}
                    info={info}
                    opt={opt}
                    edit
                    dialogInfo={res}
                    applets={applets}
                />
            )}
        </>
    );
};

/** 微信挽留modal部分 */
export const WechatStayModel: React.FunctionComponent<WechatStayBeforClose> = (props) => {
    const { dialogStyleType, dialogInfo, info, edit, applets, opt, wechatType } = props;

    return (
        <Modal
            style={{ zIndex: ComponentZIndex.WechatZIndex }}
            parent={edit ? (document.getElementById('preview-app') ?? document.body) : undefined}
        >
            <DialogStyleModal
                dialogStyleType={dialogStyleType}
                dialogInfo={dialogInfo}
                info={info}
                applets={applets}
                opt={opt}
                renderType="WEI_XIN_DRAG"
                onClose={() => {
                    const time = dialogStyleType === DIALOG_STYLE.QRCODE ? 3000 : 0;
                    window.setTimeout(() => {
                        exitWebview();
                    }, time);
                }}
                wechatStay={true}
                wechatType={wechatType}
            />
        </Modal>
    );
};

/** c端弹窗部分 */
export const DialogStyleModal: React.FunctionComponent<DialogStyleModalProps> = (props) => {
    const {
        dialogStyleType,
        info,
        onClose,
        dialogInfo,
        applets,
        showAppletsModal,
        appletsModal,
        wechatType,
        opt,
        renderType,
        wechatStay,
        formId,
        formInfo,
        needSendEffectiveAction,
    } = props;
    if (showAppletsModal && wechatType === WeChatType.MINIAPP) {
        return (
            <AppletsConfirmModal
                wechatStay={wechatStay}
                appletsModal={appletsModal}
                applets={applets}
                onClose={onClose}
            />
        );
    }

    let dialogStyleModal = <></>;

    switch (dialogStyleType) {
        case DIALOG_STYLE.DEFAULT:
            dialogStyleModal = (
                <CopyDialog
                    info={info}
                    // 默认是挽留弹窗
                    wechatStay={wechatStay}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dialogInfo}
                    onClose={onClose}
                    needSendEffectiveAction={needSendEffectiveAction}
                    renderType={renderType}
                />
            );
            break;
        case DIALOG_STYLE.LONG_PRESS:
            dialogStyleModal = (
                <LongPressDialog
                    info={info}
                    // 默认是挽留弹窗
                    wechatStay={wechatStay}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dialogInfo}
                    onClose={onClose}
                    needSendEffectiveAction={needSendEffectiveAction}
                    renderType={renderType}
                />
            );
            break;
        case DIALOG_STYLE.QRCODE:
            dialogStyleModal = (
                <QrCodeDialog
                    info={info}
                    // 默认是挽留弹窗
                    wechatStay={wechatStay}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dialogInfo}
                    onClose={onClose}
                    needSendEffectiveAction={needSendEffectiveAction}
                    renderType={renderType}
                />
            );
            break;
        case DIALOG_STYLE.JUMPWX:
            dialogStyleModal = (
                <JumpWxApplet
                    info={info}
                    onClose={onClose} // 默认是挽留弹窗
                    wechatStay={wechatStay}
                    applets={applets as Applets}
                />
            );
            break;
        case DIALOG_STYLE.DIRECT_ATTENTION:
            dialogStyleModal = (
                <DirectSubscribe
                    info={info}
                    wechatStay={wechatStay}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dialogInfo}
                    onClose={onClose}
                    needSendEffectiveAction={needSendEffectiveAction}
                    renderType={renderType}
                />
            );
            break;
        case DIALOG_STYLE.KEFU:
            dialogStyleModal = (
                <JumpWxKefu
                    wechatType={WeChatType.KEFU}
                    info={info}
                    deepLink={dialogInfo?.deepLink}
                    onClose={onClose}
                    opt={opt}
                    wechatStay={wechatStay}
                    {...dialogInfo}
                    dialogStyleType={dialogStyleType}
                    renderType={renderType}
                    formId={formId}
                    formInfo={formInfo}
                />
            );
            break;
        case DIALOG_STYLE.ACQUISITION_ASSISTANT:
            dialogStyleModal = (
                <JumpWxKefu
                    wechatType={WeChatType.ASSISTANT}
                    info={info}
                    deepLink={dialogInfo?.deepLink}
                    onClose={onClose}
                    opt={opt}
                    {...dialogInfo}
                    wechatStay={wechatStay}
                    dialogStyleType={dialogStyleType}
                    renderType={renderType}
                    formId={formId}
                    formInfo={formInfo}
                />
            );
            break;
        case DIALOG_STYLE.ACQUISITION_ASSISTANT_GROUP:
            dialogStyleModal = (
                <JumpWxKefu
                    wechatType={WeChatType.ASSISTANT_GROUP}
                    info={info}
                    deepLink={dialogInfo?.deepLink}
                    onClose={onClose}
                    opt={opt}
                    {...dialogInfo}
                    wechatStay={wechatStay}
                    dialogStyleType={dialogStyleType}
                    renderType={renderType}
                    formId={formId}
                    formInfo={formInfo}
                />
            );
            break;
        default:
            dialogStyleModal = (
                <CopyDialog
                    info={info}
                    // 默认是挽留弹窗
                    wechatStay={wechatStay}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...dialogInfo}
                    onClose={onClose}
                    needSendEffectiveAction={needSendEffectiveAction}
                    renderType={renderType}
                />
            );
            break;
    }
    return dialogStyleModal;
};
