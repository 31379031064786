import axios from 'axios';
import { Weblog } from '@ks/weblogger/lib/log.browser';
import Radar from '@ks-radar/radar';
import RMPlugin from '@ks/weblogger/lib/plugins/riskMgt';
import { BasePlugin } from '@ks/weblogger';
import * as yoda from '@yoda/bridge';
import { isProduction, isTest } from './utils/env';
import { getCallbackInfo } from './adTrack/util/sendData';
import { getComponentType, trackFMPCalibrate } from './adTrack/monitor';
import { getCookieByName } from './adTrack/monitor/adBaseLog';
import { isStaging } from 'omega-shared/utils';
import { getPageId } from './utils/getPageId';

const axiosInstance = axios.create({
    withCredentials: false,
    headers: {
        'Moli-Front-Trace-Id': window.SSR_TRACE_ID,
    },
});

export const timeLabel: Partial<{
    fmp: number;
}> = {};

let enterPageTimeStamp = 0;
export const setEnterPageTimeStamp = () => {
    enterPageTimeStamp = new Date().getTime();
};

export const getEnterPageTimeStamp = () => {
    return enterPageTimeStamp;
};

export async function initWeblog() {
    // webLogger.plugins.radar?.fmp(timeLabel.fmp);
    // 新：SSR和CSR通用的fmp打点
    const universalFmp = window?.performance?.getEntriesByName('universalFmp');
    const cssFmp = window?.performance?.getEntriesByName('cssFmp');
    console.log('cssFmp,universalFmp', cssFmp, universalFmp);
    if (universalFmp?.length) {
        const value = universalFmp[0]?.startTime;
        (await getRadar())?.fmp(value + performance.timing.navigationStart);
        try {
            const [firstPaintTime] = performance.getEntriesByName('first-paint');
            const [firstContentfulPaintTime] =
                performance.getEntriesByName('first-contentful-paint');
            if (firstPaintTime.startTime) {
                (await getRadar())?.customStage('c_first_paint', {
                    timestamp: firstPaintTime.startTime + performance.timing.navigationStart,
                });
            }
            if (firstContentfulPaintTime.startTime) {
                (await getRadar())?.customStage('c_first_contentful_paint', {
                    timestamp:
                        firstContentfulPaintTime.startTime + performance.timing.navigationStart,
                });
            }
        } catch (e) {
            console.error(e);
        }

        trackFMPCalibrate();
        // webLogger.plugins.radar?.collect('c_universal_fmp', value);
        // traceLog(value);
        // perLog(value);
    }
}

export function traceLog(fmp: number) {
    try {
        axiosInstance.post(
            'https://webview-b.e.kuaishou.com/rest/logDispatch/h5/traceLog',
            {
                bizName: 'adMoli',
                logType: 'ad_frontend_formance_log_collector',
                traceLogContent: JSON.stringify({
                    time: +new Date(),
                    fmp,
                    isSSR: window.INIT || '0',
                    inTest: window.SSR_TEST || '0',
                    pageId: getPageId() ?? '',
                    callback: getCallbackInfo(),
                }),
            },
            {
                withCredentials: false,
            },
        );
    } catch (err) {
        console.error('traceLog error', err);
    }
}

/* radar自定义指标，分别记录ssr和csr的fmp, 只记录实验组的数据 */
export async function ssrFmpLog(value: number) {
    if (window.INIT) {
        // (await getWebLogger())?.plugins.radar?.collect('c_ssr_fmp', value);
        console.log(`%c 实验组SSR渲染：fmp:${value}`, 'color: green');
        return;
    }
    // (await getWebLogger())?.plugins.radar?.collect('c_csr_fmp', value);
    if (!window.INIT && window.SSR_TEST) {
        console.log(`%c 实验组CSR渲染：fmp:${value}`, 'color: green');
    } else {
        console.log(`%c 非实验组CSR渲染：fmp:${value}`, 'color: green');
    }
}

/* fmp perflog */
// function perLog(value: number) {
//     axiosInstance.post(
//         'https://webview-b.e.kuaishou.com/rest/logDispatch/h5/perfLog',
//         {
//             bizName: 'adMoli',
//             perfParams: [
//                 {
//                     subtag: 'fmp',
//                     millisecond: value,
//                     // @ts-ignore
//                     extra1: window.INIT ? 'isSSR' : 'notSSR',
//                     // @ts-ignore
//                     extra2: window.SSR_TEST ? 'isTest' : 'notTest',
//                 },
//             ],
//         },
//         {
//             withCredentials: false,
//         }
//     );
// }

let webLogger: Weblog;

export async function getWebLogger() {
    if (!webLogger) {
        const env = isProduction ? 'production' : isTest || isStaging ? 'test' : 'development';
        console.log('[weblog env]', env, isProduction, isTest, isStaging);
        if (env === 'development') return;
        if (!webLogger) {
            console.log('init weblogger');
            // https://component.corp.kuaishou.com/docs/weblogger/views/docs/quick-start.html#%E4%B8%8D%E5%90%8C%E7%8E%AF%E5%A2%83-sdk-%E4%BD%BF%E7%94%A8%E5%B7%AE%E5%BC%82
            webLogger = new Weblog(
                {
                    yoda,
                    env,
                    // logger: true,
                    plugins: [
                        // new Radar({
                        //     projectId: '8ded7bb2de', // 雷达平台上的唯一标识
                        //     sampling: 1, // 上报采样率，1 为全部上报
                        //     customDimensions: {
                        //         c_dimension1: getPageId() || 'noid',
                        //         c_dimension2:
                        //             getCookieByName('userId') || getCookieByName('ud') || 'nouid',
                        //         c_dimension3: getComponentType(),
                        //         release_tag: window.kfxEnv && window.kfxEnv.channelId,
                        //         product_version: window.kfxEnv && window.kfxEnv.version,
                        //     },
                        // }),
                        // 反作弊SDK
                        env === 'production' &&
                            new RMPlugin({
                                env,
                                bussType: 'moliClue',
                                taskType: '99',
                                subTaskType: '99-1',
                            }),
                    ].filter(Boolean) as BasePlugin[],
                },
                { product_name: 'moli' },
            );
        }
        return webLogger;
    }
    return webLogger;
}

let radar: Radar;

export async function getRadar() {
    if (!radar) {
        const env = isProduction ? 'production' : isTest || isStaging ? 'test' : 'development';
        if (env === 'development') return;
        radar = new Radar({
            projectId: '8ded7bb2de', // 雷达平台上的唯一标识
            sampling: 1, // 上报采样率，1 为全部上报
            weblog: await getWebLogger(),
            customDimensions: {
                c_dimension1: getPageId() || 'noid',
                c_dimension2: getCookieByName('userId') || getCookieByName('ud') || 'nouid',
                c_dimension3: getComponentType(),
                release_tag: window.kfxEnv && window.kfxEnv.channelId,
                product_version: window.kfxEnv && window.kfxEnv.version,
            },
            enableKtrace: true,
            ktraceServiceName: 'ad-clue', // 服务树根节点，方便天问计费
            ktraceBizName: 'ad',
            ktraceWhitelist: [location.host + '/'],
        });
        return radar;
    }
    return radar;
}
