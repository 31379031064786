import Modal from '@/landingPage/common/components/Modal/Modal';
import { ButtonModalTypeEnum, ButtonProps } from '../Button';
import styles from '../style.module.less';
import { ComponentZIndex } from 'omega-shared/utils';
import { useEffect, useState } from 'react';

interface ButtonModalProps extends Pick<ButtonProps, 'buttonModalProps'> {
    close: () => void;
    edit?: boolean;
}

const defaultImage =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2025-01-10/1736522605326.483f6c93b5158d0c.png';

const textCloseIcon =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2023-02-07/1675758063045.2ecfbcd81522ab5e.png';
const imageCloseIcon =
    'https://h2.static.yximgs.com/kos/nlav10749/chrome-plugin-upload/2025-01-10/1736523856333.f994116739afd905.png';

export const ButtonModal: React.FunctionComponent<ButtonModalProps> = (props) => {
    const { edit, buttonModalProps, close } = props;
    const { modalType, imageUrl, modalStyle, title, text, modalTextStyle, modalTitleStyle } =
        buttonModalProps || {};
    const [isScrollText, setIsScrollText] = useState(false);

    const isImageModal = modalType === ButtonModalTypeEnum.image;
    useEffect(() => {
        if (!isImageModal) {
            /** 判断现在文案是否需要滚动，根据这个来设置不同的padding样式； 需要设置到下一次宏任务中才能拿到组件*/
            setTimeout(() => {
                const textContent = document.getElementById('buttonModalText');
                if (textContent && textContent?.scrollHeight > textContent?.clientHeight) {
                    setIsScrollText(true);
                }
            }, 0);
        }
    }, [isImageModal]);

    return (
        <Modal
            style={{ zIndex: ComponentZIndex.ButtonModalZIndex }}
            parent={edit ? (document.getElementById('preview-app') ?? document.body) : undefined}
        >
            <div
                className={styles.buttonModal}
                style={{
                    ...modalStyle,
                }}
            >
                <img
                    id="buttonModalClose"
                    className={styles.closeBtn}
                    src={isImageModal ? imageCloseIcon : textCloseIcon}
                    onClick={close}
                />
                <div className={styles.buttonModalContent}>
                    {isImageModal && (
                        <img className={styles.image} src={imageUrl || defaultImage} />
                    )}
                    {!isImageModal && (
                        <div
                            className={styles.textModal}
                            style={{ padding: isScrollText ? '24px 18px 24px 30px' : '24px' }}
                        >
                            <div className={styles.title} style={{ ...modalTitleStyle }}>
                                {title || '弹窗标题'}
                            </div>
                            <div
                                id="buttonModalText"
                                className={styles.text}
                                style={{
                                    ...modalTextStyle,
                                }}
                            >
                                {text || '请输入弹窗正文内容'}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
