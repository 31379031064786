/* 埋点管理平台打点
https://data-track.corp.kuaishou.com/v3/#/entity/list?appName=moli */

import { getWebLogger } from 'landingPage/common/weblog';
import { getCallbackInfo } from './sendData';
import { getPageId } from '../../utils/getPageId';

export enum ACTION_TYPE {
    IMAGE = 'IMAGE',
}

export function getCommonParams() {
    return {
        pageId: getPageId(),
        callback: getCallbackInfo(),
    };
}

function getSSRParams() {
    return {
        ssr: window.INIT || '0',
        test: window.SSR_TEST || '0',
    };
}

export async function trackPVEnter() {
    const weblog = await getWebLogger();
    if (weblog) {
        weblog.sendImmediately('PV', {
            type: 'enter',
            page:
                (window as any).__wxjs_environment !== undefined
                    ? 'MP_WEIXIN_LANDING_PAGE'
                    : 'LANDING_PAGE',
            params: {
                ...getCommonParams(),
            },
        });
    }
}

export async function trackPVLeave() {
    const weblog = await getWebLogger();
    if (weblog) {
        weblog.sendImmediately('PV', {
            type: 'leave',
            page:
                (window as any).__wxjs_environment !== undefined
                    ? 'MP_WEIXIN_LANDING_PAGE'
                    : 'LANDING_PAGE',
            beginTime: performance.timing.connectStart,
            params: {
                ...getCommonParams(),
            },
        });
    }
}

export async function trackClick(ACTION_TYPE: ACTION_TYPE | string) {
    if (!ACTION_TYPE) {
        console.error('trackClick error, has no ACTION_TYPE');
    }
    const weblog = await getWebLogger();
    if (weblog) {
        weblog.sendImmediately('CLICK', {
            action: ACTION_TYPE,
            params: {
                ...getCommonParams(),
                ...getSSRParams(),
            },
            type: 'USER_OPERATION',
        });
    }
}

export async function trackFormAutoPhoneStatus(status: number) {
    const weblog = await getWebLogger();
    if (weblog) {
        weblog.sendImmediately('SHOW', {
            action: 'SITE_PAGE_MOBILE_REQUEST_AND_WRITE_RESULT',
            params: {
                page_id: getPageId(),
                account_id: '',
                request_and_write_result_type: status,
            },
        });
    }
}
