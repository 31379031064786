import "../chunk-5JCHA7OU.js";
import "../chunk-ZIOG7RIF.js";

// middlePageTrans/index.ts
async function midPageTrans(pageJson, material, isDynamic, pageType) {
  await trans(pageJson, material, isDynamic, pageType);
  return pageJson;
}
var LINK_TYPE = /* @__PURE__ */ ((LINK_TYPE2) => {
  LINK_TYPE2[LINK_TYPE2["QUICK_LINK"] = 2] = "QUICK_LINK";
  LINK_TYPE2[LINK_TYPE2["ANDROID"] = 3] = "ANDROID";
  LINK_TYPE2[LINK_TYPE2["IOS"] = 4] = "IOS";
  LINK_TYPE2[LINK_TYPE2["HARMONY"] = 5] = "HARMONY";
  return LINK_TYPE2;
})(LINK_TYPE || {});
var keysNeedHandle = [
  "appHeadImgUrl",
  "appLabel",
  "appDescription",
  "appDetailImage",
  "appType",
  "appVideoUrl",
  "appBigImage"
];
var transDownload = (com, updatedRecommendElements) => {
  var _a, _b, _c, _d, _e;
  const appInfo = {
    accountId: 0,
    appVersion: updatedRecommendElements.appVersion,
    appName: updatedRecommendElements.appName,
    packageName: updatedRecommendElements.appPakageName,
    packageSize: revertAppSize((_a = updatedRecommendElements.packageSize) != null ? _a : ""),
    url: updatedRecommendElements.appUrl,
    appIconUrl: updatedRecommendElements.appIconUrl,
    h5App: false,
    platform: ((_b = com.props) == null ? void 0 : _b.linkType) === 4 /* IOS */ ? "ios" : "android",
    virusScore: 0,
    updateTime: new Date((_c = updatedRecommendElements.appUpdateTime) != null ? _c : "").getTime(),
    date: 16315488e5,
    appScore: 0,
    useSdk: false,
    downloadCount: 0,
    check: true,
    privacyUrl: updatedRecommendElements.appPrivacyUrl,
    scanStatus: 2,
    permissionList: updatedRecommendElements.appPermissionUrl,
    developerName: updatedRecommendElements.appDeveloper,
    functionIntroduction: updatedRecommendElements.functionIntroduction
  };
  if (com.props) {
    com.props.appInfo = appInfo;
    com.props.linkType = ((_d = com.props) == null ? void 0 : _d.linkType) || 3 /* ANDROID */;
    com.props.appName = updatedRecommendElements.appName;
    com.props.appDesc = updatedRecommendElements.appDescription;
    com.props.iOSLink = ((_e = com.props) == null ? void 0 : _e.linkType) === 4 /* IOS */ ? updatedRecommendElements.appUrl : "";
  }
};
var transPEDownload = (com, data, isDynamic, pageType) => {
  var _a;
  if (typeof data.appIconUrl === "string") {
    com.props.appIconUrl = data.appIconUrl;
  } else {
    com.props.appIconUrl = (_a = data.appIconUrl) == null ? void 0 : _a.imgUrl;
  }
  com.props = formatColorStyle({
    style: com.props,
    ColorMapList: data.mainColor,
    pageType,
    isDynamic
  });
  transDownload(com, data);
  if (isDynamic) {
    if (data.h5PageInspireCardText) {
      com.props.h5PageInspireCardText = data.h5PageInspireCardText;
    } else {
      delete com.props.h5PageInspireCardText;
    }
  }
};
var trans = async (components, recommendElements, isDynamic, pageType) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n, _o, _p, _q, _r, _s;
  if (!components)
    return;
  const updatedRecommendElements = {};
  Object.keys(recommendElements).forEach((key) => {
    if (recommendElements[key] !== null) {
      updatedRecommendElements[key] = recommendElements[key];
    }
  });
  const ColorMapList = pageType === 7 /* 大白中间页 */ ? updatedRecommendElements.phoneModeColor : updatedRecommendElements.mainColor;
  for (const com of components) {
    switch (com.type) {
      case "DRAG_TEXT" /* DRAG_TEXT */: {
        const selectKey = (_a = com.props) == null ? void 0 : _a.text;
        const linkKey = (_b = com.props) == null ? void 0 : _b.link;
        if (isDynamic || keysNeedHandle.includes(selectKey)) {
          const selectValue = updatedRecommendElements[selectKey];
          if (typeof selectValue === "string") {
            com.props && (com.props.text = selectValue);
          } else if (typeof selectValue === "object") {
            const replaceValue = selectValue[0];
            com.props && (com.props.text = replaceValue);
            updatedRecommendElements[selectKey] = selectValue.slice(1);
          }
          if (linkKey) {
            com.props && (com.props.link = updatedRecommendElements[linkKey]);
          }
        }
        break;
      }
      case "DRAG_IMAGE" /* DRAG_IMAGE */: {
        if (((_c = com.props) == null ? void 0 : _c.type) === "multi") {
          if (com.props && com.props.mutiInfo && com.props.mutiInfo.length) {
            const dealedMutiInfo = com.props.mutiInfo.filter(
              (info) => {
                var _a2;
                return (_a2 = info == null ? void 0 : info.imgUrl) == null ? void 0 : _a2.startsWith("http");
              }
            );
            const dealingMutiInfo = com.props.mutiInfo.filter(
              (info) => {
                var _a2;
                return !((_a2 = info == null ? void 0 : info.imgUrl) == null ? void 0 : _a2.startsWith("http"));
              }
            );
            const convertDealingMutiInfo = dealingMutiInfo.map((item) => {
              const selectKey = item.imgUrl;
              const selectValue = updatedRecommendElements[selectKey];
              if (typeof selectValue === "object" && selectValue.length === 0) {
                return isDynamic ? null : item;
              }
              if (typeof selectValue === "string") {
                item.imgUrl = selectValue;
              } else if (typeof selectValue === "object") {
                const replaceValue = selectValue[0];
                item.imgUrl = replaceValue == null ? void 0 : replaceValue.imgUrl;
                item.pictureId = replaceValue == null ? void 0 : replaceValue.pictureId;
                updatedRecommendElements[selectKey] = selectValue == null ? void 0 : selectValue.slice(1);
              }
              return item;
            }).filter(Boolean);
            com.props.mutiInfo = [...dealedMutiInfo, ...convertDealingMutiInfo];
          }
        } else {
          const selectKey = (_d = com.props) == null ? void 0 : _d.imgUrl;
          if (isDynamic || keysNeedHandle.includes(selectKey)) {
            const selectValue = updatedRecommendElements[selectKey];
            if (typeof selectValue === "string") {
              com.props && (com.props.imgUrl = selectValue);
            } else if (typeof selectValue === "object") {
              let replaceValue = selectValue;
              if (Array.isArray(selectValue)) {
                replaceValue = selectValue[0];
                updatedRecommendElements[selectKey] = selectValue.slice(1);
              }
              com.props && (com.props.imgUrl = (replaceValue == null ? void 0 : replaceValue.imgUrl) || replaceValue);
              com.props && (com.props.pictureId = (replaceValue == null ? void 0 : replaceValue.pictureId) || replaceValue);
            }
          }
        }
        if (pageType === 6 /* 大图中间页 */ && !isDynamic) {
          com.magic && com.magic.dragFree && (com.magic.dragFree.height = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
        }
        break;
      }
      case "VIDEO_DRAG" /* VIDEO_DRAG */: {
        const selectKey = (_e = com.props) == null ? void 0 : _e.videoUrl;
        const selectValue = updatedRecommendElements[selectKey];
        com.props && (com.props.videoUrl = selectValue == null ? void 0 : selectValue.videoUrl);
        com.props && (com.props.pictureId = selectValue == null ? void 0 : selectValue.pictureId);
        break;
      }
      case "PERMISSION_INFO" /* PermissionInfo */: {
        const selectValue = updatedRecommendElements.appPermissionUrl;
        com.props && (com.props.permissions = selectValue);
        break;
      }
      case "APP_DOWNLOAD_DRAG" /* APP_DOWNLOAD_DRAG */: {
        transDownload(com, updatedRecommendElements);
        break;
      }
      case "PEC_DOWNLOAD_FOR_MIDPAGE" /* PEC_DOWNLOAD_FOR_MIDPAGE */: {
        transPEDownload(com, updatedRecommendElements, isDynamic, pageType);
        break;
      }
      case "CORPORATE_INFO" /* CORPORATE_INFO */: {
        const corporationName = updatedRecommendElements.corporationName;
        const accountId = updatedRecommendElements.accountId;
        if (com.props && com.props.midPageAccountInfo) {
          com.props.midPageAccountInfo.corporationName = corporationName;
          com.props.midPageAccountInfo.accountId = accountId;
        }
        const selectValue = updatedRecommendElements.compName;
        com.props && (com.props.companyName = selectValue != null ? selectValue : "");
        break;
      }
      case "FUNCTION_INTRODUCTION" /* FUNCTION_INTRODUCTION */: {
        com.props && (com.props.desc = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.functionIntroduction) || "");
        break;
      }
      case "PRIVACY_FOR_MIDPAGE" /* PrivacyForMidpage */: {
        if (com.props) {
          com.props = {
            ...com.props,
            appPrivacyUrl: (_f = updatedRecommendElements == null ? void 0 : updatedRecommendElements.appPrivacyUrl) != null ? _f : "",
            permissions: (_g = updatedRecommendElements == null ? void 0 : updatedRecommendElements.appPermissionUrl) != null ? _g : [],
            functionIntroduction: (_h = updatedRecommendElements == null ? void 0 : updatedRecommendElements.functionIntroduction) != null ? _h : "",
            appDeveloper: (_i = updatedRecommendElements == null ? void 0 : updatedRecommendElements.appDeveloper) != null ? _i : "",
            appVersion: (_j = updatedRecommendElements == null ? void 0 : updatedRecommendElements.appVersion) != null ? _j : ""
          };
        }
        break;
      }
      case "DRAG_MARQUEE" /* DRAG_MARQUEE */: {
        com.props && (com.props.dataList = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appUserRecord) || []);
        break;
      }
      case "BLANK" /* BLANK */: {
        if (pageType === 6 /* 大图中间页 */ && ((_k = com == null ? void 0 : com.components) == null ? void 0 : _k.length) && ((_l = com.components[0]) == null ? void 0 : _l.type) === "DRAG_IMAGE" /* DRAG_IMAGE */ && !isDynamic) {
          com.magic && (com.magic.editHeight = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
          com.props && com.props.style && (com.props.style.height = (updatedRecommendElements == null ? void 0 : updatedRecommendElements.appBigImageHeight) || 0);
        }
        break;
      }
      case "COLLAPSE_TEXT" /* COLLAPSE_TEXT */: {
        const selectKey = (_m = com.props) == null ? void 0 : _m.text;
        const selectValue = updatedRecommendElements[selectKey];
        if (selectValue && com.props) {
          com.props.text = selectValue;
        }
        break;
      }
      default:
        break;
    }
    if ((_n = com.props) == null ? void 0 : _n.style) {
      const style = formatColorStyle({
        style: com.props.style,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props.style = style;
    }
    if ((_o = com.props) == null ? void 0 : _o.buttonStyle) {
      const style = formatColorStyle({
        style: com.props.buttonStyle,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props.buttonStyle = style;
    }
    if (((_p = com.props) == null ? void 0 : _p.color) || ((_q = com.props) == null ? void 0 : _q.textColor) || ((_r = com.props) == null ? void 0 : _r.btnTextColor) || ((_s = com.props) == null ? void 0 : _s.btnColor)) {
      const style = formatColorStyle({
        style: com.props,
        ColorMapList,
        pageType,
        isDynamic
      });
      com.props = style;
    }
    if (com.components && com.components.length > 0) {
      await trans(com.components, updatedRecommendElements, isDynamic, pageType);
    }
  }
};
var formatColorStyle = (params) => {
  var _a, _b, _c, _d;
  const { style, ColorMapList, pageType, isDynamic } = params;
  const formatStyle = { ...style };
  if (pageType === 7 /* 大白中间页 */ && isDynamic || pageType !== 7 /* 大白中间页 */) {
    for (const key in formatStyle) {
      if (String(formatStyle[key]).includes("mainColor")) {
        const number = (_b = (_a = formatStyle[key].match(/mainColor.[0-9]+./g)) == null ? void 0 : _a[0].match(/[0-9]+/g)) == null ? void 0 : _b[0];
        const color = (_c = ColorMapList == null ? void 0 : ColorMapList[Number(number)]) != null ? _c : "#ffffff";
        const realColor = color.startsWith("#") ? `${hexToRgb((_d = ColorMapList == null ? void 0 : ColorMapList[Number(number)]) != null ? _d : "#ffffff")}` : color;
        formatStyle[key] = formatStyle[key].replace(/mainColor.[0-9]+./g, realColor);
      }
    }
  }
  return formatStyle;
};
var hexToRgb = (hex) => {
  return `${parseInt(`0x${hex.slice(1, 3)}`)},${parseInt(`0x${hex.slice(3, 5)}`)},${parseInt(
    `0x${hex.slice(5, 7)}`
  )}`;
};
var revertAppSize = (size) => {
  if ((size == null ? void 0 : size.indexOf("MB")) >= 0) {
    return Number(size.replace("MB", "")) * 1024 * 1024;
  }
  return Number(size.replace("GB", "")) * 1024 * 1024 * 1024;
};
var getImgDetail = async (imgUrl) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.onload = () => {
      const { width, height } = img;
      resolve({ width, height });
    };
    img.src = imgUrl;
  });
};
export {
  LINK_TYPE,
  getImgDetail,
  hexToRgb,
  midPageTrans
};
