import { dataTrack } from '@/landingPage/common/utils/trackAd';
import { ButtonJumpType, DeeplinkAppType } from './Button';

const enum ButtonSubCompType {
    common = 0, //普通按钮链接
    mini_game = 1, //微信小游戏
    oppo_game = 2, //oppo小游戏
    qq_game = 3, //qq小游戏
    cid_taobao = 4, //cid淘宝
    cid_tmall = 5, //cid 天猫
    cid_jd = 6, //cid京东
    cid_pdd = 7, //cid拼多多
    cid_wechat_mall = 8, //cid微信商城
    mini_applet = 9, //小程序应用
}

/**
 * 统一上报按钮组件点击事件
 *
 * @param {ButtonJumpType} buttonJumpType - 按钮链接配置类型
 * @param {DeeplinkAppType} [deeplinkAppType] - dp链接跳转类型，可选。
 * @param {Object.<string, *>} [extraMsg] - 额外上报数据，可选。
 *
 */
export const reportButtonClick = (params: {
    buttonJumpType: ButtonJumpType;
    deeplinkAppType?: DeeplinkAppType;
    extraMsg?: { [key in string]: any };
}) => {
    const { buttonJumpType, deeplinkAppType, extraMsg = {} } = params;
    /**
     * 根据 buttonJumpType 和 deeplinkAppType，解析该按钮组件对应的子组件类型
     * @returns {SubCompType} 返回子组件类型
     */
    const handleSubCompType = () => {
        let subCompType = ButtonSubCompType.common;
        switch (true) {
            case buttonJumpType === ButtonJumpType.H5: {
                break;
            }
            case buttonJumpType === ButtonJumpType.CID &&
                deeplinkAppType === DeeplinkAppType.TAOBAO: {
                subCompType = ButtonSubCompType.cid_taobao;
                break;
            }
            case buttonJumpType === ButtonJumpType.CID &&
                deeplinkAppType === DeeplinkAppType.TMALL: {
                subCompType = ButtonSubCompType.cid_tmall;
                break;
            }
            case buttonJumpType === ButtonJumpType.CID && deeplinkAppType === DeeplinkAppType.JD: {
                subCompType = ButtonSubCompType.cid_jd;
                break;
            }
            case buttonJumpType === ButtonJumpType.CID && deeplinkAppType === DeeplinkAppType.PDD: {
                subCompType = ButtonSubCompType.cid_pdd;
                break;
            }
            case buttonJumpType === ButtonJumpType.CID &&
                deeplinkAppType === DeeplinkAppType.WECHAT_MALL: {
                subCompType = ButtonSubCompType.cid_wechat_mall;
                break;
            }
            case buttonJumpType === ButtonJumpType.GAMES &&
                deeplinkAppType === DeeplinkAppType.QQ_MINI_GAME: {
                subCompType = ButtonSubCompType.qq_game;
                break;
            }
            case buttonJumpType === ButtonJumpType.GAMES &&
                deeplinkAppType === DeeplinkAppType.OPPO_MINI_GAME: {
                subCompType = ButtonSubCompType.oppo_game;
                break;
            }
            case buttonJumpType === ButtonJumpType.GAMES && !deeplinkAppType: {
                subCompType = ButtonSubCompType.mini_game;
                break;
            }

            case buttonJumpType === ButtonJumpType.DEEPLINK_INVOKED &&
                deeplinkAppType === DeeplinkAppType.ALIPAY_APPLET: {
                subCompType = ButtonSubCompType.mini_applet;
                break;
            }
            default:
                break;
        }
        return subCompType;
    };
    dataTrack({
        eventType: 'EVENT_BUTTON_CLICK',
        button_jump_type: buttonJumpType,
        deeplink_app_type: deeplinkAppType,
        diyExtraInfo: JSON.stringify({
            renderType: 'BUTTON_DRAG',
            subCompType: handleSubCompType(),
            ...extraMsg,
        }),
    });
};
