import { WeChatType, componentsForEach } from 'omega-render';
import { getCallbackInfo, sendData } from './adTrack/util/sendData';
import { EVENT_TYPE } from './adTrack/util/track';
import { getUrlParam } from './utils/getUrlParam';
import { AfterSuccessDisplayType, gameStyle } from 'omega-shared/types';
import { getCookieByName } from './adTrack/monitor/adBaseLog';
import { isFalsy } from './utils/env';
import { getFormComponentType } from 'omega-shared/utils';
import { getRadar } from './weblog';
import { isAggergateCreativeX, getAggregationInfoList } from './utils/getAggregatePageInfoList';
import { aggregateInfoReport } from './adTrack/radarReport';
import { WeiXinProps } from '../components/WeiXinDrag/WeiXin';
import { getPageId } from './utils/getPageId';
// import { getClientData } from './getClientData';

let pageJSON: any;
let page_module_type: number[] = [];
/**
 * 发送更准确的曝光埋点
 */
export function getAppVersion() {
    let _moli_ua = navigator && navigator.userAgent;
    let versionList = _moli_ua.match(/Kwai\/(.+?) /);
    if (!versionList) {
        versionList = _moli_ua.match(/ksNebula\/(.+?) /);
    }
    let version = 'unknown';
    if (versionList) {
        version = versionList[1] && versionList[1].split('.').slice(0, 3).join('.');
    }
    console.log('version=====' + version); // '11.3.20'
    return version;
}
function ifHaveStorePage() {
    if (window.pageJsonString) {
        return Number(/STORE_PAGE/.test(window.pageJsonString));
    }
    return 0;
}
function couponIdList() {
    let couponString = '';
    if (window.pageJsonString) {
        const couponList = window.pageJsonString.match(/"couponId":"[0-9]+/g);
        let idList: any = [];
        if (couponList && couponList.length) {
            idList = couponList.map((item) => {
                const couponId = item.split(':"');
                return couponId && couponId[1] ? couponId[1] : '';
            });
        }
        couponString = idList.length ? idList.join(',') : '';
        return couponString;
    }
    return couponString;
}
function fetchWechatGame() {
    if (window.pageJsonString && window.pageJsonString.match(/WECHAT_GAME/g)) {
        return 'WECHAT_GAME';
    }
    return '';
}

enum WechatActionScene {
    'JUMP_COM_WECHAT_TO_FOLLOW' = 6, // 企微客服加粉
    'EWECHAT_CUSTOMER_ACQUISITION_ASSISTANT' = 7, //获客助手
    'EWECHAT_FOLLOW_AND_ASSISTANT' = 8, // 企微+获客助手
    'KEFU_WITH_HUOKE' = 9, // 客服+获客链接
}

export function getWexinKefuType() {
    if (!window.pageJsonString) return undefined;
    const hasKf = window.pageJsonString.match(/"wechatType":4/g);
    const hashz = window.pageJsonString.match(/"wechatType":5/g);
    const kefuWithHkzs = window.pageJsonString.match(/"planType":2/g);
    if (kefuWithHkzs) return WechatActionScene.KEFU_WITH_HUOKE;
    if (hasKf && hashz) return WechatActionScene.EWECHAT_FOLLOW_AND_ASSISTANT;
    if (hasKf) return WechatActionScene.JUMP_COM_WECHAT_TO_FOLLOW;
    if (hashz) return WechatActionScene.EWECHAT_CUSTOMER_ACQUISITION_ASSISTANT;
    return undefined;
}

export function findPlanId() {
    if (!window.pageJsonString) return '';
    let planId;
    pageJSON = JSON.parse(window.pageJsonString);
    componentsForEach(pageJSON.components, (com) => {
        if (
            com.type === 'WEI_XIN_DRAG' &&
            com?.props?.wechatType === WeChatType.KEFU &&
            com?.props?.funcId
        ) {
            planId = com?.props?.funcId;
        }
        //表单组件，开启弹窗，且选择的是添加微信, 且选择的是微信客服
        if (
            com.type === 'XIANSUO_FORM_DRAG' &&
            com?.props?.formSetting?.submitTurn?.switch &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.afterSuccessDisplayType ===
                AfterSuccessDisplayType.WE_CHAT &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.wechatType ===
                WeChatType.KEFU
        ) {
            planId = com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.opt.planeId;
        }
        // 对话表单&分步表单
        if (
            com.type === 'XIANSUO_FORM' &&
            com?.props?.wechatType === WeChatType.KEFU &&
            com?.props?.wechatFuncId
        ) {
            planId = com?.props?.wechatFuncId;
        }
    });
    return planId;
}

export function findLinkId() {
    if (!window.pageJsonString) return '';
    let linkId;
    const pageJSON = JSON.parse(window.pageJsonString);
    componentsForEach(pageJSON.components, (com) => {
        if (
            com.type === 'WEI_XIN_DRAG' &&
            com?.props?.wechatType === WeChatType.ASSISTANT &&
            com?.props?.funcId
        ) {
            linkId = (com?.props as WeiXinProps)?.opt?.linkId;
        }
        //表单组件，开启弹窗，且选择的是添加微信, 且选择的是微信客服
        if (
            com.type === 'XIANSUO_FORM_DRAG' &&
            com?.props?.formSetting?.submitTurn?.switch &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.afterSuccessDisplayType ===
                AfterSuccessDisplayType.WE_CHAT &&
            com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.wechatType ===
                WeChatType.ASSISTANT
        ) {
            linkId = com?.props?.formSetting?.submitTurn?.itemConfig?.weChatOpt?.opt.linkId;
        }
        // 对话表单&分步表单
        if (
            com.type === 'XIANSUO_FORM' &&
            com?.props?.wechatType === WeChatType.ASSISTANT &&
            com?.props?.wechatFuncId
        ) {
            linkId = com?.props?.wechatOpt.linkId;
        }
    });
    return linkId;
}

export const getTransCompList = () => {
    if (!pageJSON) {
        if (!window.pageJsonString) return [];
        pageJSON = JSON.parse(window.pageJsonString);
    }
    if (page_module_type?.length) {
        return page_module_type;
    }
    const transCompType = {
        BUTTON: 3,
        BUTTON_DRAG: 3,
        APP_DOWNLOAD: 7,
        APP_DOWNLOAD_DRAG: 7,
        CUSTOMER_SERVICE: 11,
        CUSTOMER_SERVICE_DRAG: 11,
        TEL: 12,
        TEL_DRAG: 12,
        MULTI_CONSULT: 13,
        MULTI_CONSULT_DRAG: 13,
        XIANSUO_FORM: 14,
        XIANSUO_FORM_DRAG: 14,
        WEI_XIN: 15,
        WEI_XIN_DRAG: 15,
        STORE: 29,
        COUPON_CARD: 33,
        WECHAT_GAME: 34,
    };
    componentsForEach(pageJSON.components, (com) => {
        if (Object.keys(transCompType)?.includes(com?.type)) {
            page_module_type.push(transCompType[com.type]);
        }
    });
    return page_module_type;
};

export const getWechatGameHotArea = () => {
    if (!pageJSON) {
        if (!window.pageJsonString) return 0;
        pageJSON = JSON.parse(window.pageJsonString);
    }
    let isIncludeHotarea: number = 0;
    componentsForEach(pageJSON.components, (com) => {
        if (com.type === 'WECHAT_GAME' && com.props?.style === gameStyle.hotArea) {
            isIncludeHotarea = 1;
        }
    });
    return isIncludeHotarea;
};

export const getSubFormComponent = () => {
    if (!pageJSON) {
        if (!window.pageJsonString) return 0;
        pageJSON = JSON.parse(window.pageJsonString);
    }
    return pageJSON?.pageMeta?.subFormComponentType?.length
        ? pageJSON.pageMeta.subFormComponentType
        : getFormComponentType(pageJSON)?.subFormComponentType;
};

/** 伪造聚合竞价B、C等落地页的落地页曝光 */
export const sendAggreateImpression = async () => {
    const resUrlList = await getAggregationInfoList();
    resUrlList?.forEach((item) => {
        if (item.callbackInfo?.length && item.siteId?.length) {
            sendData(
                {
                    clientTimestamp: new Date().getTime(),
                    eventType: EVENT_TYPE.impression,
                    lpPageId: item.siteId,
                    diyExtraInfo: JSON.stringify({
                        //聚合竞价相关数据标识
                        isAggreateCreative: true,
                        aggreateOriginalPage: getUrlParam('pageId'),
                    }),
                    lp_page_type: 1,
                },
                'POST',
                item.callbackInfo,
            );
        }
    });
};

export async function sendImpression() {
    // 有callback才可以发送曝光埋点
    if (getCallbackInfo()) {
        const isAggregateCreativeId = Boolean(getUrlParam('isAggregatePage')?.length);
        sendData(
            {
                clientTimestamp: new Date().getTime(),
                eventType: EVENT_TYPE.impression,
                //聚合投放明测A取的是被聚合的落地页aggregateBiddingPageId的值，其他场景（普通投放，极简创编，聚合投放明测X）正常取pageId的值
                lpPageId:
                    isAggregateCreativeId && !isAggergateCreativeX()
                        ? getUrlParam('aggregateBiddingPageId')
                        : getPageId(),
                wechatActionScene: getWexinKefuType(),
                diyExtraInfo: JSON.stringify({
                    isPreLandingpage: window.isPreLandingPage || 0,
                    isIncludeStore: ifHaveStorePage(),
                    couponId: couponIdList(),
                    renderType: fetchWechatGame(),
                    preLpPageId: getUrlParam('preLpPageId'),
                    preCreativeId: getUrlParam('preCreativeId'),
                    aggregatePageId: getUrlParam('aggregatePageId'),
                    isDowngrade: getUrlParam('downgradeSource'),
                    appVersion: getAppVersion(),
                    link_id: findLinkId(),
                    followPlanId: findPlanId(),
                    // https://docs.corp.kuaishou.com/k/home/VRCBe0Bu4fdA/fcACIWRevXHRjfuE49wX3pdeu
                    isLogin: !isFalsy(getCookieByName('userId')),
                    uid: getCookieByName('userId') || '',
                    landing_page_infos: JSON.stringify({
                        page_module_type: getTransCompList(),
                    }),
                    isIncludeHotarea: getWechatGameHotArea(),
                    subFormComponentType: getSubFormComponent(),
                    //聚合竞价相关数据标识
                    isAggreateCreative: String(isAggregateCreativeId),
                    aggreateOriginalPage: getUrlParam('pageId'),
                }),
                lp_page_type: 1,
            },
            'POST',
        );
        aggregateInfoReport({
            callback: getCallbackInfo(),
            isAggregationCreative: isAggregateCreativeId,
            impressionId: getUrlParam('pageId'),
        });
        if (isAggregateCreativeId) {
            sendAggreateImpression();
        }

        (await getRadar())?.event({
            name: 'page_impression', // 必填
            event_type: 'page_impression', // 【可选】
            category: 'traceLog', // 【可选】
            extra_info: JSON.stringify({
                callback: getCallbackInfo(),
                isCPL2: String(getUrlParam('lpFromChannel')) === '2',
                impressionId: getUrlParam('pageId'),
                url: location.href,
            }),
        });
    }
}
