import React from 'react';

import { ImageType } from '../DragImage/DragImage';
import ImageSingle from '../ImageSingle';
import ImageWithMuti from '../ImageWithMuti';

// export type IUrlType = 'moli' | 'normal';
export enum EUrlType {
    MOLI = 'MOLI',
    NORMAL = 'NORMAL',
}
export enum ELinkType {
    NormalLink = 1,
    KwaiApp = 2,
    AppDownload = 3,
}
export interface IMutiInfo {
    /* 固定为1 */
    linkType: ELinkType;
    /* 图片链接 */
    imgUrl: string;
    /* 图片绑定链接的类型：moli(建站搭建页面) normal(快手域名范围内的页面) */
    urlType: EUrlType; // moli ;normal
    /* 图片绑定的链接地址 */
    targetUrl?: string;
    /* 图片加载完毕的回调 */
    loadend?: () => void;
}
export interface ImageProps {
    /* 图片组件类型：single（单图模式）  multi（多图模式） */
    type: ImageType;
    /* 多图模式时取改字段里面值(新增) */
    mutiInfo: IMutiInfo[];
    /* 单图模式下：图片链接 */
    imgUrl: string;
    /* 单图模式下：图片绑定的跳转链接 */
    targetUrl?: string;
    /** 链接类型：
        1（普通链接）
        2（快应用）
        3（应用下载) 
        -1 (为兼容老页面新增：如果为-1，则走urlType类型判断)
        注：2、3已不用
    */
    linkType?: ELinkType;
    urlType: EUrlType; // moli ;normal
    /* 以下字段随着迭代已不再使用 start */
    appInfo?: any;
    quickAppLink?: string;
    iOSLink?: string;
    /* 以下字段随着迭代已不再使用 end */
    loadend?: () => void;
    className?: string;
    /* 图层顺序（新增） */
    zIndex: number;
    /* 是否选中优选 */
    isOptimal: boolean;
    /** 优选图片 */
    optionalConfigImgurls: string[];
    /** 图片比例 */
    imgRatio?: string | undefined;
    /** 缓存单图地址 */
    singleCache?: string;
    /** 选中图片的样式 */
    stroke?: {
        // width: number;
        // color: string;
        borderRadius: number; // 圆角大小
    };
}

const Image: React.FunctionComponent<ImageProps> = (props) => {
    const { type } = props;
    if (!type) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ImageSingle {...props} />;
    }
    if (type === ImageType.single) {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <ImageSingle {...props} />;
    }
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ImageWithMuti {...props} />;
};
export default Image;
