import { checkInWhiteList } from 'landingPage/common/api';
import { getPageId } from 'landingPage/common/utils/getPageId';
import React, { useEffect } from 'react';

export const useWhiteListHooks = () => {
    const [whiteList, setWhiteList] = React.useState<{
        status: boolean;
    }>({
        status: false,
    });
    useEffect(() => {
        getWhiteListData();
    }, []);
    const getWhiteListData = async () => {
        const pageId = getPageId();
        if (!pageId) return;
        const res = await checkInWhiteList(pageId);
        setWhiteList(res.data);
    };
    return whiteList;
};
