import { Toast } from '@ad/sharpui';
import cs from 'classnames';
import * as React from 'react';
import { ComponentJson, useComContext, useRenderState } from 'omega-render';
import BlockPlaceholder from 'landingPage/common/components/BlockPlaceholder';
import { IFormInfoView } from 'landingPage/services/models';

import Modal from 'landingPage/common/components/Modal/Modal';
import { getCallbackInfo } from 'landingPage/common/adTrack/util/sendData';
import { dataTrack } from 'landingPage/common/adTrack/util/track';
import { ComponentType } from 'landingPage/common/componentsInfo.gen';
import { useWindowSize } from 'landingPage/common/hook/useWindowSize';
import { callApp } from 'landingPage/common/utils/callApp';
import { isForm } from 'landingPage/common/utils/checkComType';
import { getProduct } from 'landingPage/common/utils/getProduct';
import { getoutLinkWhiteList } from 'landingPage/common/utils/preventWakeUpApp';
import { Div } from '../Blank/Blank';
import { FormDialog } from './formDialog';
import { PayFormDialog } from './payFormDialog';
import styles from './style.module.less';
import { getUrlParam } from 'landingPage/common/utils/getUrlParam';
// import { ReactComponent as DownloadIcon } from './icon-new-download.svg';
import { ReactComponent as CommonIcon } from './icons/shop.svg';
import { DownloadIcon } from './DownloadIcon';
import {
    deepLinkInvokeError,
    trackQQcomponentImpression,
    traceDeepLinkInvokeError,
    trackDeepLinkInvoke,
    trackGetMacroError,
    URL_TYPE,
} from 'landingPage/common/adTrack/monitor';
import { getCidCommonConfig } from 'landingPage/common/api';
import { useState } from 'react';
import { getPageId } from 'landingPage/common/utils/getPageId';
import SafeAreaPlace from 'landingPage/common/components/SafeAreaPlace';
import { BtnType } from '../WechatGame/WechatGame';
import { InputStyleType } from '../XiansuoFormDrag/XiansuoForm';
import { showAnchorFormIcon } from 'landingPage/common/utils/showAnchorFormIcon';
import { getTransCompList } from 'landingPage/common/impression';
import { trackerAd } from 'landingPage/common/utils/trackAd';
import { useWhiteListHooks } from 'landingPage/common/hooks/useWhiteListHooks';
import { transformUrlWithMacros } from '@/landingPage/common/utils/macro';
import { useTheme } from '@/landingPage/common/utils/useTheme';
import { createPromiseAndExecutor } from '@/landingPage/common/utils/promise';
import { postCheckOutLink } from '@/landingPage/services/apis';
import { reportButtonClick } from './utils';
import { ButtonModal } from './components/buttonModal';

// import { DEVICE_TYPE, getDeviceType } from 'landingPage/common/utils/getDeviceInfo';
// import { QQTips } from './QQMiniGameTips';

// function getUrlPar

export enum JumpTypeNum {
    OUTLINK = 0,
    XIANSUOFORM = 1,
    APP = 2,
    MODAL = 3,
}
export enum FormType {
    NEW = 0,
    COMMON = 1,
}

export enum FormOption {
    ExistCommon = 'ExistCommon', // 已有的普通表单
    ExistPay = 'ExistPay', // 已有的支付表单
    NewCommon = 'NewCommon', // 新加的普通表单（弹层方式展示）
    NewPay = 'NewPay', // 新加的支付表单（弹层方式展示）
}

export enum DynamicType {
    NONE = 0,
    /** 呼吸效果 */
    DYNAMIC = 1,
    /** 扫光效果, 适配plc智能中间页（type:9）的, 实际建站后台没有这个配置 */
    DYNAMIC_SHINING = 2,
}

export enum TargetTypeEnum {
    DeepLink = 'deepLink',
    AlipayMiniApp = 'alipayMiniapp',
    WeixinMiniApp = 'weixinMiniApp',
    OppoMiniGame = 'oppoMiniGame',
    QQMiniGame = 'qqMiniGame',
    cidTaobao = 'cidTaobao',
    cidTmall = 'cidTmall',
    cidJD = 'cidJD',
    cidPDD = 'cidPDD',
    cidWechat = 'cidWechat',
    Application = 'application',
}

export enum miniAppTypeEnum {
    alipay = 'alipay',
}

export enum ButtonJumpType {
    UNKNOOWN_BUTTON_JUMP_TYPE = 0,

    H5 = 1, // 端内打开h5

    DEEPLINK_INVOKED = 2, // 去唤端

    GAMES = 3, // 跳转小游戏
    /** cid电商 */
    CID = 4,
}

export enum DeeplinkAppType {
    UNKNOWN_DEEPLINK_APP_TYPE = 0, // 未知去向

    TAOBAO = 1, // 淘宝

    ALIPAY_APPLET = 2, // 支付宝小程序
    JD = 3, // 京东
    WECHAT_MINI_APP = 4, // 微信小游戏
    QUICK_APPLICATION = 5, // 快应用
    OPPO_MINI_GAME = 6, //oppo小游戏
    QQ_MINI_GAME = 7, //QQ小游戏
    TMALL = 8, //  天猫
    PDD = 9, // 拼多多
    WECHAT_MALL = 10, // 微信商城
    WECHAT_SERVICE = 11, //微信客服
    ACQUISITION_ASSISTANT = 12, //获客助手
    ACQUISITION_ASSISTANT_GROUP = 13, //获客助手
}

enum deeplinkType {
    taobao = 'taobao',
    jd = 'jd',
    alipay = 'alipay',
}

export enum ButtonModalTypeEnum {
    text = 'text',
    image = 'image',
}
export interface ButtonProps {
    text: string;
    buttonStyle?: React.CSSProperties;
    // 按钮外部背景色
    backgroundColor?: string;
    /** 是否展示下载按钮 */
    showIcon?: boolean;
    /** 自定义图标 */
    icon?: React.ReactNode;
    /** 是否吸低 */
    bottom?: boolean;
    /** 跳转链接配置 */
    targetUrl?: string;
    /** deepLink */
    targetType?: TargetTypeEnum | 'jd' | undefined;
    /** 支付宝小程序参数list */

    appId?: string;
    miniAppType?: miniAppTypeEnum;
    query?: string;
    landingPage?: string;
    backupPage?: string;

    /** 有onClick时，自定义跳转动作，targetType和targetUrl时效 */
    onClick?: () => void;
    className?: string;
    loadend?: any;
    index?: number;
    bottomBlank?: boolean;
    dynamicStatus?: DynamicType;
    /* 跳转链接类型 */
    jumpType?: JumpTypeNum;
    /** cid电商兜底落地页 */
    fallbackH5?: string;
    /** 应用跳转链接 */
    applicationUrl?: string;
    /** 应用跳转兜底H5链接 */
    applicationH5BackupUrl?: string;
    /**
     * 颜色填充类型
     * color false
     * image true
     */
    backgroundFillMode?: boolean;
    /* 跳转表单时表单配置项 */
    formProps?: IFormProps;
    formOption?: FormOption;
    iconSize?: number; // 图标大小
    btnType?: BtnType; // 新样式新增属性
    radiusStyle?: string; // 圆角类型
    btnSetting?: {
        fontMax?: number;
        fontMin?: number;
    };
    inputStyleType?: InputStyleType;
    inputStyle?: React.CSSProperties;
    isNewStyle?: boolean;
    buttonModalProps?: ButtonModalProps;
}

export interface ButtonModalProps {
    modalType: ButtonModalTypeEnum;
    imageUrl?: string;
    text?: string;
    modalTitleStyle?: React.CSSProperties;
    modalTextStyle?: React.CSSProperties;
    title?: string;
    modalStyle?: React.CSSProperties;
    imageDetail?: {
        width: number;
        height: number;
    };
}

export interface formPayProps {
    activityId: string;
    activityName: string;
    formId: string;
    itemId: string;
    itemName: string;
}
export interface IFormProps {
    showLabel: 'show' | 'hidden';
    theme?: string;
    funcId?: string;
    inputStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    formStyle?: React.CSSProperties;
    submitStyle?: React.CSSProperties;
    placeholderColor?: string;
    formOpt?: IFormInfoView;
    isPreview?: boolean;
    formType?: FormType;
    index?: number;
    formPayProps?: formPayProps;
    optionStyle?: React.CSSProperties;
    optionActiveStyle?: React.CSSProperties;
    clueAccountId?: number;
    formVersion?: number;
}
export function hasFormOrDragForm(components: ComponentJson[]) {
    if (components.find((c) => isForm(c.type))) {
        return true;
    }
    return components.some((c) => c?.components?.find((d) => isForm(d.type)));
}

/** 和url拼接组成唯一值，标识该链接是否需要走接口校验 */
const NEED_CHECK_URL_KEY = 'NEED_CHECK_URL_KEY';

const { selfPromise: checkUrlValidPromise, promiseResolver } = createPromiseAndExecutor();
const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const {
        className,
        text,
        buttonStyle,
        backgroundColor,
        showIcon,
        bottom,
        targetUrl,
        applicationUrl = '',
        applicationH5BackupUrl,
        targetType,
        children,
        icon,
        loadend,
        dynamicStatus,
        onClick,
        backgroundFillMode,
        jumpType,
        formProps,
        appId,
        miniAppType,
        query,
        landingPage,
        backupPage,
        formOption,
        iconSize = 14,
        isNewStyle = false,
        buttonModalProps,
        ...rest
    } = props;

    const [state, setState] = React.useState({ show: false });

    const [showButtonModal, setShowButtonModal] = React.useState(false);

    const linkValidRef = React.useRef({});
    // 历史数据修复
    const whiteListData = useWhiteListHooks();

    const hasForm = useRenderState((state) => {
        return hasFormOrDragForm(state.rolex.components);
    });
    const [formattedURLs, setFormattedURLs] = useState<[string, string]>();
    const handleCid = async () => {
        console.log('[handleCid]');
        try {
            const res = await getCidCommonConfig();
            const macros = res.macroParamMap;
            const { targetUrl: deepLink, fallbackH5 } = props;
            let formattedH5 = '';
            let formattedDeepLink = '';
            if (deepLink) {
                formattedDeepLink = transformUrlWithMacros({
                    url: deepLink,
                    macros,
                    onError: (key) => {
                        trackGetMacroError(key, getPageId(), URL_TYPE.DEEP_LINK);
                    },
                });
            }

            if (fallbackH5) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                formattedH5 = transformUrlWithMacros({
                    url: fallbackH5,
                    macros,
                    onError: (key) => {
                        trackGetMacroError(key, getPageId(), URL_TYPE.DEEP_LINK);
                    },
                });
            }
            setFormattedURLs([formattedDeepLink, formattedH5]);
            console.log('[formattedDeepLink]', formattedDeepLink, formattedH5);
            return [formattedDeepLink, formattedH5];
        } catch (e) {
            console.error('[error]', e);
            return [];
        }
    };

    const checkJumpValid = async (cidFormattedURLs: string[]) => {
        if (onClick || jumpType === JumpTypeNum.XIANSUOFORM) {
            promiseResolver('done');
            return;
        }
        let links: Array<string | undefined> = [];
        // 通过targetType ，targetUrl 收集需要校验的url，跟处理点击跳转的逻辑是一样的，都是确定最终跳转的链接是什么
        const collectlinks = () => {
            if (targetType === TargetTypeEnum.AlipayMiniApp) {
                // 支付宝小程序跳转用 backupPage 处理兜底链接
                backupPage && links.push(backupPage);
            } else if (targetUrl && targetType !== TargetTypeEnum.Application) {
                if (
                    targetType === TargetTypeEnum.WeixinMiniApp ||
                    targetType === TargetTypeEnum.OppoMiniGame ||
                    targetType === TargetTypeEnum.QQMiniGame ||
                    targetUrl.match(RegExp(urlConfig({ regType: deeplinkType.taobao }) ?? '')) ||
                    targetUrl.match(RegExp(urlConfig({ regType: deeplinkType.jd }) ?? '')) ||
                    TargetTypeEnum.cidWechat === targetType
                ) {
                    // 微信小程序，oppo小游戏，qq小游戏，淘宝京东，需要校验targetUrl
                    targetUrl && links.push(targetUrl);
                } else if (
                    [
                        TargetTypeEnum.cidJD,
                        TargetTypeEnum.cidPDD,
                        TargetTypeEnum.cidTaobao,
                        TargetTypeEnum.cidTmall,
                    ].includes(targetType as TargetTypeEnum)
                ) {
                    // 电商（京东，拼多多，淘宝，天猫）链接处理cidFormattedURLs
                    if (cidFormattedURLs && cidFormattedURLs.length) {
                        cidFormattedURLs?.[0] && links.push(cidFormattedURLs?.[0]);
                        cidFormattedURLs[1] && links.push(cidFormattedURLs[1]);
                    }
                } else {
                    // 兜底处理
                    targetUrl && links.push(targetUrl);
                }
            } else if (targetType === TargetTypeEnum.Application) {
                // 如果是跳转应用，需要先跳应用，失败后跳转兜底链接，这两个地址都需要加白使用，校验applicationUrl， applicationH5BackupUrl
                if (
                    applicationUrl.match(
                        RegExp(urlConfig({ regType: deeplinkType.taobao }) ?? ''),
                    ) ||
                    applicationUrl.match(RegExp(urlConfig({ regType: deeplinkType.jd }) ?? '')) ||
                    applicationUrl ||
                    applicationH5BackupUrl
                ) {
                    applicationUrl && links.push(applicationUrl);
                    applicationH5BackupUrl && links.push(applicationH5BackupUrl);
                }
            }
        };

        collectlinks();

        // 防止接口一直不返回 5s后直接resolve
        setTimeout(() => {
            promiseResolver('done');
        }, 5000);
        let linkValid = {};

        console.log('checkJumpValid  links--->', links);
        if (links.length > 0 && !!getPageId()) {
            try {
                const linkCheckList = await Promise.all(
                    links.map((link) => {
                        return postCheckOutLink({ pageId: getPageId(), url: link as string });
                    }),
                );
                console.log('linkCheckList---->', linkCheckList);
                linkCheckList.forEach((item) => {
                    // newLogicSwitch 为true 走新的接口校验逻辑取status，为false 走老逻辑默认不校验，默认认为接口校验通过了
                    linkValid[item.url] = item.newLogicSwitch ? item.status : true;
                    linkValid[`${item.url}_${NEED_CHECK_URL_KEY}`] = item.newLogicSwitch;
                });
            } catch (e) {
                console.log('postCheckOutLink error', e);
            }
        }

        linkValidRef.current = { ...linkValidRef.current, ...linkValid };
        promiseResolver('done');
    };
    // const [showQQTip, setShowQQTip] = React.useState(false);
    // const isIOS = getDeviceType() === DEVICE_TYPE.IOS;
    React.useEffect(() => {
        const init = async () => {
            let cidFormattedURLs: string[] = [];
            if (
                [
                    TargetTypeEnum.cidJD,
                    TargetTypeEnum.cidPDD,
                    TargetTypeEnum.cidTaobao,
                    TargetTypeEnum.cidTmall,
                    TargetTypeEnum.cidWechat,
                ].includes(targetType as TargetTypeEnum)
            ) {
                cidFormattedURLs = await handleCid();
            }
            if (targetType === TargetTypeEnum.QQMiniGame) {
                trackQQcomponentImpression();
                // ios环境下，展示QQ小游戏的兼容性提示
                // setShowQQTip(true);
            }
            checkJumpValid(cidFormattedURLs);
        };
        init();
    }, []);
    const buttonClick = React.useCallback(() => {
        console.log('----ture code');

        if (onClick) {
            onClick();
            return;
        }
        //按钮点击后打开弹窗
        if (jumpType === JumpTypeNum.MODAL) {
            setShowButtonModal(true);
            reportButtonClick({ buttonJumpType: ButtonJumpType.UNKNOOWN_BUTTON_JUMP_TYPE });
            return;
        }
        // 按钮跳转表单
        if (jumpType === JumpTypeNum.XIANSUOFORM) {
            reportButtonClick({ buttonJumpType: ButtonJumpType.UNKNOOWN_BUTTON_JUMP_TYPE });
            if (formProps?.formType === FormType.COMMON) {
                const index = formProps?.index;
                let componentType =
                    'div[data-component-type^="XIANSUO_FORM_DRAG"],div[data-component-type^="XIANSUO_FORM"]';
                if (formOption === FormOption.ExistPay) {
                    componentType = 'div[data-component-type^="XIANSUO_FORM_PAY"]';
                }

                if (index != null) {
                    const node = document.querySelectorAll(`${componentType}`)[index];

                    // const node = document.querySelectorAll(`.${formClass}`)[index];
                    /**
                     * 注意：scrollIntoView为了让目标元素可，会破坏影响原有的CSS定位样式，比如页面元素发生偏移
                     * scrollIntoView() 方法不仅仅作用于直接父元素，而是会影响整个滚动链（scrolling chain）
                     * 滚动链指的是从目标元素开始，一直到顶层滚动容器（通常是 viewport）的所有可滚动祖先元素。
                     * 建站组件的表单组件嵌套在BLANK组件中，因此他会优先滚动BLANK组件，使表单可见，当BLANK组件和表单相同高度的时候，没有问题，
                     * 但是如果BLANK比表单高的情况，scrollIntoView就会造成BLANK内部的所有元素发生滚动和位移，且无法恢复。
                     * nearest属性会让scrollIntoView减少减少级联效应，最小化滚动距离，一定程度上解决上面的hack行为
                     * 最优的情况是自己通过scrollTo来实现滚动到视口的行为。
                     */
                    node?.scrollIntoView({
                        // behavior: 'smooth',
                        block: 'nearest',
                        inline: 'nearest',
                    });

                    showAnchorFormIcon({ targetNode: node });
                }
            } else {
                setState({
                    show: true,
                });
            }
        } else {
            (async () => {
                // 等待链接校验完成
                await checkUrlValidPromise;

                const jumpMap = {
                    [TargetTypeEnum.WeixinMiniApp]: gotoWeixinMiniGame,
                    [TargetTypeEnum.OppoMiniGame]: gotoOppoMiniGame,
                    [TargetTypeEnum.QQMiniGame]: gotoQQMiniGame,
                    [TargetTypeEnum.cidWechat]: gotoCidWechat,
                    [TargetTypeEnum.cidJD]: gotoCid,
                    [TargetTypeEnum.cidPDD]: gotoCid,
                    [TargetTypeEnum.cidTaobao]: gotoCid,
                    [TargetTypeEnum.cidTmall]: gotoCid,
                };
                const tabobaoJdCheck = (targetUrl: string) => {
                    const tbCheck = targetUrl.match(
                        RegExp(urlConfig({ regType: deeplinkType.taobao }) ?? ''),
                    );
                    const jdCheck = targetUrl.match(
                        RegExp(urlConfig({ regType: deeplinkType.jd }) ?? ''),
                    );
                    return {
                        checked: tbCheck || jdCheck,
                        fn: tbCheck ? gotoTaobao : gotoJD,
                    };
                };
                if (targetType === TargetTypeEnum.AlipayMiniApp) {
                    await gotoAlipayMiniApp({
                        appId: appId ?? '',
                        landingPage: landingPage ?? '',
                        query: query ?? '',
                        backupPage,
                        linkValidMap: linkValidRef.current,
                    });
                } else if (targetUrl && targetType !== TargetTypeEnum.Application) {
                    if (
                        ![
                            TargetTypeEnum.cidJD,
                            TargetTypeEnum.cidPDD,
                            TargetTypeEnum.cidTaobao,
                            TargetTypeEnum.cidTmall,
                        ].includes(targetType as TargetTypeEnum) &&
                        linkValidRef.current &&
                        linkValidRef.current[targetUrl] === false
                    ) {
                        Toast.error('链接失效');
                        return;
                    }

                    if (
                        jumpMap[targetType as TargetTypeEnum] &&
                        [
                            TargetTypeEnum.cidJD,
                            TargetTypeEnum.cidPDD,
                            TargetTypeEnum.cidTaobao,
                            TargetTypeEnum.cidTmall,
                        ].includes(targetType as TargetTypeEnum) &&
                        formattedURLs
                    ) {
                        jumpMap[targetType as TargetTypeEnum](
                            formattedURLs?.[0],
                            formattedURLs[1],
                            targetType as TargetTypeEnum,
                            linkValidRef.current,
                        );
                    } else if (jumpMap[targetType as TargetTypeEnum]) {
                        jumpMap[targetType as TargetTypeEnum](targetUrl, linkValidRef.current);
                    } else if (tabobaoJdCheck(targetUrl).checked) {
                        await tabobaoJdCheck(targetUrl).fn(
                            targetUrl,
                            undefined,
                            linkValidRef.current,
                        );
                    } else {
                        reportButtonClick({
                            buttonJumpType: ButtonJumpType.H5,
                            extraMsg: {
                                landing_page_infos: JSON.stringify({
                                    page_module_type: getTransCompList(),
                                }),
                            },
                        });
                        await openH5InKwai(
                            targetUrl,
                            !linkValidRef.current[`${targetUrl}_${NEED_CHECK_URL_KEY}`],
                        );
                    }
                } else if (targetType === TargetTypeEnum.Application) {
                    // 如果是跳转应用，需要先跳应用，失败后跳转兜底链接，这两个地址都需要加白使用
                    if (tabobaoJdCheck(applicationUrl).checked) {
                        await tabobaoJdCheck(applicationUrl).fn(
                            applicationUrl,
                            applicationH5BackupUrl,
                            linkValidRef.current,
                        );
                    } else if (applicationUrl || applicationH5BackupUrl) {
                        reportButtonClick({ buttonJumpType: ButtonJumpType.H5 });
                        callApp?.({
                            link: applicationUrl,
                            url: applicationH5BackupUrl,
                            linkValidMap: linkValidRef.current,
                        });
                    }
                }
                if (!hasForm) {
                    // 如果落地页不存在表单按钮同时存在需要添加埋点 form_submit
                    // const tracker = window.trackerAd;
                    console.log('click----===', whiteListData);
                    if (trackerAd && whiteListData.status) {
                        const { dataTrack } = trackerAd;
                        dataTrack({
                            eventType: 'EVENT_FORM_SUBMIT',
                            diyExtraInfo: JSON.stringify({
                                renderType: 'BUTTON_DRAG',
                                landing_page_infos: JSON.stringify({
                                    page_module_type: getTransCompList(),
                                }),
                            }),
                        });
                    }
                }
            })();
        }
    }, [
        hasForm,
        onClick,
        targetType,
        targetUrl,
        jumpType,
        formProps?.formType,
        formProps?.index,
        appId,
        query,
        landingPage,
        backupPage,
        formOption,
        formattedURLs,
        whiteListData,
    ]);
    const { type, edit } = useComContext();
    const componentType = type as ComponentType;
    const perviewLayerStyle = useEditModalStyle(edit ?? false);
    const { color: buttonBackgroundColor } = useTheme({
        // componentProps: props,
        path: 'buttonStyle.backgroundColor',
    });
    const { color: buttonBorderColor } = useTheme({
        // componentProps: props,
        path: 'buttonStyle.borderColor',
    });
    const { color: buttonColor } = useTheme({
        // componentProps: props,
        path: 'buttonStyle.color',
    });
    return (
        <>
            {/* {showQQTip && <QQTips onClose={() => setShowQQTip(false)} />} */}
            <Div
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                data-component-type={componentType}
                className={cs(styles.root, className, {
                    [styles.icon]: showIcon,
                    [styles.dynamic]: dynamicStatus === 1,
                })}
                onClick={buttonClick}
                style={{
                    // backgroundColor,
                    ...buttonStyle,
                    background: String(buttonStyle?.background)?.includes('center')
                        ? buttonStyle?.background
                        : buttonBackgroundColor,
                    borderColor: buttonBorderColor,
                    color: buttonColor,
                    backgroundColor: buttonBackgroundColor,
                    // ...buttonTheme,
                }}
            >
                {typeof icon === 'boolean'
                    ? icon &&
                      componentType === 'APP_DOWNLOAD_DRAG' && (
                          <DownloadIcon
                              style={{
                                  width: iconSize,
                                  height: iconSize,
                                  fill: buttonColor || '#fff',
                              }}
                          />
                      )
                    : icon}
                {isNewStyle && (
                    <CommonIcon
                        style={{ width: iconSize, height: iconSize, marginRight: 4 }}
                        fill={buttonColor || '#fff'}
                    />
                )}
                {/* {icon
                    ? icon
                    : componentType === 'APP_DOWNLOAD_DRAG' && (
                          <DownloadIcon
                              style={{
                                  width: iconSize,
                                  height: iconSize,
                                  fill: buttonStyle?.color || '#fff',
                              }}
                          />
                      )} */}
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        ...buttonStyle,
                        color: buttonColor,
                        border: 'none',
                        backgroundColor: '',
                        background: '',
                        backgroundImage: 'none',
                        // marginLeft: 4,
                    }}
                >
                    {text}
                </span>
                {!bottom && children}
            </Div>
            {bottom && (
                <>
                    <BlockPlaceholder pos="BOTTOM" preview={edit} />
                    <SafeAreaPlace />
                </>
            )}
            {state.show && !!formProps && (
                <Modal style={perviewLayerStyle} edit={edit}>
                    {formOption === FormOption.NewPay ? (
                        <PayFormDialog
                            formProps={formProps}
                            onClose={() => {
                                setState({ show: false });
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    ) : (
                        <FormDialog
                            formProps={formProps}
                            onClose={() => {
                                setState({ show: false });
                                const el = document.getElementById('middle-editor');
                                if (el) {
                                    el.style.overflowY = 'auto';
                                }
                            }}
                        />
                    )}
                </Modal>
            )}
            {showButtonModal && (
                <ButtonModal
                    close={() => {
                        setShowButtonModal(false);
                    }}
                    buttonModalProps={buttonModalProps}
                    edit={edit}
                />
            )}
        </>
    );
};

const gotoTaobao = async (
    targetUrl: string,
    backupUrl?: string,
    linkValidMap?: Record<string, boolean>,
) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.DEEPLINK_INVOKED,
        deeplinkAppType: DeeplinkAppType.TAOBAO,
    });

    if (linkValidMap && linkValidMap[targetUrl] === false) {
        Toast.error('链接失效');
        return;
    }

    const url = String(urlConfig({ targetUrl, baseUrlType: deeplinkType.taobao }));
    trackDeepLinkInvoke(DeeplinkAppType.TAOBAO, 'BUTTON_DRAG');
    console.log('goto taobao====', targetUrl, '====', backupUrl);
    await callApp({
        link: url,
        url: targetUrl,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.TAOBAO,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.TAOBAO,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            deepLinkInvokeError(DeeplinkAppType.TAOBAO, 'BUTTON_DRAG');
            // 打开应用失败默认使用兜底链接
            if (backupUrl) {
                if (linkValidMap && linkValidMap[backupUrl] === false) {
                    Toast.error('链接失效');
                    return;
                }
                openH5InKwai(
                    backupUrl,
                    linkValidMap ? !linkValidMap[`${backupUrl}_${NEED_CHECK_URL_KEY}`] : true,
                );
            }
        },
    });
};

const gotoAlipayMiniApp = async (params: {
    appId: string;
    landingPage: string;
    query: string;
    backupPage?: string;
    linkValidMap?: Record<string, boolean>;
}) => {
    const { appId, landingPage, query, backupPage, linkValidMap } = params;

    reportButtonClick({
        buttonJumpType: ButtonJumpType.DEEPLINK_INVOKED,
        deeplinkAppType: DeeplinkAppType.ALIPAY_APPLET,
    });
    const baseUrl = String(
        urlConfig({ appId, landingPage, query, baseUrlType: deeplinkType.alipay }),
    );
    trackDeepLinkInvoke(DeeplinkAppType.ALIPAY_APPLET, 'BUTTON_DRAG');
    await callApp({
        link: baseUrl,
        url: backupPage,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.ALIPAY_APPLET,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.ALIPAY_APPLET,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            deepLinkInvokeError(DeeplinkAppType.ALIPAY_APPLET, 'BUTTON_DRAG');
            if (!backupPage) {
                Toast.info('打开支付宝失败');
            }
        },
        linkValidMap,
    });
};

const gotoWeixinMiniGame = async (targetUrl: string) => {
    reportButtonClick({ buttonJumpType: ButtonJumpType.GAMES });
    const u = new URL(targetUrl);
    u.searchParams.append('callback', getCallbackInfo());
    window.location.href = u.href;
};

const gotoOppoMiniGame = async (targetUrl: string) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.GAMES,
        deeplinkAppType: DeeplinkAppType.OPPO_MINI_GAME,
    });
    const ksCampaignId = getUrlParam('ksCampaignId');
    const ksUnitId = getUrlParam('ksUnitId');
    const ksCreativeId = getUrlParam('ksCreativeId');
    const ksCannel = getUrlParam('ksCannel');

    const u = new URL(targetUrl);
    u.searchParams.append('callback', getCallbackInfo());
    ksCampaignId && u.searchParams.append('ksCampaignId', ksCampaignId);
    ksUnitId && u.searchParams.append('ksUnitId', ksUnitId);
    ksCreativeId && u.searchParams.append('ksCreativeId', ksCreativeId);
    ksCannel && u.searchParams.append('ksCannel', ksCannel);
    trackDeepLinkInvoke(DeeplinkAppType.OPPO_MINI_GAME, 'BUTTON_DRAG');
    await callApp({
        link: u.href,
        url: targetUrl,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.OPPO_MINI_GAME,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.OPPO_MINI_GAME,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            deepLinkInvokeError(DeeplinkAppType.OPPO_MINI_GAME, 'BUTTON_DRAG');
            traceDeepLinkInvokeError({
                targetUrl,
                deepLinkType: DeeplinkAppType.OPPO_MINI_GAME,
                renderType: 'BUTTON_DRAG',
            });
        },
    });
};
const gotoQQMiniGame = async (targetUrl: string) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.GAMES,
        deeplinkAppType: DeeplinkAppType.QQ_MINI_GAME,
    });
    const ksCampaignId = getUrlParam('ksCampaignId');
    const ksUnitId = getUrlParam('ksUnitId');
    const ksCreativeId = getUrlParam('ksCreativeId');
    const ksCannel = getUrlParam('ksCannel');

    const u = new URL(targetUrl);
    u.searchParams.append('callback', getCallbackInfo());
    ksCampaignId && u.searchParams.append('ksCampaignId', ksCampaignId);
    ksUnitId && u.searchParams.append('ksUnitId', ksUnitId);
    ksCreativeId && u.searchParams.append('ksCreativeId', ksCreativeId);
    ksCannel && u.searchParams.append('ksCannel', ksCannel);
    trackDeepLinkInvoke(DeeplinkAppType.QQ_MINI_GAME, 'BUTTON_DRAG');
    await callApp({
        link: u.href,
        url: targetUrl,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.QQ_MINI_GAME,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.QQ_MINI_GAME,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            traceDeepLinkInvokeError({
                targetUrl,
                deepLinkType: DeeplinkAppType.QQ_MINI_GAME,
                renderType: 'BUTTON_DRAG',
            });
        },
    });
};

const target2deeplinkMap = {
    [TargetTypeEnum.cidTaobao]: DeeplinkAppType.TAOBAO,
    [TargetTypeEnum.cidTmall]: DeeplinkAppType.TMALL,
    [TargetTypeEnum.cidJD]: DeeplinkAppType.JD,
    [TargetTypeEnum.cidPDD]: DeeplinkAppType.PDD,
    [TargetTypeEnum.cidWechat]: DeeplinkAppType.WECHAT_MALL,
};
const gotoCid = async (
    targetUrl: string,
    fallbackH5: string,
    targetType: TargetTypeEnum,
    linkValidMap?: Record<string, boolean>,
) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.CID,
        deeplinkAppType: target2deeplinkMap[targetType],
    });
    trackDeepLinkInvoke(target2deeplinkMap[targetType], 'BUTTON_DRAG');

    await callApp({
        link: targetUrl,
        url: fallbackH5,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: target2deeplinkMap[targetType],
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: target2deeplinkMap[targetType],
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED_H5',
                deeplink_app_type: target2deeplinkMap[targetType],
                button_jump_type: ButtonJumpType.CID,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            traceDeepLinkInvokeError({
                targetUrl,
                deepLinkType: target2deeplinkMap[targetType],
                renderType: 'BUTTON_DRAG',
            });
        },
        linkValidMap,
    });
};
/** 跳转cid微信商城 */
const gotoCidWechat = async (targetUrl: string, linkValidMap?: Record<string, boolean>) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.CID,
        deeplinkAppType: DeeplinkAppType.WECHAT_MALL,
    });
    openH5InKwai(
        targetUrl,
        linkValidMap ? !linkValidMap[`${targetUrl}_${NEED_CHECK_URL_KEY}`] : true,
    );
};

const gotoJD = async (
    targetUrl: string,
    backupUrl?: string,
    linkValidMap?: Record<string, boolean>,
) => {
    reportButtonClick({
        buttonJumpType: ButtonJumpType.DEEPLINK_INVOKED,
        deeplinkAppType: DeeplinkAppType.JD,
    });

    if (linkValidMap && linkValidMap[targetUrl] === false) {
        Toast.error('链接失效');
        return;
    }
    const url = String(urlConfig({ targetUrl, baseUrlType: deeplinkType.jd }));
    trackDeepLinkInvoke(DeeplinkAppType.JD, 'BUTTON_DRAG');
    console.log('goto jingdong====', targetUrl, '====', backupUrl);
    await callApp({
        link: url,
        url: targetUrl,
        successCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED',
                deeplink_app_type: DeeplinkAppType.JD,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
        },
        failCallBack: () => {
            dataTrack({
                eventType: 'EVENT_DEEPLINK_INVOKED_FAILED',
                deeplink_app_type: DeeplinkAppType.JD,
                diyExtraInfo: JSON.stringify({
                    renderType: 'BUTTON_DRAG',
                }),
            });
            deepLinkInvokeError(DeeplinkAppType.JD, 'BUTTON_DRAG');
            // 打开应用失败默认使用兜底链接
            if (backupUrl) {
                if (linkValidMap && linkValidMap[backupUrl] === false) {
                    Toast.error('链接失效');
                    return;
                }
                openH5InKwai(
                    backupUrl,
                    linkValidMap ? !linkValidMap[`${backupUrl}_${NEED_CHECK_URL_KEY}`] : true,
                );
            }
        },
    });
};

const openH5InKwai = async (targetUrl: string, neddCheckWhiteList = true) => {
    if (!targetUrl) {
        Toast.info('当前链接不存在');
        return;
    }

    // firefly 开关是否走白名单校验
    if (!neddCheckWhiteList) {
        window.location.href = targetUrl;
        return;
    }

    const outlinkWhitelist = await getoutLinkWhiteList();

    const linkCheckRes = outlinkWhitelist.some((item: string) => {
        return targetUrl.includes(item);
    });

    if (linkCheckRes) {
        window.location.href = targetUrl;
    } else {
        Toast.info('跳转失败，链接不在白名单中');
    }
};

export const useEditModalStyle = (edit: boolean) => {
    const windowSize = useWindowSize();

    return React.useMemo(() => {
        if (edit) {
            const previewEl = document.getElementById('preview-app');
            const { screenWidth, screenHeight } = windowSize;

            const top =
                (previewEl?.getBoundingClientRect()?.top ?? 0) > 64
                    ? previewEl?.getBoundingClientRect()?.top
                    : 64;
            let height = 604;

            if ((previewEl?.offsetHeight ?? 0) > screenHeight - 192) {
                // 页面高度大于屏幕高度,在屏幕高度和页面滚动距离中计算弹层高度
                if ((previewEl?.getBoundingClientRect()?.top ?? 0) > 64) {
                    // 页面滚动距离没把顶部隐藏掉
                    // 此时页面底部未漏出，弹层高度为页面顶部到屏幕底部
                    height = screenHeight - (previewEl?.getBoundingClientRect()?.top ?? 0);
                } else {
                    // 页面滚动距离已隐藏掉顶部
                    // 页面底部未漏出时，屏幕高度减去header高度为弹层高度
                    // 页面底部已漏出，页面底部高度减去header高度为弹层高度
                    height =
                        (previewEl?.getBoundingClientRect()?.bottom ?? 0) > screenHeight
                            ? screenHeight - 64
                            : (previewEl?.getBoundingClientRect()?.bottom ?? 0) - 64;
                }
            } else {
                // 使用页面高度作为弹层高度
                height = previewEl?.offsetHeight ?? 604;
            }

            return {
                zIndex: 999,
                top,
                left: (screenWidth - 432) / 2,
                width: 375,
                height,
            };
        }
        return {
            zIndex: 999,
        };
    }, [edit, windowSize]);
};

const urlConfig = (params: {
    regType?: deeplinkType;
    baseUrlType?: deeplinkType;
    targetUrl?: string;
    appId?: string;
    landingPage?: string;
    query?: string;
}) => {
    const { regType, baseUrlType, targetUrl = '', appId, landingPage = '', query = '' } = params;

    const taobaoreg = /^https?:\/\/.*.taobao.com|^https?:\/\/.*.tmall.com/g;
    const jdreg = /^https?:\/\/.*.jd.com/g;

    if (regType) {
        if (regType === deeplinkType.taobao) {
            return taobaoreg;
        }
        if (regType === deeplinkType.jd) {
            return jdreg;
        }
        return undefined;
    }
    if (baseUrlType) {
        if (baseUrlType === deeplinkType.taobao) {
            // 调起淘宝返回后，可能会循环调起多次
            const baseUrl =
                /* tslint:disable-next-line max-line-length */
                'tbopen://m.taobao.com/tbopen/index.html?action=ali.open.nav&appkey=24648154&packageName=com.smile.gifmaker&module=h5&h5Url=';
            const backSchema =
                getProduct() === 13
                    ? 'ksnebula://action/bringToFront'
                    : 'kwai://action/bringToFront';
            const backUrl = `backURL=${encodeURIComponent(backSchema)}`;
            return `${baseUrl}${encodeURIComponent(targetUrl)}&${backUrl}`;
        }
        if (baseUrlType === deeplinkType.jd) {
            const params = {
                category: 'jump',
                des: 'm',
                url: `${targetUrl}`,
                keplerID: '0',
                keplerFrom: '1',
                kepler_param: {
                    source: 'kepler-open',
                    channel: '',
                },
                union_open: 'union_cps',
            };
            return `openApp.jdMobile://virtual?params=${encodeURIComponent(
                JSON.stringify(params),
            )}`;
        }
        if (baseUrlType === deeplinkType.alipay && appId?.length) {
            return `alipays://platformapi/startApp?appId=${appId}${
                landingPage.length ? `&page=${encodeURIComponent(landingPage)}` : ''
            }${query.length ? `&query=${encodeURIComponent(query)}` : ''}`;
        }
        return undefined;
    }
    return undefined;
};

export default Button;
