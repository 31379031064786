import * as React from 'react';
import BasicStyleCountdown from './BasicStyleCountdown';
import { useCountdownHook } from './countdownHook';
import RichStyleCountdown from './RichStyleCountdown';
import SimpleStyleCountdown from './SimpleStyleCountdown';
import styles from './style.module.less';
import RandomStyleCountdown from './RandomStyleCountdown';

export interface CountdownDragProps {
    styleType: COUNT_DOWN_STYLE_TYPE;
    textStyle: {
        fontFamily: string;
        textFontSize: number;
        textFontColor: string;
    };
    text: string;
    endTime: number;
    dateFormat: 'd' | 'h' | 's' | 'm';
    countdownFontSize: number;
    countdownFontColor: string;
    backgroundColorType: 'custom' | 'preset';
    countdownBackgroundColor: string;
    backgroundColor: string;
}

export enum COUNT_DOWN_STYLE_TYPE {
    RICH = 'rich',
    BASIC = 'basic',
    SIMPLE = 'simple',
    RANDOM = 'random',
}

const CountdownDrag: React.FunctionComponent<CountdownDragProps> = (props) => {
    const { endTime, styleType } = props;
    const hookProps = useCountdownHook(endTime);
    return (
        <div className={styles.root}>
            {styleType === COUNT_DOWN_STYLE_TYPE.RICH && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <RichStyleCountdown {...props} {...hookProps} />
            )}
            {styleType === COUNT_DOWN_STYLE_TYPE.BASIC && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <BasicStyleCountdown {...props} {...hookProps} />
            )}
            {styleType === COUNT_DOWN_STYLE_TYPE.SIMPLE && (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <SimpleStyleCountdown {...props} {...hookProps} />
            )}
            {styleType === COUNT_DOWN_STYLE_TYPE.RANDOM && <RandomStyleCountdown {...props} />}
        </div>
    );
};

export default CountdownDrag;
