import { XiansuoFormProps } from '@/landingPage/components/XiansuoFormDrag/XiansuoForm';
import { GameInfo, componentsForEach } from 'omega-render';
import { cloneDeep } from 'lodash';

let pageJSON: any;

export const setPageJsonCache = () => {
    try {
        pageJSON = JSON.parse(window.pageJsonString);
    } catch (e) {}
};

export const getPageJson = () => {
    if (pageJSON) {
        return pageJSON;
    }
    try {
        pageJSON = JSON.parse(window.pageJsonString);
        return pageJSON;
    } catch (e) {
        return;
    }
};

/** 从pageJson中获取小游戏信息 */
export const fetchWechatGameInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let wechatGameInfo: GameInfo = {} as GameInfo;

    componentsForEach(pageJSONCache.components, (com) => {
        if (com.type === 'WECHAT_GAME') {
            wechatGameInfo = com.props?.gameInfo;
        }
    });

    return wechatGameInfo;
};

/** 从pageJson中获取表单信息 */
export const needShowInteractionCommentInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let needShowComment = false;
    let hasInteractionRecord = false;
    componentsForEach(pageJSONCache.components, (com) => {
        if (['XIANSUO_FORM', 'XIANSUO_FORM_DRAG'].includes(com.type) && !hasInteractionRecord) {
            needShowComment = true;
        }
        if (['DRAG_INTERACTION_RECORD'].includes(com.type)) {
            needShowComment = false;
            hasInteractionRecord = true;
        }
    });
    return needShowComment;
};

export interface AnchorRes {
    isOpenCount: boolean;
    XiansuoFormInfo: any;
}

/** 获取页面中符合表单弹窗规范的表单组件props
 * 符合弹窗规范的表单类型：表单为普通表单，且表单内组件只有2个
 * 表单弹窗的时候要剔除掉广告主配置的计数展示和用户提交展示
 */
let XiansuoFormInfo: XiansuoFormProps = {} as XiansuoFormProps;
let hasGetXiansuoFormInfo = false;
let isOpenCount = false; // 是否开启转化数据(用于锚点弹窗）
export const fetchXiansuoFormInfoInPageJson = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        hasGetXiansuoFormInfo = true;
        return {
            isOpenCount,
            XiansuoFormInfo,
        };
    }
    let flag = false;
    // 如果获取过formInfo，则直接返回
    if (hasGetXiansuoFormInfo) {
        return {
            isOpenCount,
            XiansuoFormInfo,
        };
    }

    componentsForEach(pageJSONCache.components, (com) => {
        if (
            !flag &&
            com.type === 'XIANSUO_FORM_DRAG' &&
            com.props?.formOpt.components.length <= 2
        ) {
            XiansuoFormInfo = cloneDeep(com.props) as XiansuoFormProps;
            // 锚点表单需要
            isOpenCount =
                XiansuoFormInfo?.formSetting?.count?.switch ||
                XiansuoFormInfo?.formSetting?.apply?.switch ||
                false;

            if (XiansuoFormInfo?.formSetting?.count?.switch) {
                XiansuoFormInfo.formSetting.count.switch = false;
            }
            if (XiansuoFormInfo?.formSetting?.apply?.switch) {
                XiansuoFormInfo.formSetting.apply.switch = false;
            }
            flag = true;
        }
    });
    hasGetXiansuoFormInfo = true;

    return {
        isOpenCount,
        XiansuoFormInfo,
    };
};

enum StructureTitleType {
    productTitle = 1,
    formTitle = 2,
    productProfit = 3,
}
const structureTextType = [
    StructureTitleType.productTitle,
    StructureTitleType.formTitle,
    StructureTitleType.productProfit,
];

export interface StructureTextId {
    productTitle: string; // 产品标题
    formTitle: string; // 转化氛围条
    productInfo: string; // 图文详情
}
/** 依据结构化文本类型获取对应组件id */
export const getComponentId = () => {
    const pageJSONCache = getPageJson();
    if (!pageJSONCache) {
        return;
    }
    let structureTextId = {
        productTitle: '', // 产品标题
        formTitle: '', // 转化氛围条
        productInfo: '', // 图文详情
    };

    componentsForEach(pageJSONCache.components, (com) => {
        if (com.type === 'DRAG_TEXT' && structureTextType.includes(com.props?.structureTitleType)) {
            switch (com.props?.structureTitleType) {
                case StructureTitleType.productTitle:
                    structureTextId.productTitle = com.id;
                    break;
                case StructureTitleType.formTitle:
                    structureTextId.formTitle = com.id;
                    break;
                case StructureTitleType.productProfit:
                    structureTextId.productInfo = com.id;
                    break;
            }
        }
    });

    return structureTextId;
};
