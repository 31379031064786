type EventCallback = (...args: any[]) => void;

class EventEmitter<T> {
    private subscriptions = new Map<string, Set<EventCallback>>();

    /**
     * 构造函数，初始化时清除所有订阅。
     */
    constructor() {
        this.clear();
    }

    /**
     * 订阅事件。
     * @param event 事件名称
     * @param listener 事件处理函数
     * @returns 一个函数，调用该函数可以取消订阅
     */
    on = (event: string, listener: EventCallback) => {
        if (typeof event === 'string' || typeof event === 'number') {
            this.subscriptions.set(event, new Set());
        }
        this.subscriptions.get(event)?.add(listener);

        // 返回取消订阅的函数
        return () => {
            this.removeListener(event, listener);
        };
    };

    /**
     * 触发事件，调用所有订阅该事件的处理函数。
     * @param event 事件名称
     * @param args 传递给事件处理函数的参数
     */
    emit = (event: string, ...args: T extends any[] ? any[] : any) => {
        const handlers = this.subscriptions.get(event);
        if (handlers) {
            handlers.forEach((handler) => handler(...args));
        }
    };

    /**
     * 取消订阅特定事件的处理函数。
     * @param event 事件名称
     * @param callback 要取消订阅的事件处理函数
     */
    removeListener = (event: string, callback: EventCallback) => {
        const handlers = this.subscriptions.get(event);
        if (handlers) {
            handlers.delete(callback);
            if (handlers.size === 0) {
                this.subscriptions.delete(event);
            }
        }
    };

    /**
     * 清除所有事件订阅。
     */
    clear = () => {
        this.subscriptions.clear();
    };
}

const eventEmitterOverall = new EventEmitter();

export { EventEmitter, eventEmitterOverall };
